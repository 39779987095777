import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Container, Card } from 'react-bootstrap';
import CustomNavbar from './Navbar';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    background: #f8f9fa;
  }
`;

const MainContainer = styled(Container)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const Title = styled.h1`
  margin-bottom: 20px;
  text-align: center;
`;

const PolicyCard = styled(Card)`
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 20px;
  margin: 20px 0;
`;

const SectionTitle = styled.h3`
  margin-top: 20px;
`;

const ContactInfo = styled.p`
  margin-top: 10px;
  font-weight: bold;
`;

const RefundPolicy = () => {
  return (
    <>
      <GlobalStyle />
      <CustomNavbar />
      <MainContainer>
        <Title>Política de Reembolso</Title>
        <PolicyCard>
          <SectionTitle>1. Condições de Reembolso</SectionTitle>
          <ul>
            <li><strong>Erro Técnico:</strong> Se você foi cobrado mais de uma vez por erro técnico ou se o serviço pago não foi entregue devido a falhas técnicas.</li>
            <li><strong>Serviço Não Disponível:</strong> Se o serviço não estava disponível no momento da compra e você não pôde utilizá-lo posteriormente.</li>
            <li><strong>Engano de Compra:</strong> Se você comprou o crédito por engano e não o utilizou.</li>
            <li><strong>Serviço Inadequado:</strong> Se o serviço fornecido foi inadequado ou não conforme descrito.</li>
            <li><strong>Política de Satisfação Garantida:</strong> Reembolsos podem ser solicitados dentro de 14 dias após a compra, desde que o crédito não tenha sido utilizado.</li>
            <li><strong>Fraude:</strong> Se o crédito foi comprado sem o seu consentimento.</li>
            <li><strong>Problemas de Segurança:</strong> Se houver um problema de segurança significativo que impede o uso seguro do serviço.</li>
          </ul>

          <SectionTitle>2. Processo de Solicitação de Reembolso</SectionTitle>
          <p>Para solicitar um reembolso, por favor, entre em contato com nosso suporte ao cliente fornecendo as seguintes informações:</p>
          <ul>
            <li>Seu nome completo.</li>
            <li>E-mail associado à conta.</li>
            <li>Seu Token.</li>
            <li>Motivo do reembolso.</li>
            <li>Prova de compra (recibo, captura de tela, etc.).</li>
          </ul>

          <SectionTitle>3. Tempo de Processamento</SectionTitle>
          <p>O processamento do reembolso pode levar até 14 dias úteis. Você será notificado por e-mail ou whatsapp sobre o status da sua solicitação de reembolso.</p>

          <SectionTitle>4. Exceções</SectionTitle>
          <p>Reembolsos não serão concedidos se o crédito já foi utilizado, exceto nos casos de erro técnico ou serviço inadequado.</p>

          <SectionTitle>5. Contato</SectionTitle>
          <p>Para solicitar um reembolso ou obter mais informações sobre nossa política de reembolso, entre em contato conosco:</p>
          <ContactInfo>E-mail: suporte@crlvnahora.com.br</ContactInfo>
          <ContactInfo>Telefone: +55 (11) 99918-4789 WhatsApp</ContactInfo>
        </PolicyCard>
      </MainContainer>
    </>
  );
};

export default RefundPolicy;
