// src/GoogleAds.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GoogleAds = () => {
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('userToken');

    const sendPageView = (path) => {
      if (window.gtag) {
        window.gtag('set', 'user_properties', { user_id: token });
        window.gtag('event', 'page_view', {
          page_path: path,
          page_title: document.title,
          user_token: token,
        });
      }
    };

    sendPageView(location.pathname + location.search);
  }, [location]);

  return null;
};

export const trackUserRegistration = (userData) => {
  if (window.gtag) {
    window.gtag('event', 'conversion', {
      send_to: 'AW-16652051824/registro_usuario',
      value: 1.00,
      currency: 'BRL',
      transaction_id: userData.token,
    });
  }
};

export const trackPixRecharge = () => {
  if (window.gtag) {
    window.gtag('event', 'conversion', {
      send_to: 'AW-16652051824/recarga_pix',
      value: 1.00,
      currency: 'BRL',
    });
  }
};

export const trackDocumentConsultation = () => {
  if (window.gtag) {
    window.gtag('event', 'conversion', {
      send_to: 'AW-16652051824/consulta_documento',
      value: 1.00,
      currency: 'BRL',
    });
  }
};

// Função para rastrear visualizações de página
export const trackPageView = () => {
  const token = localStorage.getItem('userToken');
  if (window.gtag) {
    window.gtag('set', 'user_properties', { user_id: token });
    window.gtag('event', 'page_view', {
      page_path: window.location.pathname,
      page_title: document.title,
      user_token: token,
    });
  }
};

export default GoogleAds;
