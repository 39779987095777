import React from 'react';
import { Card, Container } from 'react-bootstrap';
import styled, { createGlobalStyle } from 'styled-components';
import CustomNavbar from './Navbar';

// Estilos globais para a aplicação
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    background: #fff;
  }

  @media (max-width: 768px) {
    .card {
      margin: 20px;
    }
  }
`;

const TermContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TermCard = styled(Card)`
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 20px;
`;

const TermCardBody = styled(Card.Body)`
  padding: 20px;
`;

const TermTitle = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const TermSectionTitle = styled.h4`
  margin-top: 20px;
`;

const TermsOfUsePage = () => {
  return (
    <>
      <CustomNavbar />
      <GlobalStyle />
      <TermContainer>
        <TermCard>
          <TermCardBody>
            <TermTitle>Termos de Uso</TermTitle>
            <p>
              Bem-vindo ao CRLV na Hora, um sistema comprometido com a segurança e privacidade dos dados de nossos usuários, conforme estabelecido pela Lei Geral de Proteção de Dados Pessoais (LGPD) nº 13.709, aprovada em agosto de 2018.
            </p>
            <p>
              Estes Termos de Uso ("Termos") regem o acesso e o uso dos serviços oferecidos pelo CRLV na Hora ("Empresa", "nós" ou "nosso") por meio de nosso site, aplicativos e outras plataformas online (coletivamente, os "Serviços").
            </p>
            <p>
              Ao utilizar os Serviços, os usuários ("você", "seu" ou "usuário") concordam em cumprir e ficar vinculados por estes Termos.
            </p>
            <p>
              Leia atentamente os Termos antes de utilizar os Serviços. Ao acessar, criar uma conta ou utilizar os Serviços de qualquer forma, você concorda em ficar vinculado por estes Termos.
            </p>
            <p>
              Se não concordar com os termos aqui estabelecidos, por favor, não utilize os Serviços oferecidos pelo CRLV na Hora.
            </p>
            <p>
              O CRLV na Hora reserva-se o direito de modificar ou atualizar estes Termos de Uso a qualquer momento e a seu exclusivo critério.
            </p>
            <p>
              Quando fizermos alterações nos Termos, publicaremos a versão atualizada em nossos Serviços e atualizaremos a data de "última atualização" no início destes Termos.
            </p>
            <p>
              É sua responsabilidade revisar periodicamente os Termos para se manter informado sobre qualquer atualização.
            </p>
            <p>
              Ao continuar a usar nossos Serviços após a publicação de quaisquer alterações nos Termos, você concorda em estar vinculado aos Termos atualizados.
            </p>
            <TermSectionTitle>1. Introdução</TermSectionTitle>
            <p>
              Bem-vindo ao CRLV na Hora, um sistema comprometido com a segurança e privacidade dos dados de nossos usuários, conforme estabelecido pela Lei Geral de Proteção de Dados Pessoais (LGPD) nº 13.709, aprovada em agosto de 2018.
            </p>
            <TermSectionTitle>2. Elegibilidade</TermSectionTitle>
            <p>
              Para utilizar os Serviços, você deve ter pelo menos 18 anos de idade e possuir a capacidade legal para celebrar um contrato vinculativo. Ao utilizar os Serviços, você garante que atende a esses requisitos e que não está impedido de usar os Serviços de acordo com as leis aplicáveis.
            </p>
            <TermSectionTitle>3. Licença Limitada para Acesso e Uso</TermSectionTitle>
            <p>
              Concedemos a você uma licença limitada, não exclusiva, intransferível e revogável para acessar e utilizar os Serviços para fins pessoais e não comerciais, sujeito a estes Termos. Você não pode sublicenciar, vender, alugar, arrendar, transferir, ceder, distribuir, hospedar ou explorar comercialmente os Serviços ou qualquer parte deles.
            </p>
            <TermSectionTitle>4. Conta de Usuário</TermSectionTitle>
            <p>
              <strong>Registro:</strong> Para acessar e utilizar os Serviços, você utilizará uma conta de usuário ("Conta") criada e fornecida pelo CRLV na Hora. Você concorda em fornecer informações verdadeiras, precisas, atuais e completas durante o processo de registro e atualizar essas informações conforme necessário.
            </p>
            <p>
              <strong>Segurança:</strong> Você é responsável por manter a confidencialidade de sua (senha e token) e por todas as atividades realizadas em sua Conta. Informe imediatamente o CRLV na Hora sobre qualquer uso não autorizado de sua Conta ou violação de segurança.
            </p>
            <TermSectionTitle>5. Proteções e Privacidade</TermSectionTitle>
            <p>
              Ao utilizar o CRLV na Hora, você pode esperar os seguintes serviços de proteção de dados:
            </p>
            <ul>
              <li>Proteção contra uso abusivo (muitas consultas com a finalidade de roubar dados);</li>
              <li>Registro de consultas (log);</li>
              <li>Proteções contra vazamento de informações;</li>
              <li>Equipe de monitoramento dedicada;</li>
            </ul>
            <TermSectionTitle>6. Uso Permitido e Restrições</TermSectionTitle>
            <p>
              O CRLV na Hora reserva-se o direito de monitorar e armazenar dados sobre o uso dos Serviços e tomar medidas cabíveis em caso de violação destes Termos.
            </p>
            <p>
              <strong>Uso Permitido:</strong>
            </p>
            <ul>
              <li>Exclusivamente particulares e  Não econômicos ;</li>
              <li>Despachantes.</li>
              <li>Concessionária.</li>
            </ul>
            <p>
              <strong>Uso Proibido:</strong>
            </p>
            <ul>
              <li>Realizar atividades que violem leis ou regulamentações aplicáveis;</li>
              <li>Usar os Serviços para fins ilegais, fraudulentos, imorais, antiéticos ou difamatórios;</li>
              <li>Assediar, ameaçar, abusar ou assustar quaisquer pessoas e/ou instituições privadas ou governamentais;</li>
              <li>Interferir ou interromper a integridade ou o desempenho dos Serviços;</li>
              <li>Tentar obter acesso não autorizado aos Serviços ou a sistemas relacionados;</li>
              <li>Copiar, reproduzir, modificar, distribuir, transmitir, exibir, executar, licenciar, criar trabalhos derivados, vender ou explorar de outra forma qualquer conteúdo ou parte dos Serviços sem a permissão prévia por escrito do CRLV na Hora.</li>
            </ul>
            <TermSectionTitle>7. Contas Deletadas e Sanções</TermSectionTitle>
            <p>
              O CRLV na Hora reserva-se o direito de aplicar sanções, encerrar ou suspender o seu acesso aos Serviços, sem direito a reembolso, a seu exclusivo critério, por qualquer motivo e sem aviso prévio, em caso de violação destes Termos.
            </p>
            <p>
              Se você violar estes Termos, sua permissão para usar os Serviços será automaticamente encerrada de forma permanente:
            </p>
            <ul>
              <li>Uso abusivo (dump);</li>
              <li>Contas compartilhadas;</li>
              <li>Contas vazadas na internet;</li>
              <li>Fraudes;</li>
              <li>Atividades ilegais de qualquer natureza;</li>
              <li>Vazamentos de informações;</li>
              <li>Requisições automáticas;</li>
              <li>Uso do painel como API/Automação;</li>
              <li>Violação destes Termos de Uso ou de qualquer outra política aplicável do CRLV na Hora.</li>
            </ul>
            <TermSectionTitle>8. Indenização</TermSectionTitle>
            <p>
              Você concorda em indenizar e isentar o CRLV na Hora, seus afiliados, diretores, funcionários e agentes de qualquer reivindicação, responsabilidade, dano, perda ou despesa, incluindo, mas não se limitando a, honorários advocatícios razoáveis, resultantes ou relacionados ao seu uso dos Serviços ou violação destes Termos.
            </p>
            <TermSectionTitle>9. Responsabilidade e Isenção de Garantias</TermSectionTitle>
            <p>
              O CRLV na Hora não se responsabiliza por usos indevidos dos seus serviços. Caso seja constatado o uso indevido, a única responsabilidade do CRLV na Hora é a exclusão permanente do usuário.
            </p>
            <p>
              O CRLV na Hora se isenta completamente de qualquer responsabilidade advinda de ações legais decorrentes do uso indevido da plataforma por usuários mal-intencionados.
            </p>
            <p>
              Ao aceitar os presentes Termos e Condições, o usuário compreende e concorda que é o único e exclusivo responsável por qualquer consequência legal, jurídica, civil ou de qualquer outra natureza, resultante do mau uso dos serviços oferecidos pela plataforma.
            </p>
            <p>
              Ao utilizar nossos serviços, o usuário se compromete a respeitar todas as leis e regulamentações aplicáveis, e assume a responsabilidade por quaisquer ações, reclamações ou demandas que possam surgir em decorrência de suas ações ou omissões.
            </p>
            <p>
              Os Serviços são fornecidos "no estado em que se encontram" e "conforme disponíveis".
            </p>
            <p>
              O CRLV na Hora não oferece garantias, expressas ou implícitas, incluindo, mas não se limitando a, garantias implícitas de comercialização, adequação a um propósito específico e não violação.
            </p>
            <p>
              O CRLV na Hora não garante que os Serviços serão ininterruptos, pontuais ou livres de erros.
            </p>
            <p>
              Em nenhum caso, o CRLV na Hora ou seus afiliados, diretores, funcionários ou agentes serão responsáveis por quaisquer danos diretos, indiretos, incidentais, especiais, consequentes ou punitivos, incluindo, mas não se limitando a, perda de lucros, dados, uso, boa vontade ou outras perdas intangíveis, resultantes do uso ou incapacidade de usar os Serviços, mesmo que o CRLV na Hora tenha sido avisado da possibilidade de tais danos.
            </p>
            <TermSectionTitle>10. Divisibilidade</TermSectionTitle>
            <p>
              Se alguma disposição destes Termos for considerada inválida ou inexequível por um tribunal de jurisdição competente, tal disposição será considerada como anulação parcial, portanto, separável destes Termos e não afetará a validade e aplicabilidade das demais disposições.
            </p>
            <TermSectionTitle>11. Reclamações e Resolução de Disputas</TermSectionTitle>
            <p>
              Se você tiver alguma reclamação ou disputa em relação aos Serviços, concorda em tentar resolver o problema diretamente com o CRLV na Hora antes de tomar outras medidas. Se não pudermos resolver a disputa por meio de negociações diretas, ambas as partes concordam em tentar resolvê-la por mediação ou arbitragem, conforme acordado mutuamente.
            </p>
            <TermSectionTitle>12. Reembolso</TermSectionTitle>
            <p>
              Para solicitar um reembolso de crédito, o usuário deve atender aos seguintes requisitos:
            </p>
            <ul>
              <li>Ter realizado menos de 10 consultas;</li>
              <li>Apresentar um motivo plausível para o pedido de reembolso.</li>
            </ul>
            <p>
              O CRLV na Hora analisará cada solicitação de reembolso individualmente e, a seu exclusivo critério, poderá aprovar ou recusar o pedido.
            </p>
            <p>
              Reembolso aprovado terá um prazo de 14 dias úteis a partir da data que foi solicitado o reembolso.
            </p>
            <TermSectionTitle>13. Melhorias e Alterações</TermSectionTitle>
            <p>
              Estamos sempre em busca de melhorar a qualidade das consultas, então módulos podem ser retirados ou adicionados a qualquer momento, sem aviso prévio. O CRLV na Hora reserva-se o direito de modificar ou descontinuar, temporária ou permanentemente, os Serviços (ou qualquer parte deles) com ou sem aviso prévio.
            </p>
            <TermSectionTitle>14. Legislação Aplicável e Foro</TermSectionTitle>
            <p>
              Estes Termos de Uso são regidos e interpretados de acordo com as leis do Brasil. Você concorda em submeter-se à jurisdição exclusiva dos tribunais brasileiros para resolver qualquer disputa decorrente destes Termos ou do uso dos Serviços.
            </p>
            <TermSectionTitle>15. Atribuição</TermSectionTitle>
            <p>
              Você não pode ceder ou transferir seus direitos ou obrigações sob estes Termos de Uso, seja voluntária ou involuntariamente, sem o prévio consentimento por escrito do CRLV na Hora. Qualquer tentativa de atribuição ou transferência sem tal consentimento será nula e sem efeito. O CRLV na Hora pode, a seu exclusivo critério, ceder ou transferir estes Termos de Uso, no todo ou em parte, sem restrições.
            </p>
            <TermSectionTitle>16. Concordância com a LGPD e Termos de Uso</TermSectionTitle>
            <p>
              Ao adquirir algum de nossos produtos ou serviços, você concorda em cumprir com a LGPD e os presentes Termos de Uso.
            </p>
            <TermSectionTitle>17. Contato</TermSectionTitle>
            <p>
              Se tiver alguma dúvida ou preocupação em relação a estes Termos de Uso, entre em contato conosco pelo e-mail contato@crlvnahora.com.br.
            </p>
          </TermCardBody>
        </TermCard>
      </TermContainer>
    </>
  );
};

export default TermsOfUsePage;
