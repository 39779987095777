import React, { useState } from 'react';
import { useAuth } from './components/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Card, Alert } from 'react-bootstrap';
import { FaSignInAlt } from 'react-icons/fa';
import './styles/Login.css';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { login } = useAuth();
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        if (await login(username, password)) {
            navigate('/Admin/Gerenciamento');
        } else {
            setError('Usuário ou senha inválidos');
        }
    };

    return (
        <Container className="d-flex justify-content-center align-items-center vh-100">
            <Card className="shadow p-4" style={{ width: '100%', maxWidth: '500px' }}>
                <Card.Body>
                    <h2 className="text-center mb-4">
                        <FaSignInAlt className="me-2" /> Login do Administrador
                    </h2>
                    <Form onSubmit={handleLogin}>
                        <Form.Group className="mb-3" controlId="formUsername">
                            <Form.Label>Nome de Usuário</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nome de usuário"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formPassword">
                            <Form.Label>Senha</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Senha"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit" className="w-100">
                            Entrar
                        </Button>
                    </Form>
                    {error && <Alert className="mt-4" variant="danger">{error}</Alert>}
                </Card.Body>
            </Card>
        </Container>
    );
};

export default Login;
