// src/GoogleAnalytics.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('userToken'); // Pega o token do localStorage

    const sendPageView = (path) => {
      if (window.gtag) {
        // Envia o token como uma propriedade do usuário
        window.gtag('set', 'user_properties', { user_id: token });

        // Envia o evento de visualização de página com o token
        window.gtag('event', 'page_view', {
          page_path: path,
          page_title: document.title,
          user_token: token, // Inclui o token nos parâmetros
        });
      }
    };

    sendPageView(location.pathname + location.search);
  }, [location]);

  return null;
};

export default GoogleAnalytics;
