import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosConfig'; // Importando a configuração do axios
import { Card, Dropdown, Button, Form, Row, Col } from 'react-bootstrap';
import { FaMoneyBillWave, FaDollarSign, FaChartLine, FaSearch, FaSort } from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

// Estilos globais
const GlobalStyle = createGlobalStyle`
  @media (max-width: 767px) {
    div#root {
      width: fit-content;
    }
  }

  .modal-content {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-left: 170px;

    @media (max-width: 767px) {
      margin-left: 0;
      margin-left: -25px;
      width: 420px;
    }
  }

  [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
    width: auto;
  }

  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin-left: -40px;

    @media (max-width: 767px) {
      margin-left: 0;
      padding-top: 40px;
    }
  }

  .modal-footer > * {
    margin: auto;
    width: 100px;

    @media (max-width: 767px) {
      margin-left: 0;
      width: 100%;
    }
  }
`;

const GerenciamentoContainer = styled.div`
  display: flex;
 

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Sidebar = styled.div`
  width: 280px;
  background-color: #343a40;
  color: #343a40;
  padding: 20px;
  position: fixed;
  height: 100%;
  overflow-y: auto;

   .nav {
    display: flex;
    align-items: start;
    gap: 20px;
    margin-left: 2px;
}

  @media (max-width: 768px) {
    width: 450px;
    height: auto;
    position: relative;
    margin-left: -25px;
  }
`;

const Content = styled.div`
  margin-left: 280px;
  padding: 80px;
  width: calc(100% - 280px);
  overflow-y: auto;

  @media (max-width: 768px) {
    margin-left: -30px;
    width: 458px;
    padding: 20px;
  }
`;

const UpdateButton = styled.button`
  background-color: #009879;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  font-family: sans-serif;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin: 20px 0;

  &:hover {
    background-color: #007f67;
    transform: scale(1.05);
  }

  &:active {
    background-color: #005f4a;
    transform: scale(1);
  }
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

  thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
  }

  th, td {
    padding: 12px 15px;
    width: auto;
  }

  tbody tr {
    border-bottom: 1px solid #dddddd;

    &:nth-of-type(even) {
      background-color: #f3f3f3;
    }

    &:last-of-type {
      border-bottom: 2px solid #009879;
    }

    &.active-row {
      font-weight: bold;
      color: #009879;
    }
  }

  @media (max-width: 768px) {
    font-size: 0.8em;
    th, td {
      padding: 8px;
    }
  }

  @media (max-width: 576px) {
    font-size: 0.7em;
    th, td {
      padding: 6px;
    }
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media (max-width: 576px) {
    flex-direction: column;
    
    
  }
`;

const StyledCard = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  align-items: center;
  width: auto;
  height: 90px;

  & > svg {
    flex-shrink: 0; /* Garante que o ícone não encolha */
    margin-right: 15px; /* Espaçamento entre o ícone e o texto */
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centraliza verticalmente o conteúdo */
  }

  h6 {
    margin: 0;
    font-size: 1em;
    color: #343a40;
  }

  p {
    margin: 0;
    font-size: 1.2em;
    font-weight: bold;
    color: #009879;
  }

  @media (max-width: 767px) {
    padding: 11px;
    margin-top: -10px;
    width: 100%;
    

    & > svg {
      margin-right: 5px; /* Ajusta o espaçamento em telas menores */
    }

    h6 {
      font-size: 0.9em;
    }

    p {
      font-size: 1em;
    }
  }
`;

const UserActivityReport = () => {
  const [activities, setActivities] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [filter, setFilter] = useState({ userId: '', username: '', route: '', startDate: '', endDate: '' });
  const [totals, setTotals] = useState({ totalCost: 0, totalCharged: 0, totalProfit: 0 });
  const [todayCount, setTodayCount] = useState(0);
  const [sortOrder, setSortOrder] = useState('desc'); // Estado para controle da ordenação
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserActivities();
    fetchTodayActivityCount();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [filter, activities, sortOrder]);

  const fetchUserActivities = async () => {
    try {
      const response = await axiosInstance.get('/api/Admin/GetUserActivities');
      setActivities(response.data);
      setFilteredActivities(response.data);
      const calculatedTotals = calculateTotals(response.data);
      setTotals(calculatedTotals);
    } catch (error) {
      console.error('Erro ao buscar atividades dos usuários:', error);
    }
  };

  const fetchTodayActivityCount = async () => {
    try {
      const response = await axiosInstance.get('/api/Admin/GetTodayActivityCount');
      setTodayCount(response.data);
    } catch (error) {
      console.error('Erro ao buscar a contagem de atividades do dia:', error);
    }
  };

  const calculateTotals = (logs) => {
    return logs.reduce(
      (totals, log) => {
        totals.totalCost += log.cost;
        totals.totalCharged += log.chargedAmount;
        totals.totalProfit += log.profit;
        return totals;
      },
      { totalCost: 0, totalCharged: 0, totalProfit: 0 }
    );
  };

  const applyFilters = () => {
    let filtered = activities;

    if (filter.userId) {
      filtered = filtered.filter(log => log.userId.toString().includes(filter.userId));
    }

    if (filter.username) {
      filtered = filtered.filter(log => log.userName.toLowerCase().includes(filter.username.toLowerCase()));
    }

    if (filter.route) {
      filtered = filtered.filter(log => log.route.toLowerCase().includes(filter.route.toLowerCase()));
    }

    if (filter.startDate) {
      filtered = filtered.filter(log => new Date(log.requestTime) >= new Date(filter.startDate));
    }

    if (filter.endDate) {
      filtered = filtered.filter(log => new Date(log.requestTime) <= new Date(filter.endDate));
    }

    // Ordenar os registros com base na data
    filtered = filtered.sort((a, b) => {
      if (sortOrder === 'asc') {
        return new Date(a.requestTime) - new Date(b.requestTime);
      } else {
        return new Date(b.requestTime) - new Date(a.requestTime);
      }
    });

    setFilteredActivities(filtered);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter(prevFilter => ({ ...prevFilter, [name]: value }));
  };

  const handleSortChange = () => {
    setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  const handleLogout = () => {
    navigate('/login');
  };

  const formatCurrency = (value) => {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  return (
    <>
      <GlobalStyle /> {/* Incluindo estilos globais */}
      <GerenciamentoContainer>
        <Sidebar>
          <a className="d-flex align-items-center mb-3 mb-md-auto me-md-auto text-white text-decoration-none">
            <span className="fs-4">SISCRV</span>
          </a>
          <hr />
          <ul className="nav nav-pills flex-column mb-auto">
            <li className="nav-item">
              <Link to="/Admin/GerenciamentoUsuariosDia" className="nav-link active" aria-current="page">
                Relatório Diário
              </Link>
            </li>
            <li>
              <Link to="/Admin/services" className="nav-link text-white">
                Custos | Preços
              </Link>
            </li>
            <li>
              <Link to="/Admin/Gerenciamento" className="nav-link text-white">
                Gerenciamento
              </Link>
            </li>
            <li>
              <Link to="/Admin/InserirCreditos" className="nav-link text-white">
                Novo Usuário | Inserir Crédito
              </Link>
            </li>
            <li>
              <Link to="/Admin/GerenciamentoCreditos" className="nav-link text-white">
              Relatório de Créditos
              </Link>
            </li>
            <li>
              <Link to="/Admin/GerenciamentoUsuariosMes" className="nav-link text-white">
                Relatório Mensal
              </Link>
            </li>
          </ul>
          <hr />
          <Dropdown>
            <Dropdown.Toggle variant="dark" id="dropdownUser1" className="d-flex align-items-center text-white text-decoration-none">
              <img src="https://avatars.githubusercontent.com/u/39032772?s=400&u=be2f63c766595d29cd10e2c0b40a6a1f6c421310&v=4" alt="" width="32" height="32" className="rounded-circle me-2" />
              <strong>Administrador</strong>
            </Dropdown.Toggle>

            <Dropdown.Menu variant="dark">
              <Dropdown.Item href="#">New project...</Dropdown.Item>
              <Dropdown.Item href="#">Settings</Dropdown.Item>
              <Dropdown.Item href="#">Profile</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={handleLogout}>Sair</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <hr />
          <CardContainer>
            <StyledCard>
              <FaMoneyBillWave size={24} />
              <div>
                <h6>Custo Total</h6>
                <p>{formatCurrency(totals.totalCost)}</p>
              </div>
            </StyledCard>
            <StyledCard>
              <FaDollarSign size={24} />
              <div>
                <h6>Valor Total</h6>
                <p>{formatCurrency(totals.totalCharged)}</p>
              </div>
            </StyledCard>
            <StyledCard>
              <FaChartLine size={24} />
              <div>
                <h6>Lucro Total</h6>
                <p>{formatCurrency(totals.totalProfit)}</p>
              </div>
            </StyledCard>
            <StyledCard>
              <FaSearch size={24} />
              <div>
                <h6>Consultas</h6>
                <p>{todayCount}</p>
              </div>
            </StyledCard>
          </CardContainer>
          <UpdateButton onClick={() => { fetchUserActivities(); fetchTodayActivityCount(); }}>Atualizar</UpdateButton>
        </Sidebar>

        <Content>
          <div style={{ width: '100%', maxWidth: '1200px', display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
            <div className="table-responsive">
              <h1>RELATÓRIO DE CONSULTAS DIÁRIAS</h1>
              <Card>
                <Card.Body>
                  <Form>
                    <Row>
                      <Col>
                        <Form.Group controlId="userIdFilter">
                          <Form.Label>ID do Usuário</Form.Label>
                          <Form.Control
                            type="text"
                            name="userId"
                            value={filter.userId}
                            onChange={handleFilterChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="usernameFilter">
                          <Form.Label>Nome do Usuário</Form.Label>
                          <Form.Control
                            type="text"
                            name="username"
                            value={filter.username}
                            onChange={handleFilterChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="routeFilter">
                          <Form.Label>Rota</Form.Label>
                          <Form.Control
                            type="text"
                            name="route"
                            value={filter.route}
                            onChange={handleFilterChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="startDateFilter">
                          <Form.Label>Data Inicial</Form.Label>
                          <Form.Control
                            type="date"
                            name="startDate"
                            value={filter.startDate}
                            onChange={handleFilterChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="endDateFilter">
                          <Form.Label>Data Final</Form.Label>
                          <Form.Control
                            type="date"
                            name="endDate"
                            value={filter.endDate}
                            onChange={handleFilterChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col className="d-flex align-items-end">
                        <Button variant="primary" onClick={applyFilters}>Aplicar Filtros</Button>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
              <Button variant="secondary" onClick={handleSortChange} className="mb-3">
                Ordenar por Data {sortOrder === 'asc' ? 'Mais Antiga' : 'Mais Recente'} <FaSort />
              </Button>
              <StyledTable className="table table-striped table-hover table-bordered">
                <thead>
                  <tr>
                    <th>Registros</th>
                    <th>ID do Usuário</th>
                    <th>Usuário</th>
                    <th>Rota</th>
                    <th>Arquivo</th>
                    <th>Custo</th>
                    <th>Valor Cobrado</th>
                    <th>Lucro</th>
                    <th>Data da Requisição</th>
                    <th>Créditos Atuais</th>
                    <th>Créditos Anteriores</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredActivities.map((log, index) => (
                    <tr key={log.id} className={index % 2 === 0 ? '' : 'active-row'}>
                      <td>{log.id}</td>
                      <td>{log.userId}</td>
                      <td>{log.userName}</td>
                      <td>{log.route}</td>
                      <td>{log.fileName}</td>
                      <td>{formatCurrency(log.cost)}</td>
                      <td>{formatCurrency(log.chargedAmount)}</td>
                      <td>{formatCurrency(log.profit)}</td>
                      <td>{new Date(log.requestTime).toLocaleString()}</td>
                      <td>{formatCurrency(log.currentCredits)}</td>
                      <td>{formatCurrency(log.previousCredits)}</td>
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            </div>
          </div>
        </Content>
      </GerenciamentoContainer>
    </>
  );
};

export default UserActivityReport;
