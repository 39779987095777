// axiosConfig.js
import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://crlvnahora.com',  //'https://crlvnahora.com', //'https://localhost:7076', 
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache', // Desativar cache
    'Pragma': 'no-cache',
    'Expires': '0'
  }
});

// Interceptor para adicionar token de autenticação se necessário
instance.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

export default instance;
