import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosConfig'; // Importando a configuração do axios
import { Form, Button, Container, Card, Alert, Row, Col, Dropdown } from 'react-bootstrap';
import { FaTasks, FaMoneyBillWave } from 'react-icons/fa'; // Importando o ícone
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';

// Estilos específicos para a página de gerenciamento
const GerenciamentoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  button.btn.btn-primary {
    width: -webkit-fill-available;
}
    .ms-3 {
    margin-left: 0;
}

  @media (min-width: 768px) {
    flex-direction: row;
  }

  .sidebar {
    background-color: #343a40;
    color: #fff;
    padding: 20px;
    position: relative;
    height: auto;
    overflow-y: auto;
    width: 450px;

    .nav {
      display: flex;
      align-items: start;
      margin-left: 2px;
    }
    .card.navbar.lateral {
      width: auto;
    }

    @media (min-width: 768px) {
      width: 280px;
      position: fixed;
      height: 100vh;
    }

    .nav-link {
      color: #fff;
      margin: 10px 0;
    }

    .nav-link.active {
      background-color: #007bff;
    }

    .nav-link:hover {
      background-color: #495057;
    }

    .dropdown-toggle::after {
      display: none;
    }

    .card-small {
      margin-top: 20px;
    }
  }

  .content {
    margin-left: 0;
    padding: 20px;
    width: 100%;

    @media (min-width: 768px) {
      margin-left: 300px;
      width: calc(100% - 300px);
    }

    .card {
      margin-bottom: 20px;
      width: auto;
    }
  }
  p {
    margin: 0;
    font-size: 1.2em;
    font-weight: bold;
    color: #009879;
  }

  @media (max-width: 767px) {
    div#root {
      width: 472px;
      margin-left: -20px;
    }

    .content div {
      width: auto;
      padding: 11px;
      margin-top: -10px;
      flex-direction: column; /* Configuração para responsividade mobile */
    }
  }

  .card-header, .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
  }
`;

const ServicesManager = () => {
  const [services, setServices] = useState([]);
  const [newService, setNewService] = useState({ name: '', cost: '', price: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [saldo, setSaldo] = useState(null);
  const [loadingSaldo, setLoadingSaldo] = useState(false);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const response = await axiosInstance.get('/api/admin/Services');
      if (Array.isArray(response.data)) {
        setServices(response.data);
      } else {
        throw new Error('A resposta da API não é um array.');
      }
    } catch (error) {
      console.error('Erro ao buscar serviços:', error);
      setErrorMessage('Erro ao buscar serviços.');
    }
  };

  const fetchSaldo = async () => {
    setLoadingSaldo(true);
    try {
      const response = await axiosInstance.post('/api/admin/GetSaldoAnyCar');
      if (response.data && response.data.valor !== undefined) {
        setSaldo(response.data.valor);
      } else {
        throw new Error('Erro ao consultar saldo.');
      }
    } catch (error) {
      console.error('Erro ao consultar saldo:', error);
      setErrorMessage('Erro ao consultar saldo.');
    } finally {
      setLoadingSaldo(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewService({ ...newService, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post('/api/admin/Services', newService);
      setNewService({ name: '', cost: '', price: '' });
      fetchServices();
      setSuccessMessage('Serviço adicionado com sucesso.');
    } catch (error) {
      console.error('Erro ao adicionar serviço:', error);
      setErrorMessage('Erro ao adicionar serviço.');
    }
  };

  const handleUpdate = async (id) => {
    const service = services.find((service) => service.id === id);
    try {
      await axiosInstance.put(`/api/admin/Services/${id}`, service);
      fetchServices();
      setSuccessMessage('Serviço atualizado com sucesso.');
    } catch (error) {
      console.error('Erro ao atualizar serviço:', error);
      setErrorMessage('Erro ao atualizar serviço.');
    }
  };

  const handleServiceChange = (id, e) => {
    const { name, value } = e.target;
    setServices(
      services.map((service) =>
        service.id === id ? { ...service, [name]: value } : service
      )
    );
  };

  const handleUpdatePrices = async () => {
    try {
      const response = await axiosInstance.get('/api/admin/Services');
      const servicePrices = response.data.reduce((acc, service) => {
        acc[service.name] = service.price;
        return acc;
      }, {});

      const updatedServices = [
        { label: `Consulta Veicular R$${servicePrices.ConsultaVP} - (Emissão na hora)`, value: 'ConsultaVP', price: servicePrices.ConsultaVP },
        { label: `2 via ATPV-e - R$${servicePrices['2viaATPVe']} - (Emissão na hora)`, value: '2viaATPVe', price: servicePrices['2viaATPVe'] },
        { label: `CRV Digital - R$${servicePrices.CrvDigital} - (Emissão na hora)`, value: 'CrvDigital', price: servicePrices.CrvDigital },
        { label: `CRLV AC - R$${servicePrices.CrlvAC} - (Emissão na hora)`, value: 'CrlvAC', price: servicePrices.CrlvAC },
        { label: `CRLV AM - R$${servicePrices.CrlvAM}`, value: 'CrlvAM', price: servicePrices.CrlvAM },
        { label: `CRLV AP - R$${servicePrices.CrlvAP} - (Emissão na hora)`, value: 'CrlvAP', price: servicePrices.CrlvAP },
        { label: `CRLV BA - R$${servicePrices.CrlvBA}`, value: 'CrlvBA', price: servicePrices.CrlvBA },
        { label: `CRLV CE - R$${servicePrices.CrlvCE}`, value: 'CrlvCE', price: servicePrices.CrlvCE },
        { label: `CRLV DF - R$${servicePrices.CrlvDF}`, value: 'CrlvDF', price: servicePrices.CrlvDF },
        { label: `CRLV ES - R$${servicePrices.CrlvES}`, value: 'CrlvES', price: servicePrices.CrlvES },
        { label: `CRLV GO - R$${servicePrices.CrlvGO} - (Emissão na hora)`, value: 'CrlvGO', price: servicePrices.CrlvGO },
        { label: `CRLV MA - R$${servicePrices.CrlvMA} - (Emissão na hora)`, value: 'CrlvMA', price: servicePrices.CrlvMA },
        { label: `CRLV MG - R$${servicePrices.CrlvMG} - (Emissão na hora)`, value: 'CrlvMG', price: servicePrices.CrlvMG },
        { label: `CRLV MT - R$${servicePrices.CrlvMT} - (Emissão na hora)`, value: 'CrlvMT', price: servicePrices.CrlvMT },
        { label: `CRLV MS - R$${servicePrices.CrlvMS}`, value: 'CrlvMS', price: servicePrices.CrlvMS },
        { label: `CRLV PB - R$${servicePrices.CrlvPB}`, value: 'CrlvPB', price: servicePrices.CrlvPB },
        { label: `CRLV PE - R$${servicePrices.CrlvPE}`, value: 'CrlvPE', price: servicePrices.CrlvPE },
        { label: `CRLV PI - R$${servicePrices.CrlvPI}`, value: 'CrlvPI', price: servicePrices.CrlvPI },
        { label: `CRLV PR - R$${servicePrices.CrlvPR} - (Emissão na hora)`, value: 'CrlvPR', price: servicePrices.CrlvPR },
        { label: `CRLV RJ - R$${servicePrices.CrlvRJ} - (Emissão na hora)`, value: 'CrlvRJ', price: servicePrices.CrlvRJ },
        { label: `CRLV RN - R$${servicePrices.CrlvRN} - (Emissão na hora)`, value: 'CrlvRN', price: servicePrices.CrlvRN },
        { label: `CRLV RO - R$${servicePrices.CrlvRO}`, value: 'CrlvRO', price: servicePrices.CrlvRO },
        { label: `CRLV RR - R$${servicePrices.CrlvRR} - (Emissão na hora)`, value: 'CrlvRR', price: servicePrices.CrlvRR },
        { label: `CRLV RS - R$${servicePrices.CrlvRS}`, value: 'CrlvRS', price: servicePrices.CrlvRS },
        { label: `CRLV SC - R$${servicePrices.CrlvSC}`, value: 'CrlvSC', price: servicePrices.CrlvSC },
        { label: `CRLV SE - R$${servicePrices.CrlvSE}`, value: 'CrlvSE', price: servicePrices.CrlvSE },
        { label: `CRLV SP - R$${servicePrices.CrlvSP} - (Emissão na hora)`, value: 'CrlvSP', price: servicePrices.CrlvSP },
        { label: `CRLV TO - R$${servicePrices.CrlvTO} - (Emissão na hora)`, value: 'CrlvTO', price: servicePrices.CrlvTO }
      ];

      localStorage.setItem('services', JSON.stringify(updatedServices));
      alert('Preços atualizados com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar os preços:', error);
      alert('Erro ao atualizar os preços.');
    }
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/login');
  };

  return (
    <GerenciamentoWrapper>
      <div className="sidebar">
        <a className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
          <span className="fs-4">SISCRV</span>
        </a>
        <hr />
        <ul className="nav nav-pills flex-column mb-auto">
          <li className="nav-item">
            <Link to="/Admin/services" className="nav-link active" aria-current="page">
              Custos | Preços
            </Link>
          </li>
          <li>
            <Link to="/Admin/Gerenciamento" className="nav-link text-white">
              Gerenciamento
            </Link>
          </li>
          <li>
            <Link to="/Admin/InserirCreditos" className="nav-link text-white">
              Novo Usuário | Inserir Crédito
            </Link>
          </li>
          <li>
            <Link to="/Admin/GerenciamentoCreditos" className="nav-link text-white">
              Relatório de Créditos
            </Link>
          </li>
          <li>
            <Link to="/Admin/GerenciamentoUsuariosDia" className="nav-link text-white">
              Relatório Diário
            </Link>
          </li>
          <li>
            <Link to="/Admin/GerenciamentoUsuariosMes" className="nav-link text-white">
              Relatório Mensal
            </Link>
          </li>
        </ul>
        <hr />
        <Dropdown>
          <Dropdown.Toggle variant="dark" id="dropdownUser1" className="d-flex align-items-center text-white text-decoration-none">
            <img src="https://avatars.githubusercontent.com/u/39032772?s=400&u=be2f63c766595d29cd10e2c0b40a6a1f6c421310&v=4" alt="" width="32" height="32" className="rounded-circle me-2" />
            <strong>Administrador</strong>
          </Dropdown.Toggle>

          <Dropdown.Menu variant="dark">
            <Dropdown.Item href="#">New project...</Dropdown.Item>
            <Dropdown.Item href="#">Settings</Dropdown.Item>
            <Dropdown.Item href="#">Profile</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={handleLogout}>Sair</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <hr />
        <Card className="card navbar lateral">
          <Card.Body className="d-flex align-items-center">
            <FaTasks size={24} />
            <div className="ms-3">
              <h6>Serviços</h6>
              <p className="mb-0">{services.length}</p>
            </div>
          </Card.Body>
        </Card>
        <Card className="card navbar lateral mt-4">
          <Card.Body className="d-flex align-items-center">
            <FaMoneyBillWave size={24} /> {/* Ícone adicionado */}
            <div className="ms-3">
              <h6>Saldo AnyCar</h6>
              {loadingSaldo ? (
                <p>Carregando...</p>
              ) : (
                <p>{saldo !== null ? `R$${saldo.toFixed(2)}` : 'Saldo não disponível'}</p>
              )}
              <Button variant="primary" onClick={fetchSaldo}>Atualizar Saldo</Button>
            </div>
          </Card.Body>
        </Card>
      </div>

      <div className="content">
        <Container>
          <h2 className="my-4">Gerenciamento de Serviços</h2>
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
          <Form onSubmit={handleSubmit} className="mb-4">
            <Row className="align-items-center">
              <Col>
                <Form.Control
                  type="text"
                  name="name"
                  value={newService.name}
                  onChange={handleInputChange}
                  placeholder="Nome do Serviço"
                  required
                />
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  name="cost"
                  value={newService.cost}
                  onChange={handleInputChange}
                  placeholder="Custo do Serviço"
                  required
                />
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  name="price"
                  value={newService.price}
                  onChange={handleInputChange}
                  placeholder="Preço do Serviço"
                  required
                />
              </Col>
              <Col>
                <Button type="submit" variant="primary">Adicionar Serviço</Button>
                <Button variant="success" onClick={handleUpdatePrices} className="ms-2">Atualizar Preços</Button>
              </Col>
            </Row>
          </Form>
          <Row>
            {services.map((service) => (
              <Col md={4} key={service.id} className="mb-4">
                <Card>
                  <Card.Body>
                    <Form>
                      <Form.Group className="mb-3">
                        <Form.Label>Serviço</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={service.name}
                          onChange={(e) => handleServiceChange(service.id, e)}
                          placeholder="Nome do Serviço"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Custo</Form.Label>
                        <Form.Control
                          type="number"
                          name="cost"
                          value={service.cost}
                          onChange={(e) => handleServiceChange(service.id, e)}
                          placeholder="Custo do Serviço"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Preço</Form.Label>
                        <Form.Control
                          type="number"
                          name="price"
                          value={service.price}
                          onChange={(e) => handleServiceChange(service.id, e)}
                          placeholder="Preço do Serviço"
                        />
                      </Form.Group>
                      <Button variant="primary" onClick={() => handleUpdate(service.id)}>Atualizar Serviço</Button>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </GerenciamentoWrapper>
  );
};

export default ServicesManager;
