// src/components/CrvDigital.js

import styled, { createGlobalStyle } from 'styled-components';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from './axiosConfig';
import PDFViewer from './PDFViewer';
import { Card, Modal, Button, Form } from 'react-bootstrap';
import CustomNavbar from './Navbar';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    background: #fff;
  }
  
  .modal-content label {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-top: 10px;
    color: orangered;
  }
  
  @media (max-width: 767px) {
    .modal-header {
      width: auto;
    }
    .modal-body {
      position: relative;
      width: auto;
    }
    .modal-footer {
      display: flex;
      flex-shrink: 0;
      flex-wrap: wrap;
      justify-content: center;
      padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
      background-color: var(--bs-modal-footer-bg);
      border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
      border-bottom-right-radius: var(--bs-modal-inner-border-radius);
      border-bottom-left-radius: var(--bs-modal-inner-border-radius);
      width: auto;
    }
    .fade.modal.show {
      margin-left: 10px;
    }
    .modal-content {
      width: 100%;
      padding: 20px;
      margin-left: -5px;
    }
    .modal-content label {
      display: flex;
      align-items: center;
      justify-content: start;
      margin-top: 10px;
      color: orangered;
      font-size: medium;
    }
  }
`;

const MainContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;

  @media (max-width: 767px) {
    div {
      height: 100%;
      width: 360px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: -10px;
      background: #fff;
    }
  }
`;

const ContentContainer = styled.div`
  display: grid;
  padding: 20px;
  max-width: auto;
  box-shadow: none;
  grid-template-columns: repeat(3, 1fr);
  margin-left: 0px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    width: auto;
    background-color: #ffffff;
    margin-left: -300px;

    @media (max-width: 767px) {
      div {
        padding: 11px;
        background: transparent;
      }
      .lShME {
        padding: 6px;
        margin: 10px;
      }
      .kpYZsG {
        grid-template-columns: 1fr;
      }
      .py-3 {
        padding-top: 2rem !important;
      }
    }
  }
`;

const FormCard = styled(Card)`
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  background-color: #ffffff;
  width: 400px;

  .form-control {
    border: var(--bs-border-width) solid #0a0a0a;
    appearance: auto;
  }

  @media (max-width: 768px) {
    padding: 15px;
    height: auto;
    background-color: #ffffff;
    margin-left: 300px;

    select.form-control {
      appearance: auto;
      padding: 10px;  
      margin-left: 3px;
    }
  }
`;

const PdfCard = styled(Card)`
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  background-color: #ffffff;
  width: 400px; 

  @media (max-width: 768px) {
    padding: 0px;
    margin-left: 300px;
  }
`;

const TableCard = styled(Card)`
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  background-color: #ffffff;
  width: fit-content;
  margin-left: 30px;

  @media (max-width: 768px) {
    padding: 0px;
    margin-left: 300px;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-size: small;

  th, td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  th {
    background-color: #f2f2f2;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f1f1f1;
  }

  @media (max-width: 768px) {
    th, td {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 4px;
    }
  }
`;

const CreditsInfo = styled.p`
  margin-top: 4px;
  color: #516377;
`;

const SubmitButton = styled.button`
  background-color: ${(props) => (props.special ? '#28a745' : '#007bff')};
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  margin-top: 8px;
  width: 100%;
  &:disabled {
    background-color: #6c757d;
  }

  @media (max-width: 1020px) {
    padding: 8px 16px;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-top: 10px;
`;

const LoadingImage = styled.img`
  display: block;
  margin: 20px auto;
`;

const DocumentImage = styled.img`
  width: 100%;
  max-width: 600px;
  display: block;
  margin: 20px auto;

  @media (max-width: 1020px) {
    max-width: 100%;
  }
`;

const PdfDownloadLink = styled.a`
  display: block;
  text-align: center;
  margin-top: 20px;
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const RequestLogsTable = ({ logs }) => (
  <StyledTable>
    <thead>
      <tr>
        <th>Saldo Anterior</th>
        <th>Documento</th>
        <th>Preço</th>
        <th>Saldo Atual</th>
      </tr>
    </thead>
    <tbody>
      {logs
        .filter(log => log.chargedAmount !== 0)
        .slice(-8)
        .reverse()
        .map((log, index) => (
          <tr key={index}>
            <td>{formatCurrency(log.previousCredits)}</td>
            <td>{log.fileName}</td>
            <td>{formatCurrency(log.chargedAmount)}</td>
            <td>{formatCurrency(log.currentCredits)}</td>
          </tr>
        ))}
    </tbody>
  </StyledTable>
);

const formatCurrency = (amount) => {
  return amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
};

const CrvDigital = () => {
  const [placa, setPlaca] = useState('');
  const [username, setUsername] = useState('');
  const [token, setToken] = useState('');
  const [credits, setCredits] = useState(0);
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfName, setPdfName] = useState('documento.pdf');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [requestLogs, setRequestLogs] = useState([]);
  const [debouncedToken, setDebouncedToken] = useState(token);
  const [showImage, setShowImage] = useState(true);

  const trackPurchase = () => {
    // Disparar o evento personalizado para o GTM
    window.dataLayer.push({
        'event': 'successfulConsultation',
        'service': 'CrvDigital',
        'Value': '30,00'
    });
};

  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');
    if (storedToken) {
      setToken(storedToken);
    }
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('token', token);
    localStorage.setItem('username', username);
  }, [token, username]);

  const validatePlaca = (placa) => {
    const oldPattern = /^[A-Z]{3}[0-9]{4}$/i;
    const newPattern = /^[A-Z]{3}[0-9][A-J][0-9]{2}$/i;
    return oldPattern.test(placa) || newPattern.test(placa);
  };

  const handlePlacaChange = (e) => {
    const value = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '').slice(0, 7);
    setPlaca(value);
  };

  useEffect(() => {
    const fetchCredits = async () => {
      if (debouncedToken) {
        try {
          const response = await axiosInstance.get('/api/user/credits', {
            params: { token: debouncedToken }
          });
          setCredits(response.data.credits);
        } catch (error) {
          console.error('Erro ao obter créditos:', error);
          setError('Erro ao obter créditos do usuário.');
          setShowErrorModal(true);
        }
      }
    };

    fetchCredits();
  }, [debouncedToken]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedToken(token);
    }, 3000);

    return () => {
      clearTimeout(handler);
    };
  }, [token]);

  useEffect(() => {
    if (token) {
      fetchRequestLogs(token);
    }
  }, [token]);

  const fetchRequestLogs = async (token) => {
    try {
      const response = await axiosInstance.post('/CRV/FilteredLogs', { token });
      setRequestLogs(response.data);
    } catch (error) {
      console.error('Erro ao buscar registros:', error);
    }
  };

  const handleRequest = async () => {
    if (!validatePlaca(placa)) {
      setError('Por favor, insira uma placa válida.');
      setShowErrorModal(true);
      return;
    }

    if (!placa || !username || !token) {
      setError('Por favor, preencha todos os campos obrigatórios.');
      setShowErrorModal(true);
      return;
    }

    setError('');
    setPdfUrl('');
    setLoading(true);
    setShowImage(false);

    try {
      const response = await axiosInstance.post('/CRV/BuscaDados2', null, {
        params: { placa, username, token, service: 'CrvDigital' },
        responseType: 'blob'
      });

      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const contentDisposition = response.headers['content-disposition'];
      let fileName = `${placa}_CrvDigital.pdf`;
      if (contentDisposition && contentDisposition.includes('attachment')) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch && fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
        }
      }

      setCredits((prevCredits) => prevCredits - response.data.price);
      setPdfUrl(pdfUrl);
      setPdfName(fileName);
      fetchRequestLogs(token);

      // Disparar o evento de consulta bem-sucedida para o GTM
      trackPurchase();


    } catch (error) {
      console.error('Erro ao fazer a requisição:', error);
      if (error.response) {
        if (error.response.status === 303) {
          setError('Veículo não encontrado! Verifique se digitou a placa corretamente.');
        } else if (error.response.status === 401) {
          setError('Token inválido ou expirado.');
        } else if (error.response.status === 400) {
          setError('Não foi possível emitir o documento. O veículo pode ter débitos ou restrições que impedem a emissão do documento digital. Acesse o site do Detran para verificar a situação do veículo.');
        } else if (error.response.status === 500) {
          setError('Confira se escolheu a opção correta no menu "Selecione um serviço" para emissão do CRLV.');
        } else if (error.response.status === 503) {
          setError('Créditos insuficientes! Insira novos créditos acessando a página Recarga Pix e realize sua consulta.');
        } else if (error.response.status === 504) {
          setError('Não foi possível emitir o documento. O veículo pode ter débitos ou restrições que impedem a emissão do documento digital. Acesse o site do Detran para verificar a situação do veículo.');
        } else if (error.response.status === 600) {
          setError('Não foi possível emitir o documento. O veículo pode ter débitos pendentes ou restrições que impedem a emissão do documento digital. Acesse o site do Detran e consulte a situação do veículo antes de tentar novamente.');
        } else if (error.response.status === 601) {
          setError('Não foi possível obter os dados (Renavam, CPF/CNPJ) necessários para prosseguir com a consulta. Se o erro persistir, entre em contato com o suporte pelo WhatsApp: (11) 9 9918-4789.');
        } else if (error.response.status === 602) {
          setError('Não foi possível obter os dados (Renavam, CPF/CNPJ) necessários para prosseguir com a consulta. Se o erro persistir, entre em contato com o suporte pelo WhatsApp: (11) 9 9918-4789.');
        } else if (error.response.status === 603) {
          setError('CRV Digital não encontrado. Verifique se o veículo já tem comunicação de venda');
        } else if (error.response.status === 802) {
          setError('CRV Digital não encontrado. Se o erro persistir, fale com suporte pelo WhatsApp (11)9 9918-4789');
        } else if (error.response.status === 801) {
          setError('CRV Digital não encontrado. Não existe intenção de venda ou a comunicação de venda já foi realizada e o CRV Digital saiu do sistema do Detran');
        } else if (error.response.status === 800) {
          setError('Não foi possível emitir o documento! Verifique a situação do veículo no site do Detran, pois o documento pode não ser digital ou a emissão está bloqueada.');
        }
      } else {
        setError('Erro ao fazer a requisição. Se o erro persistir fale com suporte pelo WhatsApp (11)9 9918-4789');
      }
      setShowErrorModal(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadClick = () => {
    if (pdfUrl) {
      // Usuário clicou para baixar o PDF, não exibe o modal de confirmação
    }
  };

  return (
    <MainContainer>
      <GlobalStyle />
      <CustomNavbar />
      <ContentContainer className="sisdok-container d-grid gap-3">
        <FormCard className="shadow p-4">
          <h1>CRV Digital - R$30</h1>
          <div>
            <Form.Control
              type="text"
              placeholder="Placa"
              value={placa}
              onChange={handlePlacaChange}
              className="form-control"
            />
            <Form.Control
              type="text"
              placeholder="Nome de usuário"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="form-control"
            />
            <Form.Control
              type="text"
              placeholder="Token"
              value={token}
              onChange={(e) => setToken(e.target.value)}
              className="form-control"
            />
            <CreditsInfo>Créditos disponíveis: R${credits.toFixed(2)}</CreditsInfo>
            <SubmitButton onClick={handleRequest}>
              Emitir Documento
            </SubmitButton>
            {loading && <LoadingImage src="carregamento.gif" alt="Carregando..." />}
          </div>
        </FormCard>
        <PdfCard className="shadow p-4">
          <div>
            {showImage && !pdfUrl && (
              <DocumentImage src="crvdigital.png" alt="CRV Digital" />
            )}
            {pdfUrl && !loading && (
              <div>
                <PDFViewer pdfUrl={pdfUrl} />
                <PdfDownloadLink href={pdfUrl} download={pdfName} onClick={handleDownloadClick}>Download do PDF</PdfDownloadLink>
              </div>
            )}
          </div>
        </PdfCard>
        <TableCard className="shadow p-4">
          <h3>Histórico Recente</h3>
          <RequestLogsTable logs={requestLogs} />
        </TableCard>
      </ContentContainer>
      {showErrorModal && (
        <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Erro</Modal.Title>
          </Modal.Header>
          <Modal.Body>{error}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </MainContainer>
  );
};

export default CrvDigital;
