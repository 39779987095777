import React, { useState } from 'react';
import axiosInstance from './axiosConfig';
import { Form, Button, Card, Alert, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import CustomNavbar from './Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCreditCard, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FaUser, FaLock } from 'react-icons/fa';
import ReactPixel from 'react-facebook-pixel';

const GlobalStyle = createGlobalStyle`
html {
    background-color: #fff;
}

@media (max-width: 768px) {
    .form-label {
        font-weight: bold;
        width: fit-content;
    }
    .form-control {
        display: block;
        width: fit-content;
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: var(--bs-body-color);
        appearance: none;
        background-color: var(--bs-body-bg);
        background-clip: padding-box;
        border: var(--bs-border-width) solid var(--bs-border-color);
        border-radius: var(--bs-border-radius);
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }
    .mb-3 {
        margin-bottom: 1rem !important;
        width: fit-content;
    }
    .modal-body {
        position: relative;
        flex: 1 1 auto;
        padding: var(--bs-modal-padding);
        width: auto;
    }
    .modal-footer {
        display: flex;
        flex-shrink: 0;
        flex-wrap: wrap;
        align-items: center;
        justify-content: normal;
        background-color: var(--bs-modal-footer-bg);
        border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
        border-bottom-right-radius: var(--bs-modal-inner-border-radius);
        border-bottom-left-radius: var(--bs-modal-inner-border-radius);
        margin-left: -10px;
    }
    .form-check {
        display: flex;
        align-items: center;
        margin-bottom: .125rem;
    }
    .form-check-input {
        margin-right: 10px;
        margin-left: 0;
        width: 1.25em;
        height: 1.25em;
        border-radius: .25em;
        background-color: #fff;
        border: 1px solid #0d6efd;
    }
    .form-check-input:checked {
        background-color: #0d6efd;
        border-color: #0d6efd;
    }
    .modal-title {
        margin-bottom: 0;
        line-height: var(--bs-modal-title-line-height);
        width: 100%;
        font-size: larger;
    }
    .modal-content p {
        font-size: 1.3em;
    }
        .alert-danger {
    margin-top: 1rem;
    /* margin-left: -40px; */
    width: auto;
}
}
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;

    @media (max-width: 768px) {
        display: contents;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;

    @media (max-width: 768px) {
        flex-direction: column;
        margin-left: -15px;
        margin-top: -190px;
    }
`;

const FormContainer = styled.div`
    flex: 1;
    padding: 20px;
    background-color: #fff;

    @media (max-width: 1366px) {
        margin-top: 200px;
    }
`;

const ImageContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #fff;

    img {
        max-width: 800px;
        height: auto;
        border-radius: 10px;
    }

    @media (max-width: 768px) {
        img {
            content: url('img/EstadosMobile.png');
            max-width: 520px;
        }
    }

    @media (max-width: 1366px) {
        margin-top: 0px;
    }
`;

const RegisterForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [whatsAppNumber, setWhatsAppNumber] = useState('');
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [email, setEmail] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isAgreed, setIsAgreed] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const navigate = useNavigate();

    const validateCpfCnpj = (value) => {
        const cleanedValue = value.replace(/\D/g, '');
        if (cleanedValue.length === 11) {
            return validateCPF(cleanedValue);
        } else if (cleanedValue.length === 14) {
            return validateCNPJ(cleanedValue);
        }
        return false;
    };

    const validateCPF = (cpf) => {
        let sum = 0;
        let remainder;

        if (cpf === "00000000000") return false;
        for (let i = 1; i <= 9; i++) sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
        remainder = (sum * 10) % 11;

        if ((remainder === 10) || (remainder === 11)) remainder = 0;
        if (remainder !== parseInt(cpf.substring(9, 10))) return false;

        sum = 0;
        for (let i = 1; i <= 10; i++) sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
        remainder = (sum * 10) % 11;

        if ((remainder === 10) || (remainder === 11)) remainder = 0;
        if (remainder !== parseInt(cpf.substring(10, 11))) return false;
        return true;
    };

    const validateCNPJ = (cnpj) => {
        if (cnpj.length !== 14) return false;

        if (cnpj === "00000000000000" || cnpj === "11111111111111" ||
            cnpj === "22222222222222" || cnpj === "33333333333333" ||
            cnpj === "44444444444444" || cnpj === "55555555555555" ||
            cnpj === "66666666666666" || cnpj === "77777777777777" ||
            cnpj === "88888888888888" || cnpj === "99999999999999")
            return false;

        let size = cnpj.length - 2;
        let numbers = cnpj.substring(0, size);
        let digits = cnpj.substring(size);
        let sum = 0;
        let pos = size - 7;
        for (let i = size; i >= 1; i--) {
            sum += numbers.charAt(size - i) * pos--;
            if (pos < 2) pos = 9;
        }
        let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
        if (result !== parseInt(digits.charAt(0))) return false;

        size = size + 1;
        numbers = cnpj.substring(0, size);
        sum = 0;
        pos = size - 7;
        for (let i = size; i >= 1; i--) {
            sum += numbers.charAt(size - i) * pos--;
            if (pos < 2) pos = 9;
        }
        result = sum % 11 < 2 ? 0 : 11 - sum % 11;
        if (result !== parseInt(digits.charAt(1))) return false;

        return true;
    };

    const validateForm = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (username.length === 0 || username.length > 50) {
            setError('Nome de usuário deve ter entre 1 e 50 caracteres.');
            return false;
        }
        if (password.length < 6 || password.length > 50) {
            setError('Senha deve ter entre 6 e 50 caracteres.');
            return false;
        }
        if (!emailPattern.test(email)) {
            setError('Email inválido.');
            return false;
        }
        if (!validateCpfCnpj(cpfCnpj)) {
            setError('CPF/CNPJ inválido.');
            return false;
        }
        if (whatsAppNumber.length < 14 || whatsAppNumber.length > 16) {
            setError('Número de WhatsApp deve ter entre 14 e 15 caracteres.');
            return false;
        }
        return true;
    };

    const handleRegister = async () => {
        if (!validateForm()) {
            return;
        }
        try {
            const response = await axiosInstance.post('/api/User/Register', {
                username: username.toUpperCase(),
                password,
                whatsAppNumber,
                cpfCnpj,
                email: email.toUpperCase()
            });
            const userData = response.data;
            localStorage.setItem('email', email);
            localStorage.setItem('cpfCnpj', cpfCnpj);
            localStorage.setItem('username', username);
            localStorage.setItem('token', userData.token);
            setUserDetails(userData);
            setSuccessMessage('Usuário criado com sucesso!');
            setError('');
            setShowModal(false);
            setShowSuccessModal(true);

                    // Disparar o evento personalizado para o GTM
            window.dataLayer.push({
                'event': 'userRegistration',
               
            });

            ReactPixel.track('CompleteRegistration', {
                content_name: username,
                value: 1.00,
                currency: 'BRL'
            });
        } catch (error) {
            if (error.response && error.response.data) {
                setShowModal(false);
                setError(error.response.data);

            } else {
                setError('Erro ao criar usuário. Verifique os detalhes.');
            }
            console.error('Erro ao criar usuário:', error);
        }
    };

    const formatWhatsAppNumber = (value) => {
        const digits = value.replace(/\D/g, '');
        if (digits.length === 11) {
            return digits.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})$/, '($1) $2 $3-$4');
        } else if (digits.length === 10) {
            return digits.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
        }
        return value;
    };

    const handleWhatsAppChange = (e) => {
        setWhatsAppNumber(formatWhatsAppNumber(e.target.value));
    };

    const formatCpfCnpj = (value) => {
        const digits = value.replace(/\D/g, '');
        if (digits.length <= 11) {
            return digits.replace(/(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
        } else {
            return digits.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
        }
    };

    const handleCpfCnpjChange = (e) => {
        setCpfCnpj(formatCpfCnpj(e.target.value));
    };

    const handleShowModal = () => {
        if (validateForm()) {
            setShowModal(true);
        }
    };

    const handleAgree = () => {
        if (isAgreed) {
            handleRegister();
        } else {
            setError('Você deve concordar com os Termos de Uso e a LGPD.');
        }
    };

    const [showSuccessModal, setShowSuccessModal] = useState(false);

    return (
        <>
            <CustomNavbar />
            <GlobalStyle />
            <Container>
                <InnerContainer>
                    <FormContainer>
                        <Card className="shadow p-4 mb-4">
                            <Card.Body>
                                <h2 className="text-center mb-4">Criar Novo Usuário</h2>
                                <Form>
                                    <Form.Group className="mb-3" controlId="formNewUsername">
                                        <Form.Label>Nome de Usuário</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Novo nome de usuário"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            maxLength={50}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formNewPassword">
                                        <Form.Label>Senha</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Nova senha"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            maxLength={50}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formWhatsAppNumber">
                                        <Form.Label>Número de WhatsApp</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="(XX) X XXXX-XXXX"
                                            value={whatsAppNumber}
                                            onChange={handleWhatsAppChange}
                                            maxLength={16}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formCpfCnpj">
                                        <Form.Label>CPF/CNPJ</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="CPF ou CNPJ"
                                            value={cpfCnpj}
                                            onChange={handleCpfCnpjChange}
                                            maxLength={18}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            maxLength={50}
                                        />
                                    </Form.Group>

                                    <Button variant="primary" className="w-100" onClick={handleShowModal}>
                                        Criar Usuário
                                    </Button>
                                </Form>

                                {error && <Alert className="mt-4" variant="danger">{error}</Alert>}
                                {successMessage && <Alert className="mt-4" variant="success">{successMessage}</Alert>}
                            </Card.Body>
                        </Card>
                    </FormContainer>
                    <ImageContainer>
                        <img src="img/CRLV1.png" alt="Placeholder" />
                    </ImageContainer>
                </InnerContainer>
            </Container>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Termos de Uso e LGPD</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Ao criar uma conta, você concorda com nossos Termos de Uso e a Política de Privacidade de acordo com a LGPD.</p>
                    <Form.Check
                        type="checkbox"
                        label="Li e concordo com os Termos de Uso e LGPD"
                        checked={isAgreed}
                        onChange={() => setIsAgreed(!isAgreed)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Não estou de acordo
                    </Button>
                    <Button variant="primary" onClick={handleAgree}>
                        Estou de acordo
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Usuário Criado com Sucesso</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <FontAwesomeIcon icon={faCheckCircle} size="3x" className="text-success mb-3" />
                    <p><strong><FaUser /> Usuário:</strong> {userDetails?.username}</p>
                    <p><strong><FaLock /> Token:</strong> {userDetails?.token}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => navigate('/Pagamentos')}>
                        <FontAwesomeIcon icon={faCreditCard} className="me-2" />
                        Adicionar Crédito
                    </Button>
                    <Button variant="secondary" onClick={() => navigate('/')}>
                        <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
                        Sair
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default RegisterForm;
