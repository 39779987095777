// TutorialPage.js
import React, { useState } from 'react';
import { Container, Row, Col, Card, Modal, Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/TutorialPage.css'; // Use um CSS específico para esta página
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomNavbar from './Navbar'; // Importando o CustomNavbar

const VideoTutorial = ({ videoUrl, title }) => (
  <div className="video-tutorial">
    <h2 className="video-title">{title}</h2>
    <div className="video-responsive">
      <iframe
        src={videoUrl}
        title={title}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  </div>
);

const TutorialSection = ({ title, description, icon, videoUrl, imageUrl }) => (
  <Card className="mb-4 shadow">
    <Card.Body>
      <Card.Title className="page-title mb-4">{title}</Card.Title>
      <Card.Text className="text-left mb-4">
        <img src={icon} alt={title} className="mr-2" width="24" height="24" />
        {description}
      </Card.Text>
      {videoUrl && (
        <div className="text-center mb-4">
          <iframe width="560" height="315" src={videoUrl} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      )}
      {imageUrl && (
        <div className="text-center">
          <Thumbnail imageUrl={imageUrl} title={title} />
        </div>
      )}
    </Card.Body>
  </Card>
);

const Thumbnail = ({ imageUrl, title }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <img
        src={imageUrl}
        alt={title}
        className="img-thumbnail"
        style={{ width: 'auto', cursor: 'pointer' }}
        onClick={() => setShow(true)}
      />
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={imageUrl} alt={title} className="img-fluid" />
          <FontAwesomeIcon icon={faArrowRight} className="scroll-icon" />
        </Modal.Body>
      </Modal>
    </>
  );
};

const CollapsibleStep = ({ stepNumber, stepText, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <Card className="mb-2">
      <Card.Header onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
        <strong>{stepNumber}.</strong> {stepText}
      </Card.Header>
      {open && <Card.Body className="text-left">{children}</Card.Body>}
    </Card>
  );
};

const TutorialPage = () => (
  <>
    <CustomNavbar /> {/* Usando o componente CustomNavbar */}
    <Container>
      <VideoTutorial videoUrl="https://www.youtube.com/embed/dQE7fVXDTTQ?si=5xNMJtxMQvLLbvfP" title="Video Tutorial no Computador" />
      <VideoTutorial videoUrl="https://www.youtube.com/embed/cM7fVi2Kmn8?si=_QCm6Sd09HhVAW78" title="Video Tutorial no Celular" />
      <Row>
        <Col>
          <Card className="mb-4 shadow mt-5">
            <Card.Body>
              <Card.Title className="page-title mb-4">Passo a Passo - Documentos Digitais CRLV-E, CRV-E e ATPV-E</Card.Title>
              <CollapsibleStep stepNumber="1" stepText="Para emitir um documento digital">
                <Card.Text className="text-left mb-4">
                  <ul>
                    <li><strong>Clique em "Selecionar um serviço"</strong> e escolha o documento que deseja emitir.</li>
                    <li><strong>Insira o número de placa</strong> no campo escrito "Placa".</li>
                    <li><strong>Insira seu nome de usuário</strong> no campo escrito "Nome de Usuário".</li>
                    <li><strong>Insira o seu Token</strong> no campo "Token".</li>
                  </ul>
                </Card.Text>
                <div className="text-center mb-4">
                  <Thumbnail imageUrl="/data/DocumentoDigitais.PNG" title="Selecionar um serviço" />
                </div>
              </CollapsibleStep>
              <CollapsibleStep stepNumber="2" stepText="Saldo da conta e Histórico recente do usuário.">
                <Card.Text className="text-left mb-4">
                  Depois que inserir os dados o sistema puxará o saldo da sua conta e o histórico recente do usuário. O saldo é quanto você tem pará realizar as consultas, antes de consulta verifique se o saldo é suficiente. O histórico das últimas 5 consultas realizadas será exibido no canto direito. Você pode conferir e auditar se os descontos estão sendo feito corretamente com as informações de saldo anterior, nome do documento consultado,  preço e saldo após consulta.
                </Card.Text>
                <div className="text-center mb-4">
                  <Thumbnail imageUrl="/data/DocumentoDigitais2.PNG" title="Download do PDF" />
                </div>
              </CollapsibleStep>
              <CollapsibleStep stepNumber="3" stepText="Documento em PDF">
                <Card.Text className="text-left mb-4">
                  Quando finalizar a busca, o documento será exibido na página com a opção de fazer o download do arquivo. Basta clicar em <strong>"Download em PDF"</strong>.
                  A tabela que exibe as últimas 5 consultas realizadas será atualizada. Nessa tabela, são mostrados:
                  <ul>
                    <li>Saldo anterior à consulta</li>
                    <li>Nome do documento consultado</li>
                    <li>Preço pago pela consulta</li>
                    <li>Saldo atual (quanto restou na sua conta após aquela consulta)</li>
                  </ul>
                </Card.Text>
                <div className="text-center mb-4">
                  <Thumbnail imageUrl="/data/DocumentoDigitais3.PNG" title="Histórico Recente" />
                </div>
              </CollapsibleStep>
            </Card.Body>
          </Card>
          <Card className="mb-4 shadow">
            <Card.Body>
              <Card.Title className="page-title mb-4">Passo a Passo - Consulta Bin Estadual, Nacional e PREMIUM</Card.Title>
              <CollapsibleStep stepNumber="1" stepText="Esse tutorial serve para realizar as 3 consultas veiculares do sistema">
                <Card.Text className="text-left mb-4">
                  <ul>
                    <li><strong>Insira o número de placa</strong> no campo escrito "Placa".</li>
                    <li><strong>Insira seu nome de usuário</strong> no campo escrito "Nome de Usuário".</li>
                    <li><strong>Insira o seu Token</strong> no campo "Token".</li>
                  </ul>
                  <div className="text-center mb-4">
                    <Thumbnail imageUrl="/data/ConsultaBinEstadual.PNG" title="Consulta Bin Estadual" />
                  </div>
                </Card.Text>
              </CollapsibleStep>
              <CollapsibleStep stepNumber="2" stepText="Saldo conta de usuário.">
                <Card.Text className="text-left mb-4">
                  O saldo da sua conta de usuário será carregado na página. Se tiver saldo suficiente para realizar a consulta, basta clicar em <strong>"Consultar"</strong>.
                </Card.Text>
                <div className="text-center mb-4">
                  <Thumbnail imageUrl="/data/ConsultaBinEstadual1.PNG" title="Dados do Veículo" />
                </div>
              </CollapsibleStep>
              <CollapsibleStep stepNumber="3" stepText="Informações do veículo.">
                <Card.Text className="text-left mb-4">
                  Ao clicar em consultar, será feita realizada a busca no sistema do Detran dos dados do veículo.
                  Quando finalizar a busca, os dados do veículo serão exibidos abaixo do botão <strong>"Consultar"</strong>.
                </Card.Text>
                <div className="text-center mb-4">
                  <Thumbnail imageUrl="/data/ConsultaBinEstadual2.PNG" title="Download PDF" />
                </div>
              </CollapsibleStep>
              <CollapsibleStep stepNumber="4" stepText="Baixar consulta em PDF">
                <Card.Text className="text-left mb-4">
                  No final da tabela com os dados do veículo, tem o botão 'Download PDF'.
                  Basta clicar nele para gerar um arquivo PDF com os dados do veículo.
                </Card.Text>
                <div className="text-center mb-4">
                  <Thumbnail imageUrl="/data/ConsultaBinEstadual3.PNG" title="PDF Gerado" />
                </div>
              </CollapsibleStep>
            </Card.Body>
          </Card>
          <Card className="mb-4 shadow">
            <Card.Body>
              <Card.Title className="page-title mb-4">Passo a Passo - Recarga Pix</Card.Title>
              <CollapsibleStep stepNumber="1" stepText="Para adicionar crédito à sua conta de usuário">
                <Card.Text className="text-left mb-4">
                  <ul>
                    <li><strong>Insira o valor em reais</strong> no campo "Valor".</li>
                    <li><strong>Insira o e-mail</strong> no campo "Email".</li>
                    <li><strong>Insira um CPF/CNPJ válido</strong> no campo "CPF ou CNPJ".</li>
                    <li><strong>Insira o Token</strong> no campo "Token do Usuário".</li>
                  </ul>
                  <div className="text-center mb-4">
                    <Thumbnail imageUrl="/data/formPrincipal.PNG" title="Recarga Pix" />
                  </div>
                </Card.Text>
              </CollapsibleStep>
              <CollapsibleStep stepNumber="2" stepText="Informações válidas">
                <Card.Text className="text-left mb-4">
                  As informações devem ser válidas para que seja possível gerar o QR code e o link de cópia e cola. Depois de inserir os dados, basta clicar em <strong>"Gerar QRcode Pix"</strong>.
                </Card.Text>
                <div className="text-center mb-4">
                  <Thumbnail imageUrl="/data/formPrincipal2.PNG" title="Gerar QRcode Pix" />
                </div>
              </CollapsibleStep>
              <CollapsibleStep stepNumber="3" stepText="QRcode e Chave copia e cola">
                <Card.Text className="text-left mb-4">
                  Ao clicar em Gerar QRcode Pix será exibido em uma janela as informações para pagamento. Você tem a opção de escanear o QR code ou clicar no ícone ou no texto da chave de cópia e cola para copiar o link.
                </Card.Text>
                <div className="text-center mb-4">
                  <Thumbnail imageUrl="/data/formPrincipal3.PNG" title="Informações para pagamento" />
                </div>
              </CollapsibleStep>
              <CollapsibleStep stepNumber="4" stepText="Confirmação automática de pagamento.">
                <Card.Text className="text-left mb-4">
                  Automaticamente, o sistema irá reconhecer o pagamento realizado e abrirá uma janela com a informação de pagamento confirmado. Pronto, seus créditos foram inseridos. Agora, basta acessar a página do serviço que deseja e seguir o passo a passo.
                </Card.Text>
                <div className="text-center mb-4">
                  <Thumbnail imageUrl="/data/formPrincipal4.PNG" title="Pagamento confirmado" />
                </div>
              </CollapsibleStep>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </>
);

export default TutorialPage;
