// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext';
import Login from './Login';
import SISDOK from './SISDOK';
import InserirCreditos from './InserirCreditos';
import Gerenciamento from './Gerenciamento';
import CreditReport from './CreditReport';
import UserActivityReport from './UserActivityReport';
import MonthlyUserActivityReport from './MonthlyUserActivityReport';
import './styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Dashboard from './Dashboard';
import ConsultaBinNacional from './ConsultaBinNacional';
import CostManagement from './CostManagement';
import ServicesManager from './ServicesManager';
import ProtectedRoute from './components/ProtectedRoute';
import Pagamentos from './Pagamentos';
import DocumentosDigitais from './DocumentosDigitais';
import TutorialPage from './TutorialPage';
import ConsultaEstadual from './ConsultaEstadual';
import ConsultaGravame from './ConsultaGravame';
import GoogleAnalytics from './GoogleAnalytics';
import GoogleAds from './GoogleAds'; // Importar o componente Google Ads
import RegisterForm from './RegisterForm';
import LGPDPage from './LGPDPage';
import TermsOfUsePage from './TermsOfUsePage';
import ReactPixel from 'react-facebook-pixel';
import Precos from './Precos';
import Consulta from './Consulta';
import ConsultaSinistro from './ConsultaSinistro';
import ConsultaRenainf from './ConsultaRenainf';
import Atpve from './Atpve';
import CrvDigital from './CrvDigital';
import RefundPolicy from './reembolso';
import Landing from './Landing';




const App = () => {
    useEffect(() => {
        ReactPixel.init('1158371855386728');
        ReactPixel.pageView();
    }, []);

    return (
        <AuthProvider>
            <Router>
                <GoogleAnalytics />
                <GoogleAds /> {/* Incluir o componente Google Ads */}
                <Routes>
                    <Route path="/" element={<Landing/>} />
                    <Route path="Admin/login" element={<Login />} />
                    <Route element={<ProtectedRoute />}>
                        <Route path="/Admin/InserirCreditos" element={<InserirCreditos />} />
                        <Route path="/Admin/Gerenciamento" element={<Gerenciamento />} />
                        <Route path="/Admin/GerenciamentoCreditos" element={<CreditReport />} />
                        <Route path="/Admin/GerenciamentoUsuariosDia" element={<UserActivityReport />} />
                        <Route path="/Admin/GerenciamentoUsuariosMes" element={<MonthlyUserActivityReport />} />
                        
                        <Route path="/Admin/CostManagement" element={<CostManagement />} />
                        <Route path="/Admin/services" element={<ServicesManager />} />
                    </Route>
                    <Route path="/consultapremium" element={<Consulta />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/" element={<Navigate to="/crlvnahora" />} />
                    <Route path="/consultabinNacional" element={<ConsultaBinNacional />} />
                    <Route path="/pagamentos" element={<Pagamentos />} />
                    <Route path="/documentosdigitais" element={<DocumentosDigitais />} />
                    <Route path="/tutorial" element={<TutorialPage />} />
                    <Route path="/consultaestadual" element={<ConsultaEstadual />} />
                    <Route path="/consultagravame" element={<ConsultaGravame />} />
                    <Route path="/cadastro" element={<RegisterForm />} />
                    <Route path="/lgpd" element={<LGPDPage />} />
                    <Route path="termosUso" element={<TermsOfUsePage />} />
                    <Route path="/servicos" element={<Precos />} />
                    <Route path="/sinistro" element={<ConsultaSinistro />} />
                    <Route path="/renainf" element={<ConsultaRenainf />} />
                    <Route path="/atpve" element={<Atpve />} />
                    <Route path="/crvdigital" element={<CrvDigital />} />
                    <Route path="/reembolso" element={<RefundPolicy />} />
                    <Route path="/crlvnahora" element={<Landing />} />
                </Routes>
            </Router>
        </AuthProvider>
    );
};

export default App;
