import React, { useState, useRef, useEffect } from 'react';
import axiosInstance from './axiosConfig';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Card, Button, Modal } from 'react-bootstrap';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import styled, { createGlobalStyle } from 'styled-components';
import CustomNavbar from './Navbar';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    background: #f8f9fa;
  }
`;

const MainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 20px;

  @media (max-width: 767px) {
    div {
      height: 100%;
      width: 360px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: -10px;
      background: #ffffff0d;
      margin-left: -15px;   
      margin-top: -10px;
    }

    @media (max-width: 767px) {
      p {
        margin-bottom: -10px;
        font-size: 1.3em;
        padding-top: 25px;
        margin-left: -15px;
      }
    }
  }
`;

const ConsultaCard = styled(Card)`
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 20px auto;
  padding: 20px;
  @media (max-width: 767px) {
    margin-top: -30px;
  }
`;

const ConsultaTitle = styled.h2`
  text-align: left;
  margin-bottom: 2rem;
  font-family: 'FontAwesome';
  color: black;
  font-size: larger;
`;

const SectionTitle = styled.h3`
  display: inline-block;
  margin: 10px 0;
  color: #00417c;
  font-size: medium;

  @media (max-width: 767px) {
    display: inline-block;
    margin: 20px 0;
    margin-left: -14px;
    color: #00417c;
  }
`;

const InfoBlock = styled.div`
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
`;

const InfoTable = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin-bottom: 10px;
`;

const InfoCell = styled.div`
  padding: 5px;
`;

const ConsultaButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
  background-color: #007bff;
  border: none;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    opacity: 1;
    pointer-events: none;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-top: 10px;
`;

const ContainerConsulta = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 700px;
  font-size: smaller;
  @media (max-width: 767px) {
    margin-left: -30px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-top: 0px;
  margin-bottom: 8px;
  border: 1px solid #007bff;
  border-radius: 4px;
`;

const CreditsInfo = styled.p`
  color: #007bff;
  font-size: large;
  @media (max-width: 767px) {
    margin-top: -10px;
  }
`;

const CaptchaContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding: 0 10px;

  @media (max-width: 767px) {
    iframe {
      max-width: auto;
      transform: scale(1);
      transform-origin: 0 0;
      margin-top: 30px;
      margin-left: -25px;
    }
  }
`;

const ConsultaRenainf = () => {
  const [placa, setPlaca] = useState('');
  const [token, setToken] = useState('');
  const [username, setUsername] = useState('');
  const [captchaToken, setCaptchaToken] = useState(null);
  const [consultaData, setConsultaData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [credits, setCredits] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const hcaptchaRef = useRef(null);

      const trackPurchase = () => {
        // Disparar o evento personalizado para o GTM
        window.dataLayer.push({
            'event': 'successfulConsultation',
            'service': 'Renainf',
            'Value': '10,00'
        });
    };

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');
    if (storedToken) setToken(storedToken);
    if (storedUsername) setUsername(storedUsername);
  }, []);

  useEffect(() => {
    localStorage.setItem('token', token);
    localStorage.setItem('username', username);
  }, [token, username]);

  useEffect(() => {
    if (token) fetchCredits(token);
  }, [token]);

  const fetchCredits = async (token) => {
    try {
      const response = await axiosInstance.get('/api/user/credits', { params: { token } });
      setCredits(response.data.credits);
    } catch (error) {
      setError('Erro ao obter créditos do usuário.');
      setShowErrorModal(true);
    }
  };

  const handleConsulta = async () => {
    if (!placa || !token || !captchaToken || !username) {
      setError('Por favor, preencha todos os campos e complete o CAPTCHA.');
      setShowErrorModal(true);
      return;
    }

    if (credits < 10) {
      setError('Créditos insuficientes para realizar a consulta.');
      setShowErrorModal(true);
      return;
    }

    setError('');
    setLoading(true);
    setConsultaData(null);

    try {
      const response = await axiosInstance.post('/CRV/Renainf', null, {
        params: { placa, username, token },
      });

      const responseData = response.data;

      if (
        responseData.HEADER &&
        responseData.HEADER.INFORMACOES_RETORNO &&
        responseData.HEADER.INFORMACOES_RETORNO.STATUS_RETORNO &&
        responseData.HEADER.INFORMACOES_RETORNO.STATUS_RETORNO.CODIGO === '0' &&
        responseData.HEADER.INFORMACOES_RETORNO.STATUS_RETORNO.DESCRICAO === 'ERRO NO PROVEDOR REMOTO. OPERAÇÃO IMPEDIDA.'
      ) {
        throw new Error('Não foi possível realizar a consulta, se o erro persistir fale com suporte.');
      }

      setConsultaData(responseData);
      setCredits(credits - 10);

      // Disparar o evento de consulta bem-sucedida para o GTM
      trackPurchase();

    } catch (error) {
      if (error.response && error.response.status === 500) {
        setError('Não foi possível realizar a consulta, se o erro persistir fale com suporte.');
      } else {
        setError(error.message || 'Erro ao fazer a requisição. Verifique o console para mais detalhes.');
      }
      setShowErrorModal(true);
    } finally {
      setLoading(false);
      if (hcaptchaRef.current) {
        hcaptchaRef.current.resetCaptcha();
        setCaptchaToken(null);
      }
    }
  };

  const onCaptchaChange = (token) => setCaptchaToken(token);

  const handlePlacaChange = (e) => {
    const value = e.target.value.toUpperCase().replace(/\s/g, ''); // Remove espaços e converte para maiúsculas
    if (value.length <= 7) {
      setPlaca(value);
    }
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.text('Consulta Renainf', 14, 16);

    const addSection = (title, data) => {
      doc.autoTable({
        startY: doc.lastAutoTable ? doc.lastAutoTable.finalY + 10 : 20,
        head: [[title, '']],
        body: data.map(([label, value]) => [
          { content: label, styles: { fontStyle: 'bold' } },
          value,
        ]),
        theme: 'grid',
        styles: { fontSize: 10, cellPadding: 3, valign: 'middle', lineWidth: 0.1 },
        columnStyles: { 0: { cellWidth: 80 }, 1: { cellWidth: 102 } },
      });
    };

    const renderTableDataForPDF = (data) => {
      return Object.entries(data)
        .filter(([key]) => !['STATUS_RETORNO'].includes(key))
        .map(([key, value]) => {
          if (typeof value === 'object') {
            return [key.replace(/([A-Z])/g, ' $1').toUpperCase(), JSON.stringify(value)];
          }
          return [key.replace(/([A-Z])/g, ' $1').toUpperCase(), value];
        });
    };

    if (consultaData) {
      addSection('DADOS DA CONSULTA', [
        ['TIPO DE CONSULTA', '536-RENAINF - INFRACOES COMPLETA'],
        ['DADOS UTILIZADOS', `PLACA: ${placa}`],
      ]);

      consultaData.RENAINF.OCORRENCIAS.forEach((ocorrencia, index) => {
        addSection(`OCORRÊNCIA ${index + 1}`, [
          ['AUTO DE INFRAÇÃO', ocorrencia.AUTO_INFRACAO],
          ['DATA DE INFRAÇÃO', ocorrencia.DATA_INFRACAO],
          ['EXIGIBILIDADE', ocorrencia.EXIGIBILIDADE],
          ['INFRAÇÃO', ocorrencia.INFRACAO],
          ['ORGÃO AUTUADOR', ocorrencia.ORGAO_ATUADOR],
          ['PLACA', ocorrencia.DETALHES.DADOS_NOTIFICACAO.PLACA],
        ]);
      });

      doc.save(`${placa}_Consulta_Renainf.pdf`);
    }
  };

  return (
    <>
      <GlobalStyle />
      <CustomNavbar />
      <MainContainer>
        <ConsultaCard>
          <Card.Body>
            <h1>Multas Renainf - R$10</h1>
            <div style={{ marginTop: '20px', color: '#516377', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <Input
                  type="text"
                  id="placa"
                  placeholder="Digite a placa"
                  value={placa}
                  onChange={handlePlacaChange}
                  maxLength="7"
                />
                <Input
                  type="text"
                  id="username"
                  placeholder="Digite o username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <Input
                  type="text"
                  id="token"
                  placeholder="Digite o token"
                  value={token}
                  onChange={(e) => setToken(e.target.value)}
                />
                <div style={{ marginTop: '2px' }}>
                  <CreditsInfo>Créditos disponíveis: R${credits.toFixed(2)}</CreditsInfo>
                </div>
                <CaptchaContainer>
                  <HCaptcha
                    sitekey="71549bc6-209d-4506-9f97-ee716882c326"
                    onVerify={onCaptchaChange}
                    ref={hcaptchaRef}
                  />
                </CaptchaContainer>
                <ConsultaButton onClick={handleConsulta} disabled={loading || !captchaToken || placa.length !== 7}>
                  {loading ? 'Consultando...' : 'Consultar'}
                </ConsultaButton>
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </div>
            </div>
          </Card.Body>
        </ConsultaCard>
        {consultaData && (
          <ContainerConsulta>
            <Card className="mb-4">
              <Card.Header className="bg-primary text-white">DADOS DA CONSULTA</Card.Header>
              <Card.Body>
                <InfoBlock>
                  <SectionTitle>DADOS DA CONSULTA</SectionTitle>
                  <p><strong>TIPO DE CONSULTA:</strong> 536-RENAINF - INFRACOES COMPLETA</p>
                  <p><strong>DADOS UTILIZADOS:</strong> PLACA: {placa}</p>
                </InfoBlock>
              </Card.Body>
            </Card>
            {consultaData.RENAINF.OCORRENCIAS.map((ocorrencia, index) => (
              <Card className="mb-4" key={index}>
                <Card.Header className="bg-primary text-white">OCORRÊNCIA {index + 1}</Card.Header>
                <Card.Body>
                  <SectionTitle>RENAINF - INFRACAO/{ocorrencia.INFRACAO}</SectionTitle>
                  <InfoTable>
                    <InfoCell><strong>AUTO DE INFRAÇÃO:</strong> {ocorrencia.AUTO_INFRACAO}</InfoCell>
                    <InfoCell><strong>INFRAÇÃO:</strong> {ocorrencia.INFRACAO}</InfoCell>
                    <InfoCell><strong>DATA DE INFRAÇÃO:</strong> {ocorrencia.DATA_INFRACAO}</InfoCell>
                    <InfoCell><strong>ORGÃO AUTUADOR:</strong> {ocorrencia.ORGAO_ATUADOR}</InfoCell>
                    <InfoCell><strong>EXIGIBILIDADE:</strong> {ocorrencia.EXIGIBILIDADE}</InfoCell>
                    <InfoCell><strong>PLACA:</strong> {ocorrencia.DETALHES.DADOS_NOTIFICACAO.PLACA}</InfoCell>
                  </InfoTable>
                </Card.Body>
              </Card>
            ))}
            <Button variant="primary" onClick={handleDownloadPDF}>Download PDF</Button>
          </ContainerConsulta>
        )}
        <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Erro</Modal.Title>
          </Modal.Header>
          <Modal.Body>{error}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowErrorModal(false)}>Fechar</Button>
          </Modal.Footer>
        </Modal>
      </MainContainer>
    </>
  );
};

export default ConsultaRenainf;
