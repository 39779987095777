import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosConfig'; // Importando a configuração do axios
import { Card, Dropdown, Button, Form } from 'react-bootstrap';
import { FaMoneyBillWave, FaDollarSign, FaChartLine, FaSearch } from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

// Estilos globais
const GlobalStyle = createGlobalStyle`
  @media (max-width: 767px) {
    div#root {
      width: fit-content;
    }
  }

  .modal-content {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-left: 170px;

    @media (max-width: 767px) {
      margin-left: 0;
      margin-left: -25px;
      width: 420px;
    }
  }

  [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
    width: auto;
  }

  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin-left: -40px;

    @media (max-width: 767px) {
      margin-left: 0;
      padding-top: 40px;
    }
  }

  .modal-footer > * {
    margin: auto;
    width: 100px;

    @media (max-width: 767px) {
      margin-left: 0;
      width: 100%;
    }
  }
`;

const GerenciamentoContainer = styled.div`
  display: flex;
  height: 100vh;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;

const Sidebar = styled.div`
  width: 280px;
  background-color: #343a40;
  color: #343a40;
  padding: 20px;
  position: fixed;
  height: 100%;
  overflow-y: auto;

  .nav {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-left: 2px;
}

  @media (max-width: 768px) {
    width: 470px;
    height: auto;
    position: relative;
    margin-left: -25px;
  }
`;

const Content = styled.div`
  margin-left: 280px;
  padding: 80px;
  width: calc(100% - 280px);
  overflow-y: auto;

  @media (max-width: 768px) {
    margin-left: -30px;
    width: 458px;
    padding: 20px;
  }
`;

const UpdateButton = styled.button`
  background-color: #009879;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  font-family: sans-serif;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin: 20px 0;

  &:hover {
    background-color: #007f67;
    transform: scale(1.05);
  }

  &:active {
    background-color: #005f4a;
    transform: scale(1);
  }
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

  thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
  }

  th, td {
    padding: 12px 15px;
    width: auto;
  }

  tbody tr {
    border-bottom: 1px solid #dddddd;

    &:nth-of-type(even) {
      background-color: #f3f3f3;
    }

    &:last-of-type {
      border-bottom: 2px solid #009879;
    }

    &.active-row {
      font-weight: bold;
      color: #009879;
    }
  }

  @media (max-width: 768px) {
    font-size: 0.8em;
    th, td {
      padding: 8px;
    }
  }

  @media (max-width: 576px) {
    font-size: 0.7em;
    th, td {
      padding: 6px;
    }
  }
`;

const CreditReport = () => {
  const [credits, setCredits] = useState([]);
  const [filteredCredits, setFilteredCredits] = useState([]);
  const [filter, setFilter] = useState({ userId: '', username: '', startDate: '', endDate: '' });
  const navigate = useNavigate();

  useEffect(() => {
    fetchCreditLogs();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [filter, credits]);

  const fetchCreditLogs = async () => {
    try {
      const response = await axiosInstance.get('/api/Admin/GetCreditLogs');
      setCredits(response.data);
      setFilteredCredits(response.data);
    } catch (error) {
      console.error('Erro ao buscar logs de crédito:', error);
    }
  };

  const applyFilters = () => {
    let filtered = credits;

    if (filter.userId) {
      filtered = filtered.filter(log => log.userId.toString().includes(filter.userId));
    }

    if (filter.username) {
      filtered = filtered.filter(log => log.username.toLowerCase().includes(filter.username.toLowerCase()));
    }

    if (filter.startDate) {
      filtered = filtered.filter(log => new Date(log.date) >= new Date(filter.startDate));
    }

    if (filter.endDate) {
      filtered = filtered.filter(log => new Date(log.date) <= new Date(filter.endDate));
    }

    setFilteredCredits(filtered);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter(prevFilter => ({ ...prevFilter, [name]: value }));
  };

  const handleLogout = () => {
    navigate('/login');
  };

  const formatCurrency = (value) => {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  return (
    <>
      <GlobalStyle />
      <GerenciamentoContainer>
        <Sidebar>
          <a className="d-flex align-items-center mb-3 mb-md-auto me-md-auto text-white text-decoration-none">
            <span className="fs-4">SISCRV</span>
          </a>
          <hr />
          <ul className="nav nav-pills flex-column mb-auto">
            <li className="nav-item">
              <Link to="/Admin/GerenciamentoCreditos" className="nav-link active" aria-current="page">
              Relatório de Créditos
              </Link>
            </li>
            <li>
              <Link to="/Admin/services" className="nav-link text-white">
                Custos | Preços
              </Link>
            </li>
            <li>
              <Link to="/Admin/Gerenciamento" className="nav-link text-white">
              Gerenciamento
              </Link>
            </li>
            <li>
              <Link to="/Admin/InserirCreditos" className="nav-link text-white">
                Novo Usuário | Inserir Crédito
              </Link>
            </li>
            <li>
              <Link to="/Admin/GerenciamentoUsuariosDia" className="nav-link text-white">
                Relatório Diário
              </Link>
            </li>
            <li>
              <Link to="/Admin/GerenciamentoUsuariosMes" className="nav-link text-white">
                Relatório Mensal
              </Link>
            </li>
          </ul>
          <hr />
          <Dropdown>
            <Dropdown.Toggle variant="dark" id="dropdownUser1" className="d-flex align-items-center text-white text-decoration-none">
              <img src="https://avatars.githubusercontent.com/u/39032772?s=400&u=be2f63c766595d29cd10e2c0b40a6a1f6c421310&v=4" alt="" width="32" height="32" className="rounded-circle me-2" />
              <strong>Administrador</strong>
            </Dropdown.Toggle>

            <Dropdown.Menu variant="dark">
              <Dropdown.Divider />
              <Dropdown.Item onClick={handleLogout}>Sair</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <hr />
        </Sidebar>

        <Content>
          <div style={{ width: '100%', maxWidth: '1200px', display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
            <div className="table-responsive">
              <h1>RELATÓRIO DE CRÉDITOS</h1>
              <Form>
                <Form.Group controlId="userIdFilter">
                  <Form.Label>ID do Usuário</Form.Label>
                  <Form.Control
                    type="text"
                    name="userId"
                    value={filter.userId}
                    onChange={handleFilterChange}
                  />
                </Form.Group>
                <Form.Group controlId="usernameFilter">
                  <Form.Label>Nome do Usuário</Form.Label>
                  <Form.Control
                    type="text"
                    name="username"
                    value={filter.username}
                    onChange={handleFilterChange}
                  />
                </Form.Group>
                <Form.Group controlId="startDateFilter">
                  <Form.Label>Data Inicial</Form.Label>
                  <Form.Control
                    type="date"
                    name="startDate"
                    value={filter.startDate}
                    onChange={handleFilterChange}
                  />
                </Form.Group>
                <Form.Group controlId="endDateFilter">
                  <Form.Label>Data Final</Form.Label>
                  <Form.Control
                    type="date"
                    name="endDate"
                    value={filter.endDate}
                    onChange={handleFilterChange}
                  />
                </Form.Group>
                <Button variant="primary" onClick={applyFilters}>Aplicar Filtros</Button>
              </Form>
              <StyledTable className="table table-striped table-hover table-bordered">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>ID do Usuário</th>
                    <th>Usuário</th>
                    <th>Créditos Adicionado</th>
                    <th>Data</th>
                    <th>Créditos Anterior</th>
                    <th>Créditos Atual</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCredits.map((log) => (
                    <tr key={log.id}>
                      <td>{log.id}</td>
                      <td>{log.userId}</td>
                      <td>{log.username}</td>
                      <td>{formatCurrency(log.credits)}</td>
                      <td>{new Date(log.date).toLocaleString()}</td>
                      <td>{formatCurrency(log.creditsAnterior)}</td>
                      <td>{formatCurrency(log.creditsAtual)}</td>
                      
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            </div>
          </div>
        </Content>
      </GerenciamentoContainer>
    </>
  );
};

export default CreditReport;
