import React, { useState, useRef, useEffect, useCallback } from 'react';
import axiosInstance from './axiosConfig'; // Importando a configuração do axios
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Card, Table, Button, Navbar, Nav, Modal } from 'react-bootstrap';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import styled, { createGlobalStyle } from 'styled-components';
import CustomNavbar from './Navbar'; // Importando o CustomNavbar

// Estilos globais para a aplicação
const GlobalStyle = createGlobalStyle`

    div#root {
    background-color: #fff;
    width: auto;
 }
    
    div {
    background: #fff;
    width: auto;
}

  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    background: #fff;
  }
  button.btn.btn-secondary {
    padding: inherit;
  }
      .cCJLoN {
      width: auto;
  }
      

  @media (max-width: 768px) {
    .modal-header .btn-close {
      margin-right: 140px;
    }
      .modal-footer {
    justify-content: start;
}
    .text-success {
      --bs-text-opacity: 1;
      color: rgb(251 244 246) !important;
      background: #0090ff;
    }
      .row {
    margin-left: 20px;
}
    
  }
`;

// Estilo do container principal
const MainContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  margin-left: -15px;

   @media (max-width: 768px) {
    div {
      height: 100%;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: -10px;
      margin-left: 2px;

    }
  }
`;

// Cabeçalho que contém a barra de navegação
const Header = styled.header`
  width: 100%;
  height: auto;
  margin-left: 0px;
  background-color: #fff;
   
  .navbar {
    background-color: #fff;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 1.225rem;
    margin-left: 0px;
    margin-top: -20px;
    border-bottom: var(--bs-border-width) var(--bs-border-style) #dee2e603 !important;
  }

  .navbar-brand {
  }
  .bi {
    width: 40px;
    height: 32px;
  }

  .nav-pills {
    display: flex;
    justify-content: start;
    width: 100%;
  }
  .nav-link.active {
    background-color: #fff;
    color: #007bff;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    .header {
      width: 100%;
      height: auto;
      margin-left: 0px;
      background-color: #fff;
    }

    .navbar {
      flex-direction: row;
      padding-top: 0px;
      margin-left: -25px;
    }

    .navbar-brand {
      margin-right: auto;
      margin-bottom: 0px;
      margin-left: -35px;
    }
    .nav-pills {
      justify-content: flex-start;
      flex-grow: 1;
    }
    .nav-link {
      color: #667;
      border-bottom: 1px solid;
      border-bottom-color: rgba(0, 0, 0, .28);
      letter-spacing: 0;
      border-radius: inherit;
      width: 100%;
      margin-left: 20px;
    }
  }
`;

// Botão para alternar o menu de navegação em dispositivos móveis
const NavbarToggle = styled.button`
  background: none;
  border: none;
  color: #000;
  font-size: 1.5rem;
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 1100;

  @media (min-width: 769px) {
    display: none;
  }
`;

// Contêiner para os links do menu de navegação
const NavbarCollapse = styled.div`
  display: ${({ expanded }) => (expanded ? 'block' : 'none')};

  @media (min-width: 992px) {
    display: flex !important;
    flex-basis: auto;
  }
`;

// Estilo do card de consulta
const ConsultaCard = styled(Card)`
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 20px auto;
  padding: 20px;

  @media (max-width: 767px) {
    padding: 20px;
    width: 100%;
    margin-left: -15px;

    div {
      width: 100%;
      padding: 11px;
    }
  }
`;

// Estilo do título da consulta
const ConsultaTitle = styled.h2`
  text-align: left;
  margin-bottom: 2rem;
  font-family: 'FontAwesome';
  color: black;
  font-size: larger;
`;

// Estilo dos inputs da consulta
const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-top: 0px;
  margin-bottom: 8px;
  border: 1px solid #007bff;
  border-radius: 4px;
`;

// Informações sobre créditos disponíveis
const CreditsInfo = styled.p`
  color: #007bff;
  font-size: large;
  @media (max-width: 767px) {
    margin-top: -10px;
  }
`;

// Botão de consulta
const ConsultaButton = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  margin-top: 8px;
  width: 100%;
  &:disabled {
    background-color: #0d6efd; /* Cinza para estado desabilitado */
    color: #fff;
  }
  &:not(:disabled):hover {
    background-color: #0056b3; /* Azul mais escuro para estado hover */
  }
  @media (max-width: 767px) {
    margin-top: -10px;
  }
`;

// Mensagem de erro
const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-top: 10px;
`;

// Contêiner da consulta
const ContainerConsulta = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 700px;
  background-color: #fff;
`;

// Título da seção
const SectionTitle = styled.h3`
  display: inline-block;
  margin: 10px;
`;

// Estilo dos itens da lista
const ListGroupItem = styled.li`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ced4da;
`;

// Texto dos itens da lista
const ListGroupItemText = styled.div`
  margin-left: 1rem;
`;

// Estilo do contêiner do CAPTCHA para dispositivos móveis
const CaptchaContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding: 0 10px;
  background-color: #fff;

  @media (max-width: 767px) {
    iframe {
      max-width: auto;
      transform: scale(1);
      transform-origin: 0 0;
      margin-top: 5px;
      margin-left: -25px;
    }
  }
`;

const ConsultaBinNacional = () => {
  const [placa, setPlaca] = useState('');
  const [token, setToken] = useState('');
  const [username, setUsername] = useState('');
  const [captchaToken, setCaptchaToken] = useState(null);
  const [consultaData, setConsultaData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [credits, setCredits] = useState(0);
  const [navbarExpanded, setNavbarExpanded] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [debouncedUsername, setDebouncedUsername] = useState(username);
  const [debouncedToken, setDebouncedToken] = useState(token);

      const trackPurchase = () => {
        // Disparar o evento personalizado para o GTM
        window.dataLayer.push({
            'event': 'successfulConsultation',
            'service': 'BaseNacional',
            'Value': '10,00'
        });
    };

  

  const hcaptchaRef = useRef(null);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedToken(token);
    }, 3000);

    return () => {
      clearTimeout(handler);
    };
  }, [username, token]);

  useEffect(() => {
    if (debouncedToken) {
      fetchCredits(debouncedToken);
    }
  }, [debouncedToken]);

  // Carregar valores do localStorage ao inicializar o componente
  useEffect(() => {
    const storedToken = localStorage.getItem('userToken');
    const storedUsername = localStorage.getItem('username');
    if (storedToken) {
      setToken(storedToken);
    }
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  // Salvar valores no localStorage quando mudarem
  useEffect(() => {
    localStorage.setItem('userToken', token);
    localStorage.setItem('username', username);
  }, [token, username]);

  const fetchCredits = async (token) => {
    try {
      const response = await axiosInstance.get('/api/user/credits', {
        params: { token }
      });
      setCredits(response.data.credits);
    } catch (error) {
      console.error('Erro ao obter créditos:', error);
      setError('Erro ao obter créditos do usuário.');
      setShowErrorModal(true);
    }
  };

  const handlePlacaChange = (e) => {
    const value = e.target.value.replace(/\s/g, '').toUpperCase();
    if (value.length <= 7) {
      setPlaca(value);
    }
  };

  const handleConsulta = async () => {
    if (placa.length !== 7 || !token || !captchaToken || !username) {
      setError('Por favor, preencha todos os campos corretamente e complete o CAPTCHA.');
      setShowErrorModal(true);
      return;
    }

    setError('');
    setLoading(true);
    setConsultaData(null);

    try {
      const response = await axiosInstance.post('/CRV/BinNacional', null, {
        params: {
          placa,
          username,
          token
        }
      });
      const data = response.data.BIN_NACIONAL;
      if (data.LOGO_FABRICANTE && data.LOGO_FABRICANTE.IMAGEM_BASE64) {
        delete data.LOGO_FABRICANTE.IMAGEM_BASE64;
      }
      setConsultaData(data);
      const updatedCredits = credits - 10;
      setCredits(updatedCredits);

      // Disparar o evento de consulta bem-sucedida para o GTM
      trackPurchase();


    } catch (error) {
      console.error('Erro ao fazer a requisição:', error);
      if (error.response) {
        if (error.response.status === 401) {
          setError('Token inválido ou expirado.');
        } else if (error.response.status === 400) {
          setError('Créditos insuficientes! Insira novos créditos acessando a página Recarga Pix e realize sua consulta.');
        } else {
          setError('Veículo não encontrado! Verifique se digitou a placa corretamente.');
        }
      } else {
        setError('Erro ao fazer a requisição. Verifique o console para mais detalhes.');
      }
      setShowErrorModal(true);
    } finally {
      setLoading(false);
      if (hcaptchaRef.current) {
        hcaptchaRef.current.resetCaptcha();
        setCaptchaToken(null);
      }
    }
  };

  const onCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.text('BIN NACIONAL', 14, 16);

    const addSection = (title, data) => {
      doc.autoTable({
        startY: doc.lastAutoTable ? doc.lastAutoTable.finalY + 10 : 20,
        head: [[title, '']],
        body: data.map(([label, value]) => [
          { content: label, styles: { fontStyle: 'bold' } },
          value
        ]),
        theme: 'grid',
        styles: {
          fontSize: 10,
          cellPadding: 3,
          valign: 'middle',
          lineWidth: 0.1
        },
        columnStyles: {
          0: { cellWidth: 80 },
          1: { cellWidth: 102 }
        }
      });
    };

    const renderTableDataForPDF = (data) => {
      return Object.entries(data).map(([key, value]) => {
        return [key.replace(/_/g, ' '), value];
      });
    };

    addSection('DADOS DO VEÍCULO', renderTableDataForPDF({
      'PLACA': consultaData.PLACA,
      'RENAVAM': consultaData.RENAVAM,
      'CHASSI': consultaData.CHASSI,
      'UF': consultaData.UF,
      'MUNICÍPIO': consultaData.MUNICIPIO,
      'NÚMERO DO MOTOR': consultaData.NUMERO_MOTOR,
      'CAIXA CÂMBIO': consultaData.CAIXA_CAMBIO,
      'SITUAÇÃO': consultaData.SITUACAO
    }));

    addSection('INFORMAÇÕES COMPLEMENTARES', renderTableDataForPDF({
      'MARCA MODELO': consultaData.MARCA_MODELO,
      'COR VEÍCULO': consultaData.COR_VEICULO,
      'ANO MODELO': consultaData.ANO_MODELO,
      'ANO FABRICAÇÃO': consultaData.ANO_FABRICACAO,
      'COMBUSTÍVEL': consultaData.COMBUSTIVEL,
      'ESPÉCIE VEÍCULO': consultaData.ESPECIE_VEICULO,
      'CATEGORIA VEÍCULO': consultaData.CATEGORIA_VEICULO,
      'TIPO VEÍCULO': consultaData.TIPO_VEICULO,
      'POTÊNCIA VEÍCULO': consultaData.POTENCIA_VEICULO,
      'CILINDRADA': consultaData.CILINDRADA,
      'CAPACIDADE CARGA': consultaData.CAPACIDADE_CARGA,
      'CMT': consultaData.CMT,
      'PBT': consultaData.PBT,
      'TIPO CARROCERIA': consultaData.TIPO_CARROCERIA,
      'SITUAÇÃO CHASSI': consultaData.SITUACAO_CHASSI
    }));

    addSection('INFORMAÇÕES DO PROPRIETÁRIO', renderTableDataForPDF({
      'DOCUMENTO': consultaData.PROPRIETARIO.DOCUMENTO,
      'NOME': consultaData.PROPRIETARIO.NOME
    }));

    const restricoesBody = [];
    for (const [key, value] of Object.entries(consultaData.RESTRICOES)) {
      if (key !== 'MENSAGENS_RESTRICOES' && key !== 'EXISTE_RESTRICAO_GERAL') {
        restricoesBody.push([
          key.replace(/_/g, ' '),
          value === '0' ? 'NÃO' : 'SIM'
        ]);
      }
    }

    addSection('RESTRIÇÕES', restricoesBody.map(([title, value]) => [
      `${title}:`,
      { content: value, styles: { textColor: value === 'NÃO' ? 'green' : 'red', fontStyle: 'bold' } }
    ]));

    const infosExtras = consultaData.RESTRICOES.MENSAGENS_RESTRICOES.map(msg => [
      'RESTRIÇÃO',
      { content: msg.MENSAGEM.replace('OUTRAS RESTRICOES: ', ''), styles: { textColor: 'red' } }
    ]);

    addSection('INFORMAÇÕES EXTRAS', infosExtras);

    doc.save('consulta_bin_nacional.pdf');
  };

  const renderTableRows = (data) => {
    return Object.entries(data).map(([key, value]) => (
      <tr key={key}>
        <th className="text-uppercase">{key.replace(/_/g, ' ')}</th>
        <td className="text-uppercase">{value}</td>
      </tr>
    ));
  };

  const renderRestricoesRows = (data) => {
    const rows = [];
    for (const [key, value] of Object.entries(data)) {
      if (typeof value === 'object') {
        rows.push(
          <tr key={key}>
            <th colSpan="2" className="text-uppercase">{key.replace(/_/g, ' ')}</th>
          </tr>
        );
        for (const [subKey, subValue] of Object.entries(value)) {
          if (subKey === 'EXISTE_PENDENCIA' && subValue === '0') {
            rows.push(
              <tr key={subKey}>
                <td colSpan="2" className="text-uppercase text-success">NÃO</td>
              </tr>
            );
          } else if (subKey === 'EXISTE_PENDENCIA' && subValue === '1') {
            rows.push(
              <tr key={subKey}>
                <td colSpan="2" className="text-uppercase text-danger">SIM</td>
              </tr>
            );
          } else {
            rows.push(
              <tr key={subKey}>
                <td className="text-uppercase">{subKey.replace(/_/g, ' ')}</td>
                <td className="text-uppercase">{subValue}</td>
              </tr>
            );
          }
        }
      } else {
        if (key !== 'MENSAGENS_RESTRICOES' && key !== 'EXISTE_RESTRICAO_GERAL') {
          rows.push(
            <tr key={key}>
              <th className="text-uppercase">{key.replace(/_/g, ' ')}</th>
              <td className="text-uppercase">{value === '0' ? <span className="text-success">NÃO</span> : value === '1' ? <span className="text-danger">SIM</span> : value}</td>
            </tr>
          );
        }
      }
    }
    return rows;
  };

  return (
    <MainContainer>
      <GlobalStyle />
      <CustomNavbar /> {/* Usando o componente CustomNavbar */}
      <ConsultaCard>
        <Card.Body>
          <h1>Base Nacional - R$10</h1>
          <div style={{ marginTop: '20px', color: '#516377', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <Input
                type="text"
                id="placa"
                placeholder="Digite a placa"
                value={placa}
                onChange={handlePlacaChange}
              />
              <Input
                type="text"
                id="username"
                placeholder="Digite o username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <Input
                type="text"
                id="token"
                placeholder="Digite o token"
                value={token}
                onChange={(e) => setToken(e.target.value)}
              />
              <div style={{ marginTop: '2px' }}>
                <CreditsInfo>Créditos disponíveis: R${credits.toFixed(2)}</CreditsInfo>
              </div>
              <CaptchaContainer>
                <HCaptcha
                  sitekey="71549bc6-209d-4506-9f97-ee716882c326"
                  onVerify={onCaptchaChange}
                  ref={hcaptchaRef}
                />
              </CaptchaContainer>
              <ConsultaButton onClick={handleConsulta} disabled={loading || !captchaToken || placa.length !== 7}>
                {loading ? 'Consultando...' : 'Consultar'}
              </ConsultaButton>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </div>
          </div>
        </Card.Body>
      </ConsultaCard>
      {consultaData && (
        <ContainerConsulta>
          <Card className="mb-4">
            <Card.Header className="bg-primary text-white">Dados do Veículo</Card.Header>
            <Card.Body>
              <Table bordered>
                <tbody>
                  {renderTableRows({
                    'PLACA': consultaData.PLACA,
                    'RENAVAM': consultaData.RENAVAM,
                    'CHASSI': consultaData.CHASSI,
                    'UF': consultaData.UF,
                    'MUNICÍPIO': consultaData.MUNICIPIO,
                    'NÚMERO DO MOTOR': consultaData.NUMERO_MOTOR,
                    'CAIXA CÂMBIO': consultaData.CAIXA_CAMBIO,
                    'SITUAÇÃO': consultaData.SITUACAO
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Card className="mb-4">
            <Card.Header className="bg-primary text-white">Informações Complementares</Card.Header>
            <Card.Body>
              <Table bordered>
                <tbody>
                  {renderTableRows({
                    'MARCA MODELO': consultaData.MARCA_MODELO,
                    'COR VEÍCULO': consultaData.COR_VEICULO,
                    'ANO MODELO': consultaData.ANO_MODELO,
                    'ANO FABRICAÇÃO': consultaData.ANO_FABRICACAO,
                    'COMBUSTÍVEL': consultaData.COMBUSTIVEL,
                    'ESPÉCIE VEÍCULO': consultaData.ESPECIE_VEICULO,
                    'CATEGORIA VEÍCULO': consultaData.CATEGORIA_VEICULO,
                    'TIPO VEÍCULO': consultaData.TIPO_VEICULO,
                    'POTÊNCIA VEÍCULO': consultaData.POTENCIA_VEICULO,
                    'CILINDRADA': consultaData.CILINDRADA,
                    'CAPACIDADE CARGA': consultaData.CAPACIDADE_CARGA,
                    'CMT': consultaData.CMT,
                    'PBT': consultaData.PBT,
                    'TIPO CARROCERIA': consultaData.TIPO_CARROCERIA,
                    'SITUAÇÃO CHASSI': consultaData.SITUACAO_CHASSI
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Card className="mb-4">
            <Card.Header className="bg-primary text-white">Informações do Proprietário</Card.Header>
            <Card.Body>
              <Table bordered>
                <tbody>
                  {renderTableRows({
                    'DOCUMENTO': consultaData.PROPRIETARIO.DOCUMENTO,
                    'NOME': consultaData.PROPRIETARIO.NOME
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Card className="mb-4">
            <Card.Header className="bg-primary text-white">Restrições</Card.Header>
            <Card.Body>
              <Table bordered>
                <tbody>
                  {renderRestricoesRows({
                    'RESTRIÇÃO ROUBO FURTO': consultaData.RESTRICOES.EXISTE_RESTRICAO_ROUBO_FURTO,
                    'RESTRIÇÃO RENAJUD': consultaData.RESTRICOES.EXISTE_RESTRICAO_RENAJUD,
                    'VEÍCULO BAIXADO': consultaData.RESTRICOES.VEICULO_BAIXADO,
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Card className="mb-4">
            <Card.Header className="bg-primary text-white">Informações Extras</Card.Header>
            <Card.Body>
              <Table bordered>
                <tbody>
                  {consultaData.RESTRICOES.MENSAGENS_RESTRICOES.map((msg, idx) => (
                    <tr key={idx}>
                      <td colSpan="2" className="text-uppercase text-danger">RESTRIÇÃO: {msg.MENSAGEM.replace('OUTRAS RESTRICOES: ', '')}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Button variant="primary" onClick={handleDownloadPDF}>Download PDF</Button>
        </ContainerConsulta>
      )}
      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Erro</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowErrorModal(false)}>Fechar</Button>
        </Modal.Footer>
      </Modal>
    </MainContainer>
  );
};

export default ConsultaBinNacional;
