import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Card, Alert } from 'react-bootstrap';
import axiosInstance from './axiosConfig'; // Importando a configuração do axios

const CostManagement = () => {
    const [services, setServices] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        fetchServices();
    }, []);

    const fetchServices = async () => {
        try {
            const response = await axiosInstance.get('/api/Admin/Services');
            console.log('API Response:', response.data); // Log da resposta da API
            if (Array.isArray(response.data)) {
                setServices(response.data);
            } else {
                throw new Error('A resposta da API não é um array.');
            }
        } catch (error) {
            console.error('Erro ao buscar serviços:', error);
            setErrorMessage('Erro ao buscar serviços.');
        }
    };

    const handleServiceChange = (index, field, value) => {
        const newServices = [...services];
        newServices[index][field] = value;
        setServices(newServices);
    };

    const handleUpdateServices = async () => {
        try {
            const updates = services.map(service => ({
                id: service.id,
                cost: service.cost,
                price: service.price
            }));

            await axiosInstance.put('/api/admin/UpdateServiceCosts', updates);
            setSuccessMessage('Serviços atualizados com sucesso.');
            fetchServices();
        } catch (error) {
            console.error('Erro ao atualizar serviços:', error);
            setErrorMessage('Erro ao atualizar serviços.');
        }
    };

    return (
        <Container>
            <h2 className="my-4">Gerenciamento de Custos e Preços</h2>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            <Form>
                {services.map((service, index) => (
                    <Card className="mb-3" key={service.id}>
                        <Card.Body>
                            <Form.Group className="mb-3">
                                <Form.Label>Serviço: {service.name}</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Custo"
                                    value={service.cost}
                                    onChange={(e) => handleServiceChange(index, 'cost', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Preço</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Preço"
                                    value={service.price}
                                    onChange={(e) => handleServiceChange(index, 'price', e.target.value)}
                                />
                            </Form.Group>
                        </Card.Body>
                    </Card>
                ))}
                <Button variant="primary" onClick={handleUpdateServices}>Atualizar Serviços</Button>
            </Form>
        </Container>
    );
};

export default CostManagement;
