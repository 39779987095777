import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const services = [
  { name: 'Consulta Veicular PREMIUM', path: '/Dashboard', description: 'Informações do veículo.', image: '/9.png' },
  { name: 'Documentos Digitais', path: '/DocumentosDigitais', description: '(CRLV) - (ATPV-E) - (CRV DIGITAL).', image: '/14.png' },
  { name: 'Consulta Veicular', path: '/ConsultaEstadual', description: 'R$10 - Base Estadual.', image: '/16.png' },
  
  { name: 'Consulta Veicular', path: '/ConsultaBinNacional', description: 'R$10 - Base Nacional.', image: '/10.png' },
  { name: 'Consulta Gravame (SNG)', path: '/ConsultaGravame', description: 'R$10 - Sistema Nacional de Gravames', image: '/19.png' },
  { name: 'Consulta Veicular', path: '/Dashboard', description: 'LEILÃO COMPLETO + SCORE', image: '/18.png' },
  { name: 'Consulta Veicular', path: '/Dashboard', description: 'LEILÃO SIMPLES', image: '/17.png' },
  // Adicione mais serviços conforme necessário
];

const Container = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 767px) {
    margin-top: 2rem;
    
  }
           div#root {
        width: auto;
        height: auto;
        
    }
        .sc-hRJeED.jQeKea {
    margin-left: -20px;
}
}
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 767px) {
    
  }
`;

const Card = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  

  /* Adiciona espaçamento superior ao primeiro card no layout mobile */
  &:first-child {
    @media (max-width: 767px) {
      
      

    }
  }
`;

const CardImage = styled.img`
  width: 370px;
  height: 200px;
  object-fit: cover;

 

  @media (max-width: 767px) {
    height: auto;
  }
`;

const CardBody = styled.div`
  padding: 1rem;
`;

const CardTitle = styled.h5`
  color: #007bff;
  margin: 0 0 0.5rem 0;
`;

const CardText = styled.p`
  color: #333;
  margin: 0 0 1rem 0;
`;

const Button = styled.button`
  background-color: #007bff;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  cursor: pointer;
  align-self: flex-start;

  &:hover {
    background-color: #0056b3;
  }
`;

const Ribbon = styled.div`
  
        --f: 10px;
    --r: 15px;
    --t: 10px;
    position: absolute;
    inset: var(--t) calc(-1* var(--f)) auto auto;
    padding: 0 10px var(--f) calc(10px + var(--r));
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - var(--f)), calc(100% - var(--f)) 100%, calc(100% - var(--f)) calc(100% - var(--f)), 0 calc(100% - var(--f)), var(--r) calc(50% - var(--f) / 2));
    background: #ff9800;
    box-shadow: 0 calc(-1* var(--f)) 0 inset #0005;
    margin-right:8px;
    font-size: larger;
    font-weight: bolder;
    color: white;


 @media (max-width: 767px) {
      
           --f: 10px;
        --r: 15px;
        --t: 10px;
        position: sticky;
        inset: var(--t) calc(-1* var(--f)) auto auto;
        padding: 0 10px var(--f) calc(10px + var(--r));
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - var(--f)), calc(100% - var(--f)) 100%, calc(100% - var(--f)) calc(100% - var(--f)), 0 calc(100% - var(--f)), var(--r) calc(50% - var(--f) / 2));
        background: #ff9800;
        box-shadow: 0 calc(-1* var(--f)) 0 inset #0005;
        /* margin-right: -100px; */
        margin-top: -40px;
        font-size: larger;
        font-weight: bolder;
        width: 180px;
        margin-left: 188px;
        color: white;
    }
        
   @media (max-min: 965) and (max-width: 1200){   
    .krDMJH {
    --f: 10px;
    --r: 15px;
    --t: 10px;
    position: absolute;
    inset: var(--t) calc(-1* var(--f)) auto auto;
    padding: 0 10px var(--f) calc(10px + var(--r));
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - var(--f)), calc(100% - var(--f)) 100%, calc(100% - var(--f)) calc(100% - var(--f)), 0 calc(100% - var(--f)), var(--r) calc(50% - var(--f) / 2));
    background: #ff9800;
    box-shadow: 0 calc(-1* var(--f)) 0 inset #0005;
    margin-right: 90px;
    font-size: larger;
    font-weight: bolder;
    color: white;
      }


    }


`;
const GreenRibbon = styled(Ribbon)`
  background: #4caf50; /* Green color */
  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
`;

const Dashboard = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Grid>
        {services.map((service, index) => (
          <Card key={index}>
            <div style={{ position: 'relative' }}>
              {['/18.png', '/17.png','/9.png'].includes(service.image) && (
                <Ribbon>MANUTENÇÃO</Ribbon>
              )}
              {['/10.png', '/14.png', '/16.png', '/19.png'].includes(service.image) && (
                <GreenRibbon>NOVO</GreenRibbon>
              )}
              <CardImage src={service.image} alt={service.name} />
            </div>
            <CardBody>
              <CardTitle>{service.name}</CardTitle>
              <CardText>{service.description}</CardText>
              <Button onClick={() => navigate(service.path)}>Acessar</Button>
            </CardBody>
          </Card>
        ))}
      </Grid>
    </Container>
  );
};

export default Dashboard;
