import styled, { createGlobalStyle } from 'styled-components';
import React, { useState, useRef, useEffect } from 'react';
import axiosInstance from './axiosConfig'; // Importando a configuração do axios
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Modal, Button, Card, Navbar, Nav } from 'react-bootstrap';
import { jsPDF } from 'jspdf';
import CustomNavbar from './Navbar'; // Importando o CustomNavbar

// Estilos globais para a aplicação
const GlobalStyle = createGlobalStyle`
  div#root {
    background-color: #fff;
    width: auto;
  }
  div {
    background: #fff;
    width: auto;
  }
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    background-color: #fff;
  }
  button.btn.btn-secondary {
   
  }
  .cCJLoN {
    width: auto;
  }

  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1* var(--bs-gutter-y));
    margin-right: calc(-.5* var(--bs-gutter-x));
    margin-left: calc(-.5* var (--bs-gutter-x));
    width: 800px;
}
        p {
        font-size: 0.8em;
        margin-top: -30px;
    }
  @media (max-width: 768px) {
    .modal-header .btn-close {
      margin-right: 140px;
    }
    .modal-footer {
      justify-content: start;
    }
    .text-success {
      --bs-text-opacity: 1;
      color: rgb(251 244 246) !important;
      background: #0090ff;
    }
    .row {
      margin-left: 20px;
    }
      .modal-body {
        /* position: relative; */
        /* flex: 1 1 auto; */
        /* padding: var(--bs-modal-padding); */
        width: auto;
        /* overflow-x: auto; */
    }
            p {
        font-size: 0.8em;
        margin-top: -30px;
    }
}
  }
`;

// Container principal que ajusta o layout central da página
const MainContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  @media (max-width: 768px) {
    .card-body {
      padding: 2rem;
      width: auto;
      background: transparent;
    }
    div {
      height: 100%;
      width: 380px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: -10px;
      background-color: #fff;
    }
  }
`;

// Estilo do card de consulta, pode ajustar largura máxima, sombra e bordas
const ConsultaCard = styled(Card)`
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  background-color: #fff;
  margin: 20px auto;
  padding: 20px;
  .card-body {
    padding: 2rem;
    width: auto;
  }
  @media (max-width: 767px) {
    padding: 20px;
    width: 100%;
    margin-left: -15px;
    div {
      width: 100%;
      padding: 11px;
      margin-top: -20px;
    }
  }
`;

// Estilo do título da consulta
const ConsultaTitle = styled.h2`
  text-align: left;
  margin-bottom: 2rem;
  font-family: 'FontAwesome';
  color: black;
  font-size: larger;
`;

// Descrição da consulta
const ConsultaDescription = styled.p`
  text-align: left;
  color: #516377;
`;

// Estilo dos inputs da consulta
const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-top: 0px;
  margin-bottom: 8px;
  border: 1px solid #007bff;
  border-radius: 4px;
`;

// Informações sobre créditos disponíveis
const CreditsInfo = styled.p`
  color: #007bff;
  font-size: large;
`;

// Botão de consulta
const ConsultaButton = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  margin-top: 8px;
  width: 100%;
  &:disabled {
    background-color: #007bff;
  }
`;

// Botão de download de PDF
const DownloadButton = styled.button`
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  margin-top: 8px;
  width: 100%;
  &:disabled {
    background-color: #28a745;
  }
`;

// Contêiner da consulta
const ContainerConsulta = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (max-width: 767px) {
    .card-body {
      padding: 2rem;
      width: auto;
      background: #fff;
    }
  }
`;

// Título da seção
const SectionTitle = styled.h3`
  display: inline-block;
  margin: 10px;
`;

// Estilo dos itens da lista
const ListGroupItem = styled.li`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ced4da;
`;

// Texto dos itens da lista
const ListGroupItemText = styled.div`
  margin-left: 1rem;
`;

// Componente principal da consulta
const Consulta = () => {
  const [placa, setPlaca] = useState('');
  const [token, setToken] = useState('');
  const [username, setUsername] = useState('');
  const [captchaToken, setCaptchaToken] = useState(null);
  const [consultaData, setConsultaData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [credits, setCredits] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [selectedField, setSelectedField] = useState('');
  const [confirmationStep, setConfirmationStep] = useState(0);
  const [navbarExpanded, setNavbarExpanded] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [debouncedToken, setDebouncedToken] = useState(token);
  const hcaptchaRef = useRef(null);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedToken(token);
    }, 3000);

    return () => {
      clearTimeout(handler);
    };
  }, [token]);

  useEffect(() => {
    if (debouncedToken) {
      fetchCredits(debouncedToken);
    }
  }, [debouncedToken]);

  // Carregar valores do localStorage ao inicializar o componente
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');
    if (storedToken) {
      setToken(storedToken);
    }
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  // Salvar valores no localStorage quando mudarem
  useEffect(() => {
    localStorage.setItem('userToken', token);
    localStorage.setItem('username', username);
  }, [token, username]);

  const fetchCredits = async (token) => {
    try {
      const response = await axiosInstance.get('/api/user/credits', {
        params: { token },
      });
      setCredits(response.data.credits);
    } catch (error) {
      console.error('Erro ao obter créditos:', error);
      setError('Erro ao obter créditos do usuário.');
      setShowErrorModal(true);
    }
  };

  const handlePlacaChange = (e) => {
    const value = e.target.value.replace(/\s/g, '').toUpperCase();
    if (value.length <= 7) {
      setPlaca(value);
    }
  };

  const handleConsulta = async () => {
    if (placa.length !== 7 || !token || !captchaToken || !username) {
      setError('Por favor, preencha todos os campos corretamente e complete o CAPTCHA.');
      setShowErrorModal(true);
      return;
    }

    if (credits < 10) {
      setError('Créditos insuficientes para realizar a consulta.');
      setShowErrorModal(true);
      return;
    }

    setError('');
    setLoading(true);
    setConsultaData(null);

    try {
      const response = await axiosInstance.get('/CRV/ConsultaVP', {
        params: {
          placa,
          username,
          token
        }
      });
      setConsultaData(response.data);
    } catch (error) {
      console.error('Erro ao fazer a requisição:', error);
      if (error.response) {
        if (error.response.status === 401) {
          setError('Token inválido ou expirado.');
        } else if (error.response.status === 400) {
          setError('Créditos insuficientes! Insira novos créditos acessando a página Recarga Pix e realize sua consulta.');
        } else {
          setError('Veículo não encontrado! Verifique se digitou a placa corretamente.');
        }
      } else {
        setError('Erro ao fazer a consulta. Se persistir fale com suporte.');
      }
      setShowErrorModal(true);
    } finally {
      setLoading(false);
      if (hcaptchaRef.current) {
        hcaptchaRef.current.resetCaptcha();
        setCaptchaToken(null);
      }
    }
  };

  const handleNumberClick = (field) => {
    setSelectedField(field);
    setModalMessage('Para ter acesso a essa informação completa, será descontado R$30 dos seus créditos. Deseja continuar?');
    setShowModal(true);
    setConfirmationStep(1);
  };

  const handleModalConfirm = async () => {
    if (confirmationStep === 1) {
      setModalMessage('Você tem certeza que deseja descontar R$30 dos seus créditos para ver o número completo?');
      setConfirmationStep(2);
    } else if (confirmationStep === 2) {
      if (credits < 30) {
        setError('Créditos insuficientes para visualizar o número completo.');
        setShowModal(false);
        setShowErrorModal(true);
        return;
      }

      try {
        const response = await axiosInstance.post('/CRV/logRequest', {
          userName: username,
          token,
          fileName: 'NrCRV_' + placa,
          route: 'NrCRV',
          previousCredits: credits,
          currentCredits: credits - 30
        });

        setCredits(credits - 30);
        const fullNumber = response.data.fullNumber;

        setConsultaData((prevData) => {
          if (prevData && selectedField && fullNumber) {
            return {
              ...prevData,
              [selectedField]: fullNumber
            };
          }
          return prevData;
        });
      } catch (error) {
        console.error('Erro ao registrar a atividade:', error);
        setError('Erro ao registrar a atividade. Verifique o console para mais detalhes.');
        setShowErrorModal(true);
      } finally {
        setShowModal(false);
        setConfirmationStep(0);
      }
    }
  };

  const onCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.text('Informações do Veículo', 10, 10);
    doc.text(`Placa: ${consultaData.PLACA}`, 10, 20);
    doc.text(`Renavam: ${consultaData.RENAVAM}`, 10, 30);
    doc.text(`Chassi: ${consultaData.CHASSI}`, 10, 40);
    doc.text(`Número CRV: ${consultaData.CRLV}`, 10, 50);
    doc.text(`Ano Fabricação/Modelo: ${consultaData.ANO_FABRICACAO} / ${consultaData.ANO_MODELO}`, 10, 60);
    doc.text(`Marca Modelo: ${consultaData.MARCA_MODELO}`, 10, 70);
    doc.text(`Número do motor: ${consultaData.MOTOR}`, 10, 80);
    doc.text(`Município Emplacamento: ${consultaData.MUNICIPIO}`, 10, 90);
    doc.text(`Combustível: ${consultaData.COMBUSTIVEL}`, 10, 100);
    doc.text(`Cor: ${consultaData.COR_VEICULO}`, 10, 110);
    doc.text(`Proprietário Nome: ${consultaData.PROPRIETARIO_NOME}`, 10, 120);
    doc.text(`Proprietário Documento: ${consultaData.PROPRIETARIO_DOCUMENTO}`, 10, 130);
    doc.save(`ConsultaVeicular_${consultaData.PLACA}.pdf`);
  };

  return (
    <MainContainer>
      <GlobalStyle />
      <CustomNavbar /> {/* Usando o componente CustomNavbar */}
      <ConsultaCard className="shadow p-4">
        <Card.Body>
          <h1>Consulta Premium - R$10</h1>
          <p>Consulta puxa Renavam, Chassi, Motor, Proprietário. O Numero do CRV (Digital) é cobrado a parte. Se sair 00000*** é porque não tem! </p>
          <div>
            <Input
              type="text"
              id="placa"
              placeholder="Digite a placa"
              value={placa}
              onChange={handlePlacaChange}
            />
            <Input
              type="text"
              id="username"
              placeholder="Digite o username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Input
              type="text"
              id="token"
              placeholder="Digite o token"
              value={token}
              onChange={(e) => setToken(e.target.value)}
            />
            <CreditsInfo>Créditos disponíveis: R${credits.toFixed(2)}</CreditsInfo>
            <HCaptcha
              sitekey="71549bc6-209d-4506-9f97-ee716882c326"
              onVerify={onCaptchaChange}
              ref={hcaptchaRef}
            />
            <ConsultaButton onClick={handleConsulta} disabled={loading || !captchaToken || placa.length !== 7}>
              {loading ? 'Consultando...' : 'Consultar'}
            </ConsultaButton>
          </div>
        </Card.Body>
      </ConsultaCard>
      {consultaData && (
        <ContainerConsulta>
          <div className="row">
            <div className="col-12 text-center my-4">
              <i className="fas fa-car fa-2x"></i>
              <SectionTitle>Informações do Veículo</SectionTitle>
            </div>
            <div className="col-md-6">
              <ul className="list-group">
                <ListGroupItem className="list-group-item list-group-item-action">
                  <img src="img/license-plate.png" alt="Placa" style={{ height: '40px', width: '40px' }} />
                  <ListGroupItemText>
                    <h6 className="mb-0">{consultaData.PLACA}</h6>
                    <small>Placa</small>
                  </ListGroupItemText>
                </ListGroupItem>
                <ListGroupItem className="list-group-item list-group-item-action">
                  <img src="img/assinando.png" alt="Renavam" style={{ height: '40px', width: '40px' }} />
                  <ListGroupItemText>
                    <h6 className="mb-0">{consultaData.RENAVAM}</h6>
                    <small>Renavam</small>
                  </ListGroupItemText>
                </ListGroupItem>
                <ListGroupItem className="list-group-item list-group-item-action">
                  <img src="img/001-chassis.png" alt="Chassi" style={{ height: '40px', width: '40px' }} />
                  <ListGroupItemText>
                    <h6 className="mb-0">{consultaData.CHASSI}</h6>
                    <small>Chassi</small>
                  </ListGroupItemText>
                </ListGroupItem>
                <ListGroupItem className="list-group-item list-group-item-action">
                  <img src="img/lista-de-afazeres.png" alt="Número CRV" style={{ height: '40px', width: '40px' }} />
                  <ListGroupItemText>
                    <h6 className="mb-0 text-success" onClick={() => handleNumberClick('CRLV')} style={{ cursor: 'pointer' }}>
                      {consultaData.CRLV ? consultaData.CRLV : '****'}
                    </h6>
                    <small>Clique em **** Número CRV</small>
                  </ListGroupItemText>
                </ListGroupItem>
                <ListGroupItem className="list-group-item list-group-item-action">
                  <img src="img/calendario.png" alt="Ano Fabricação/Modelo" style={{ height: '40px', width: '40px' }} />
                  <ListGroupItemText>
                    <h6 className="mb-0">{consultaData.ANO_FABRICACAO} / {consultaData.ANO_MODELO}</h6>
                    <small>Ano Fabricação/Modelo</small>
                  </ListGroupItemText>
                </ListGroupItem>
              </ul>
            </div>
            <div className="col-md-6">
              <ul className="list-group">
                <ListGroupItem className="list-group-item list-group-item-action">
                  <img src="img/014-electric-car.png" alt="Marca Modelo" style={{ height: '40px', width: '40px' }} />
                  <ListGroupItemText>
                    <h6 className="mb-0">{consultaData.MARCA_MODELO}</h6>
                    <small>Marca Modelo</small>
                  </ListGroupItemText>
                </ListGroupItem>
                <ListGroupItem className="list-group-item list-group-item-action">
                  <img src="img/005-steering-wheel.png" alt="Tipo" style={{ height: '40px', width: '40px' }} />
                  <ListGroupItemText>
                    <h6 className="mb-0">{consultaData.TIPO}</h6>
                    <small>Tipo</small>
                  </ListGroupItemText>
                </ListGroupItem>
                <ListGroupItem className="list-group-item list-group-item-action">
                  <img src="img/003-car-engine.png" alt="Número do motor" style={{ height: '40px', width: '40px' }} />
                  <ListGroupItemText>
                    <h6 className="mb-0">{consultaData.MOTOR}</h6>
                    <small>Número do motor</small>
                  </ListGroupItemText>
                </ListGroupItem>
                <ListGroupItem className="list-group-item list-group-item-action">
                  <img src="img/006-schedule.png" alt="Ano último licenciamento" style={{ height: '40px', width: '40px' }} />
                  <ListGroupItemText>
                    <h6 className="mb-0">{consultaData.ANO_ULTIMO_LICENCIAMENTO}</h6>
                    <small>Ano último licenciamento</small>
                  </ListGroupItemText>
                </ListGroupItem>
                <ListGroupItem className="list-group-item list-group-item-action">
                  <img src="img/002-car.png" alt="Município Emplacamento" style={{ height: '40px', width: '40px' }} />
                  <ListGroupItemText>
                    <h6 className="mb-0">{consultaData.MUNICIPIO}</h6>
                    <small>Município Emplacamento</small>
                  </ListGroupItemText>
                </ListGroupItem>
              </ul>
            </div>
          </div>

          <div className="row">
            <div className="col-12 text-center my-4">
              <i className="fa fa-list-ul fa-2x"></i>
              <SectionTitle>Características</SectionTitle>
            </div>
            <div className="col-md-6">
              <ul className="list-group">
                <ListGroupItem className="list-group-item list-group-item-action">
                  <img src="img/004-gas-station.png" alt="Combustível" style={{ height: '40px', width: '40px' }} />
                  <ListGroupItemText>
                    <h6 className="mb-0">{consultaData.COMBUSTIVEL}</h6>
                    <small>Combustível</small>
                  </ListGroupItemText>
                </ListGroupItem>
                <ListGroupItem className="list-group-item list-group-item-action">
                  <img src="img/servico.png" alt="Categoria" style={{ height: '40px', width: '40px' }} />
                  <ListGroupItemText>
                    <h6 className="mb-0">{consultaData.CATEGORIA}</h6>
                    <small>Categoria</small>
                  </ListGroupItemText>
                </ListGroupItem>
                <ListGroupItem className="list-group-item list-group-item-action">
                  <img src="img/aluguel-de-carros.png" alt="Lotação" style={{ height: '40px', width: '40px' }} />
                  <ListGroupItemText>
                    <h6 className="mb-0">{consultaData.LOTACAO}</h6>
                    <small>Lotação</small>
                  </ListGroupItemText>
                </ListGroupItem>
              </ul>
            </div>
            <div className="col-md-6">
              <ul className="list-group">
                <ListGroupItem className="list-group-item list-group-item-action">
                  <img src="img/pintar.png" alt="Cor" style={{ height: '40px', width: '40px' }} />
                  <ListGroupItemText>
                    <h6 className="mb-0">{consultaData.COR_VEICULO}</h6>
                    <small>Cor</small>
                  </ListGroupItemText>
                </ListGroupItem>
                <ListGroupItem className="list-group-item list-group-item-action">
                  <img src="img/compartilhamento-de-carro.png" alt="Espécie" style={{ height: '40px', width: '40px' }} />
                  <ListGroupItemText>
                    <h6 className="mb-0">{consultaData.ESPECIE}</h6>
                    <small>Espécie</small>
                  </ListGroupItemText>
                </ListGroupItem>
                <ListGroupItem className="list-group-item list-group-item-action">
                  <img src="img/009-stolen.png" alt="Roubo" style={{ height: '40px', width: '40px' }} />
                  <ListGroupItemText>
                    <h6 className="mb-0">{consultaData.STATUS_ROUBO}</h6>
                    <small>Roubo</small>
                  </ListGroupItemText>
                </ListGroupItem>
              </ul>
            </div>
          </div>

          <div className="row">
            <div className="col-12 text-center my-4">
              <i className="fa fa-user fa-2x"></i>
              <SectionTitle>Proprietário</SectionTitle>
            </div>
            <div className="col-md-6">
              <ul className="list-group">
                <ListGroupItem className="list-group-item list-group-item-action">
                  <img src="img/avatar-de-perfil.png" alt="Nome" style={{ height: '40px', width: '40px' }} />
                  <ListGroupItemText>
                    <h6 className="mb-0">{consultaData.PROPRIETARIO_NOME}</h6>
                    <small>Nome</small>
                  </ListGroupItemText>
                </ListGroupItem>
              </ul>
            </div>
            <div className="col-md-6">
              <ul className="list-group">
                <ListGroupItem className="list-group-item list-group-item-action">
                  <img src="img/carteira-de-motorista.png" alt="Documento" style={{ height: '40px', width: '40px' }} />
                  <ListGroupItemText>
                    <h6 className="mb-0">{consultaData.PROPRIETARIO_DOCUMENTO}</h6>
                    <small>Documento</small>
                  </ListGroupItemText>
                </ListGroupItem>
              </ul>
            </div>
          </div>
          <DownloadButton onClick={handleDownloadPDF}>Salvar em PDF</DownloadButton>
        </ContainerConsulta>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmação</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowModal(false);
              setConfirmationStep(0);
            }}
            style={{ flex: 1 }}
          >
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleModalConfirm} style={{ flex: 1 }}>
            Sim
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Erro</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </MainContainer>
  );
};

export default Consulta;
