import React from 'react';
import { Card, Container } from 'react-bootstrap';
import styled, { createGlobalStyle } from 'styled-components';
import CustomNavbar from './Navbar';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    background: #fff;
  }

  @media (max-width: 768px) {
    .card {
      margin: 20px;
    }
  }
`;

const TermContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TermCard = styled(Card)`
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 20px;
`;

const TermCardBody = styled(Card.Body)`
  padding: 20px;
`;

const TermTitle = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const TermSectionTitle = styled.h4`
  margin-top: 20px;
`;

const LGPDPage = () => {
  return (
    <>
      <CustomNavbar />
      <GlobalStyle />
      <TermContainer>
        <TermCard>
          <TermCardBody>
            <TermTitle>Termos de LGPD</TermTitle>
            <p>
              A Lei Geral de Proteção de Dados (LGPD) dispõe sobre o tratamento de dados pessoais, inclusive nos meios digitais, por pessoa natural ou por pessoa jurídica de direito público ou privado, com o objetivo de proteger os direitos fundamentais de liberdade e de privacidade e o livre desenvolvimento da personalidade da pessoa natural.
            </p>
            <TermSectionTitle>Finalidade</TermSectionTitle>
            <p>
              Os dados pessoais serão tratados de acordo com as seguintes finalidades:
            </p>
            <ul>
              <li>(I) execução de contrato ou procedimentos preliminares relacionados a contrato do qual seja parte o titular, a pedido do titular dos dados;</li>
              <li>(II) cumprimento de obrigação legal ou regulatória pelo controlador;</li>
              <li>(III) realização de estudos por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;</li>
              <li>(IV) exercício regular de direitos em processo judicial, administrativo ou arbitral;</li>
              <li>(V) proteção da vida ou da incolumidade física do titular ou de terceiro;</li>
              <li>(VI) tutela da saúde, exclusivamente, em procedimento realizado por profissionais de saúde, serviços de saúde ou autoridade sanitária;</li>
              <li>(VII) interesses legítimos do controlador ou de terceiro, exceto no caso de prevalecerem direitos e liberdades fundamentais do titular que exijam a proteção dos dados pessoais;</li>
              <li>(VIII) proteção do crédito, inclusive quanto ao disposto na legislação pertinente.</li>
            </ul>
            <TermSectionTitle>Direitos do Titular</TermSectionTitle>
            <p>
              O titular dos dados pessoais tem direito a obter do controlador, em relação aos dados por ele tratados, a qualquer momento e mediante requisição:
            </p>
            <ul>
              <li>(I) confirmação da existência de tratamento;</li>
              <li>(II) acesso aos dados;</li>
              <li>(III) correção de dados incompletos, inexatos ou desatualizados;</li>
              <li>(IV) anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto nesta Lei;</li>
              <li>(V) portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;</li>
              <li>(VI) eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas hipóteses previstas no art. 16 da LGPD;</li>
              <li>(VII) informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;</li>
              <li>(VIII) informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</li>
              <li>(IX) revogação do consentimento.</li>
            </ul>
            <TermSectionTitle>Segurança</TermSectionTitle>
            <p>
              O controlador deverá adotar medidas de segurança, técnicas e administrativas aptas a proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito.
            </p>
            <p>
              Esta política de privacidade pode ser atualizada a qualquer momento, motivo pelo qual recomendamos a sua leitura periódica.
            </p>
          </TermCardBody>
        </TermCard>
      </TermContainer>
    </>
  );
};

export default LGPDPage;
