import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosConfig'; // Importando a configuração do axios
import { Form, Button, Card, Alert, Dropdown } from 'react-bootstrap';
import { FaUserPlus, FaCoins, FaCalendarDay, FaCalendarAlt, FaUsers } from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

// Estilos globais
const GlobalStyle = createGlobalStyle`
  @media (max-width: 767px) {
    div#root {
      width: 500px;
        margin-left: 10px;
    }
  }

  .modal-content {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-left: 170px;

    @media (max-width: 767px) {
      margin-left: 0;
      width: 420px;
    }
  }
  
  [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
    width: auto;
  }

  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin-left: -40px;

    @media (max-width: 767px) {
      margin-left: 0;
      padding-top: 40px;
    }
  }
    @media (max-width: 767px) {
    div {
        width: AUTO;
        
    }
}
    .chWRRX {
    display: flex;
    /* height: 100vh; */
}

  .modal-footer > * {
    margin: auto;
    width: 100px;

    @media (max-width: 767px) {
      margin-left: 0;
      width: 100%;
    }
  }
`;

const GerenciamentoContainer = styled.div`
  display: flex;
  

  @media (max-width: 768px) {
    flex-direction: column;
    
  }
`;

const Sidebar = styled.div`
  width: 280px;
  background-color: #343a40;
  color: #343a40;
  padding: 20px;
  position: fixed;
  height: 100%;
  overflow-y: auto;

  .nav {
    display: flex;
    align-items: start;
    gap: 20px;
    margin-left: 2px;
}
  
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    position: relative;
    margin-left: -25px;
  }
`;

const Content = styled.div`
  margin-left: 280px;
  padding: 20px;
  //padding-top: 100px; /* Ajuste para garantir que os cards fiquem abaixo do menu lateral */
  width: calc(100% - 280px);
  overflow-y: auto;

  @media (max-width: 768px) {
    margin-left: 0;
    width: 440px;
    padding: 20px;
    margin-top: 20px; /* Adicionado para dispositivos móveis */
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  


  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

const StyledCard = styled(Card)`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 380PX;
  height: auto;
  margin-left: 10px;
  margin-top: 10px;

  .nav {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-left: 2px;
}

  @media (max-width: 768px) {
    width: 100%;
  }

  .form-control {
    width: 100%;
    max-width: 100%;
  }
  .mb-3 {
    margin-bottom: 1rem !important;
    WIDTH: max-content;
  }

  p {
    margin-bottom: 20px;
    font-size: 1.3em;
    padding-top: 25px;
    margin-left: 20px;
    font-weight: bold;
    color: #009879;
}

}
  .btn {
    width: 100%;
    max-width: 100%;
  }
`;

const AddCreditsForm = ({ onAddCredits }) => {
  const [token, setToken] = useState('');
  const [credits, setCredits] = useState('');

  const handleSubmit = () => {
    onAddCredits(token, credits);
  };

  return (
    <Form>
      <Form.Group className="mb-3" controlId="formAddToken">
        <Form.Label>Token</Form.Label>
        <Form.Control
          type="text"
          placeholder="Insira o token"
          value={token}
          onChange={(e) => setToken(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formCredits">
        <Form.Label>Créditos</Form.Label>
        <Form.Control
          type="number"
          placeholder="Insira a quantidade de créditos"
          value={credits}
          onChange={(e) => setCredits(e.target.value)}
        />
      </Form.Group>

      <Button variant="primary" className="w-100" onClick={handleSubmit}>
        Adicionar Crédito
      </Button>
    </Form>
  );
};

const RemoveCreditsForm = ({ onRemoveCredits }) => {
  const [token, setToken] = useState('');
  const [credits, setCredits] = useState('');

  const handleSubmit = () => {
    onRemoveCredits(token, credits);
  };

  return (
    <Form>
      <Form.Group className="mb-3" controlId="formRemoveToken">
        <Form.Label>Token</Form.Label>
        <Form.Control
          type="text"
          placeholder="Insira o token"
          value={token}
          onChange={(e) => setToken(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formRemoveCredits">
        <Form.Label>Créditos</Form.Label>
        <Form.Control
          type="number"
          placeholder="Insira a quantidade de créditos"
          value={credits}
          onChange={(e) => setCredits(e.target.value)}
        />
      </Form.Group>

      <Button variant="primary" className="w-100" onClick={handleSubmit}>
        Remover Crédito
      </Button>
    </Form>
  );
};


function GenerateToken() {
  const [newUsername, setNewUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [initialCredits, setInitialCredits] = useState('');
  const [newWhatsAppNumber, setNewWhatsAppNumber] = useState('');
  const [newCpfCnpj, setNewCpfCnpj] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newUserData, setNewUserData] = useState(null);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [copied, setCopied] = useState(false);
  const [addResponseMessage, setAddResponseMessage] = useState('');
  const [addResponseVariant, setAddResponseVariant] = useState('');
  const [removeResponseMessage, setRemoveResponseMessage] = useState('');
  const [removeResponseVariant, setRemoveResponseVariant] = useState('');
  const [userCount, setUserCount] = useState(0); // Estado para armazenar a quantidade de usuários
  const [creditsToday, setCreditsToday] = useState(0); // Estado para armazenar créditos inseridos hoje
  const [creditsMonth, setCreditsMonth] = useState(0); // Estado para armazenar créditos inseridos no mês
  const navigate = useNavigate();

  const validateForm = (username, password, credits, whatsAppNumber, email) => {
    if (!username || !password || isNaN(credits) || !whatsAppNumber) {
      setError('Todos os campos obrigatórios devem ser preenchidos e os créditos devem ser um número válido.');
      return false;
    }
    if (email && !email.includes('@')) {
      setError('Email inválido.');
      return false;
    }
    return true;
  };

  const formatCpfCnpj = (value) => {
    if (!value) return value;

    const cpfCnpj = value.replace(/[^\d]/g, '');

    if (cpfCnpj.length <= 11) {
      return cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else {
      return cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
  };

  const formatWhatsAppNumber = (value) => {
    if (!value) return value;

    const digits = value.replace(/[^\d]/g, '');

    if (digits.length <= 2) {
      return `(${digits}`;
    } else if (digits.length <= 7) {
      return `(${digits.slice(0, 2)}) ${digits.slice(2)}`;
    } else {
      return `(${digits.slice(0, 2)}) ${digits.slice(2, 7)}-${digits.slice(7, 11)}`;
    }
  };

  const fetchUserCount = async () => {
    try {
      const response = await axiosInstance.get('/api/Admin/GetActiveUserCount');
      setUserCount(response.data);
    } catch (error) {
      console.error('Erro ao buscar contagem de usuários:', error);
    }
  };

  const fetchCreditsToday = async () => {
    try {
      const response = await axiosInstance.get('/api/Admin/GetCreditsInsertedToday');
      setCreditsToday(response.data);
    } catch (error) {
      console.error('Erro ao buscar créditos inseridos hoje:', error);
    }
  };

  const fetchCreditsMonth = async () => {
    try {
      const response = await axiosInstance.get('/api/Admin/GetCreditsInsertedThisMonth');
      setCreditsMonth(response.data);
    } catch (error) {
      console.error('Erro ao buscar créditos inseridos no mês:', error);
    }
  };

  const handleCreateUser = async () => {
    if (!validateForm(newUsername, newPassword, initialCredits, newWhatsAppNumber, newEmail)) return;
    try {
      const response = await axiosInstance.post('/api/Admin/CreateUserWithToken', {
        username: newUsername.toUpperCase(),
        password: newPassword,
        initialCredits: parseInt(initialCredits),
        whatsAppNumber: newWhatsAppNumber,
        cpfcnpj: newCpfCnpj,
        email: newEmail.toUpperCase(),
        creditsAnterior:0,
        creditsAtual:0
      });
      setNewUserData(response.data);
      setSuccessMessage('Usuário criado com sucesso!');
      setError('');
      fetchUserCount(); // Atualizar a quantidade de usuários após criar um novo usuário
      fetchCreditsToday(); // Atualizar créditos inseridos hoje
      fetchCreditsMonth(); // Atualizar créditos inseridos no mês
    } catch (error) {
      console.error("Erro ao criar usuário:", error);
      setError('Erro ao criar usuário. Verifique o console para mais detalhes.');
    }
  };

 // Modificar as funções handleAddCredits e handleRemoveCredits para enviar token
const handleAddCredits = async (token, credits) => {
  try {
    const response = await axiosInstance.post('/api/Admin/AddCredits', {
      token,
      credits: Number(credits),
    });
    setAddResponseMessage(`Mensagem: ${response.data.mensagem}\nValor Anterior: ${response.data.valorAnterior}\nValor Atual: ${response.data.valorAtual}`);
    setAddResponseVariant('success');
    fetchCreditsToday(); // Atualizar créditos inseridos hoje
    fetchCreditsMonth(); // Atualizar créditos inseridos no mês
  } catch (error) {
    setAddResponseMessage('Erro ao adicionar créditos.');
    setAddResponseVariant('danger');
  }
};

const handleRemoveCredits = async (token, credits) => {
  try {
    const response = await axiosInstance.post('/api/Admin/RemoveCredits', {
      token,
      credits: Number(credits),
    });
    setRemoveResponseMessage(`Mensagem: ${response.data.mensagem}\nValor Anterior: ${response.data.valorAnterior}\nValor Atual: ${response.data.valorAtual}`);
    setRemoveResponseVariant('success');
    fetchCreditsToday(); // Atualizar créditos inseridos hoje
    fetchCreditsMonth(); // Atualizar créditos inseridos no mês
  } catch (error) {
    setRemoveResponseMessage('Erro ao remover créditos.');
    setRemoveResponseVariant('danger');
  }
};

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const handleLogout = () => {
    navigate('/login');
  };

  // Função para formatar valores em reais
  const formatCurrency = (value) => {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  return (
    <>
      <GlobalStyle /> {/* Incluindo estilos globais */}
      <GerenciamentoContainer>
        <Sidebar>
          <a className="d-flex align-items-center mb-3 mb-md-auto me-md-auto text-white text-decoration-none">
            <span className="fs-4">SISCRV</span>
          </a>
          <hr />
          <ul className="nav nav-pills flex-column mb-auto">
            <li className="nav-item">
              <Link to="/Admin/InserirCreditos" className="nav-link active" aria-current="page">
                Novo Usuário | Inserir Crédito
              </Link>
            </li>
            <li>
              <Link to="/Admin/services" className="nav-link text-white">
                Custos | Preços
              </Link>
            </li>
            <li>
              <Link to="/Admin/Gerenciamento" className="nav-link text-white">
                Gerenciamento
              </Link>
            </li>
            <li>
              <Link to="/Admin/GerenciamentoCreditos" className="nav-link text-white">
              Relatório de Créditos
              </Link>
            </li>
            <li>
              <Link to="/Admin/GerenciamentoUsuariosDia" className="nav-link text-white">
                Relatório Diário
              </Link>
            </li>
            <li>
              <Link to="/Admin/GerenciamentoUsuariosMes" className="nav-link text-white">
                Relatório Mensal
              </Link>
            </li>
          </ul>
          <hr />
          <Dropdown>
            <Dropdown.Toggle variant="dark" id="dropdownUser1" className="d-flex align-items-center text-white text-decoration-none">
              <img src="https://avatars.githubusercontent.com/u/39032772?s=400&u=be2f63c766595d29cd10e2c0b40a6a1f6c421310&v=4" alt="" width="32" height="32" className="rounded-circle me-2" />
              <strong>Administrador</strong>
            </Dropdown.Toggle>

            <Dropdown.Menu variant="dark">
              <Dropdown.Item href="#">New project...</Dropdown.Item>
              <Dropdown.Item href="#">Settings</Dropdown.Item>
              <Dropdown.Item href="#">Profile</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={handleLogout}>Sair</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <hr />
          <CardContainer>
            <StyledCard>
              <FaUsers size={24} />
              <div>
                <h6>Usuários</h6>
                <p>{userCount}</p>
              </div>
            </StyledCard>
            <StyledCard>
              <FaCalendarDay size={24} />
              <div>
                <h6>Créditos Hoje</h6>
                <p>{formatCurrency(creditsToday)}</p>
              </div>
            </StyledCard>
            <StyledCard>
              <FaCalendarAlt size={24} />
              <div>
                <h6>Créditos Mês</h6>
                <p>{formatCurrency(creditsMonth)}</p>
              </div>
            </StyledCard>
          </CardContainer>
        </Sidebar>

        <Content>
          <div style={{ marginLeft: '0', width: '100%',  display: 'flex', flexWrap: 'wrap', gap: '20px', marginTop: '20px' }}>
            <StyledCard className="shadow p-4 mb-4">
              <Card.Body>
                <h2 className="text-center mb-4">
                  <FaUserPlus className="me-2" /> Criar Novo Usuário
                </h2>
                <Form>
                  <Form.Group className="mb-3" controlId="formNewUsername">
                    <Form.Label>Nome de Usuário</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Novo nome de usuário"
                      value={newUsername}
                      onChange={(e) => setNewUsername(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formNewPassword">
                    <Form.Label>Senha</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Nova senha"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formInitialCredits">
                    <Form.Label>Créditos Iniciais</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Créditos iniciais"
                      value={initialCredits}
                      onChange={(e) => setInitialCredits(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formWhatsAppNumber">
                    <Form.Label>Número de WhatsApp</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="(XX) XXXXX-XXXX"
                      value={newWhatsAppNumber}
                      onChange={(e) => setNewWhatsAppNumber(formatWhatsAppNumber(e.target.value))}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formCpfCnpj">
                    <Form.Label>CPF/CNPJ</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="CPF ou CNPJ"
                      value={newCpfCnpj}
                      onChange={(e) => setNewCpfCnpj(formatCpfCnpj(e.target.value))}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      value={newEmail}
                      onChange={(e) => setNewEmail(e.target.value)}
                    />
                  </Form.Group>

                  <Button variant="primary" className="w-100" onClick={handleCreateUser}>
                    Criar Usuário
                  </Button>
                </Form>

                {error && <Alert className="mt-4" variant="danger">{error}</Alert>}
                {successMessage && <Alert className="mt-4" variant="success">{successMessage}</Alert>}

                {newUserData && (
                  <div className="mt-4">
                    <h3>Usuário Criado</h3>
                    <Form.Group className="mb-3">
                      <Form.Label>Nome do Usuário</Form.Label>
                      <Form.Control
                        type="text"
                        value={newUserData.username}
                        readOnly
                        onClick={() => handleCopy(newUserData.username)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Créditos</Form.Label>
                      <Form.Control
                        type="number"
                        value={newUserData.credits}
                        readOnly
                        onClick={() => handleCopy(newUserData.credits.toString())}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Token do Usuário</Form.Label>
                      <Form.Control
                        type="text"
                        value={newUserData.token}
                        readOnly
                        onClick={() => handleCopy(newUserData.token)}
                      />
                    </Form.Group>
                  </div>
                )}
              </Card.Body>
            </StyledCard>

            <StyledCard className="shadow p-4 mb-4">
              <Card.Body>
                <h2 className="text-center mb-4">
                  <FaCoins className="me-2" /> Adicionar Créditos
                </h2>
                <AddCreditsForm onAddCredits={handleAddCredits} />
                {addResponseMessage && (
                  <Alert className="mt-4" variant={addResponseVariant}>
                    {addResponseMessage.split('\n').map((line, index) => (
                      <p key={index}>{line}</p>
                    ))}
                  </Alert>
                )}
              </Card.Body>
            </StyledCard>

            <StyledCard className="shadow p-4 mb-4">
              <Card.Body>
                <h2 className="text-center mb-4">
                  <FaCoins className="me-2" /> Remover Créditos
                </h2>
                <RemoveCreditsForm onRemoveCredits={handleRemoveCredits} />
                {removeResponseMessage && (
                  <Alert className="mt-4" variant={removeResponseVariant}>
                    {removeResponseMessage.split('\n').map((line, index) => (
                      <p key={index}>{line}</p>
                    ))}
                  </Alert>
                )}
              </Card.Body>
            </StyledCard>
          </div>
        </Content>
      </GerenciamentoContainer>
    </>
  );
}

export default GenerateToken;
