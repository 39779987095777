import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosConfig'; // Importando a configuração do axios
import { Modal, Button, Dropdown, Form } from 'react-bootstrap';
import { FaMoneyBillWave, FaDollarSign, FaChartLine, FaUsers, FaEdit, FaTrashAlt, FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

// Estilos globais
const GlobalStyle = createGlobalStyle`
    button.btn.btn-warning {
    margin-right: 2px;
}
    button.btn.btn-info {
    margin-right: 2px;
}

.modal.show .modal-dialog {
    transform: none;
    background: transparent;
}
  @media (max-width: 767px) {
    div#root {
      width: fit-content;
    }
  }

  .modal-content {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: auto;
    margin-left: -250px;

    @media (max-width: 767px) {
      margin-left: 0;
       margin-left: 25px;
       width: auto;
       height: auto;
       padding: 0px;
    }
  }

  [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
    width: auto;
  }

  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin-left: -40px;
    @media (max-width: 767px) {
        width: auto;
        margin-top: -40px;
        margin-left: 0px;
        height: 400px;
        padding-top: 50px;

            .fade.modal.show {
        width: 450px;
        margin-left: -40px;
    }
        .modal-footer > * {
    margin: auto;
    width: 100px;
  }
`;

const GerenciamentoContainer = styled.div`
  display: flex;
  height: 100vh;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

const Sidebar = styled.div`
  width: 280px;
  background-color: #343a40;
  color: #343a40;
  padding: 20px;
  position: fixed;
  height: 100%;
  overflow-y: auto;
  align-items: start;

  .nav {
    display: flex;
    align-items: start;
    gap: 20px;
    margin-left: 2px;
}

  @media (max-width: 768px) {
    width: auto;
    height: auto;
    position: relative;
    margin-left: -25px;
  }
`;

const Content = styled.div`
  margin-left: 280px;
  padding: 80px;
  width: calc(100% - 280px);
  overflow-y: auto;
  @media (max-width: 768px) {
    margin-left: -30px;
    width: 110%;
    padding: 20px;
  }
`;

const UpdateButton = styled.button`
  background-color: #009879;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  font-family: sans-serif;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin: 20px 0;

  &:hover {
    background-color: #007f67;
    transform: scale(1.05);
  }

  &:active {
    background-color: #005f4a;
    transform: scale(1);
  }
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

  thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
  }

  th, td {
    padding: 12px 5px;
    width: auto;
    font-size: 12px;
  }

  tbody tr {
    border-bottom: 1px solid #dddddd;

    &:nth-of-type(even) {
      background-color: #f3f3f3;
    }

    &:last-of-type {
      border-bottom: 2px solid #009879;
    }

    &.active-row {
      font-weight: bold;
      color: #009879;
    }
  }

  @media (max-width: 768px) {
    font-size: 0.8em;
    th, td {
      padding: 8px;
    }

    button.btn.btn-info {
    width: 25px;
    height: 25px;
    margin-top: -10px;
}
    button.btn.btn-warning {
    width: 25px;
    height: 25px;
    margin-top: -10px;
}
    button.btn.btn-danger {
    width: 25px;
    height: 25px;
    margin-top: -10px;
}
    svg {
    margin-left: -8px;
    margin-top: -15px;
}
  }

  @media (max-width: 576px) {
    font-size: 0.7em;
    th, td {
      padding: 6px;
    }
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

const StyledCard = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  align-items: center;
  width: auto;
  height: 90px;

  & > svg {
    flex-shrink: 0; /* Garante que o ícone não encolha */
    margin-right: 15px; /* Espaçamento entre o ícone e o texto */
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centraliza verticalmente o conteúdo */
  }

  h6 {
    margin: 0;
    font-size: 1em;
    color: #343a40;
  }

  p {
    margin: 0;
    font-size: 1.2em;
    font-weight: bold;
    color: #009879;
  }

  @media (max-width: 767px) {
    padding: 11px;
    margin-top: -10px;
    width: 100%;

    & > svg {
      margin-right: 5px; /* Ajusta o espaçamento em telas menores */
    }

    h6 {
      font-size: 0.9em;
    }

    p {
      font-size: 1em;
    }
  }
`;

const UserManagementPage = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [requestLogs, setRequestLogs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [userCount, setUserCount] = useState(0); // Estado para armazenar a quantidade de usuários
  const navigate = useNavigate();
  const [editingUser, setEditingUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    fetchUserCount();

    if (!token || !username) {
      navigate('/Admin/Gerenciamento');
    } else {
      fetchUsers();
      fetchUserCount();
    }
  }, [navigate]);

  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get('/api/Admin/GetAllUsers');
      const lowercasedUsers = response.data.map(user => ({
        ...user,
        username: user.username.toLowerCase(),
        whatsAppNumber: user.whatsAppNumber.toLowerCase(),
        cpfcnpj: user.cpfcnpj.toLowerCase(),
        email: user.email.toLowerCase(),
        token: user.token.toLowerCase()
      }));
      setUsers(lowercasedUsers);
    } catch (error) {
      console.error('Erro ao buscar usuários:', error);
    }
  };

  const fetchUserCount = async () => {
    try {
      const response = await axiosInstance.get('/api/Admin/GetActiveUserCount');
      setUserCount(response.data);
    } catch (error) {
      console.error('Erro ao buscar contagem de usuários:', error);
    }
  };

  const fetchRequestLogs = async (user) => {
    try {
      const response = await axiosInstance.get(`/CRV/RequestLogs?token=${user.token}`);
      setRequestLogs(response.data);
      setSelectedUser(user);
      setShowModal(true);
    } catch (error) {
      console.error('Erro ao buscar logs de requisição:', error);
    }
  };

  const handleEditUser = (user) => {
    setEditingUser(user);
    setShowEditModal(true);
  };

  const handleDeleteUser = async (userId) => {
    if (window.confirm('Tem certeza que deseja deletar este usuário?')) {
      try {
        await axiosInstance.delete(`/api/Admin/DeleteUser/${userId}`);
        setUsers(users.filter(user => user.id !== userId));
      } catch (error) {
        console.error('Erro ao deletar usuário:', error);
      }
    }
  };

  const handleUpdateUser = async () => {
    try {
        const userPayload = {
            id: editingUser.id,
            username: editingUser.username,
            password: editingUser.password,
            token: editingUser.token,
            whatsAppNumber: editingUser.whatsAppNumber,
            cpfcnpj: editingUser.cpfcnpj,
            email: editingUser.email,
            credits: editingUser.credits,
            isAdmin: editingUser.isAdmin === "0" ? false : true
        };

        await axiosInstance.put(`/api/Admin/UpdateUser`, userPayload);
        setUsers(users.map(user => (user.id === editingUser.id ? editingUser : user)));
        setShowEditModal(false);
    } catch (error) {
        console.error('Erro ao atualizar usuário:', error);
    }
};

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingUser({
      ...editingUser,
      [name]: value,
    });
  };

  const calculateTotals = (logs) => {
    return logs.reduce(
      (totals, log) => {
        totals.totalRequests += 1;
        totals.totalCost += log.cost;
        totals.totalCharged += log.chargedAmount;
        totals.totalProfit += log.profit;
        return totals;
      },
      { totalRequests: 0, totalCost: 0, totalCharged: 0, totalProfit: 0 }
    );
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    navigate('/Login');
  };

  const totals = calculateTotals(requestLogs);

  // Função para formatar valores em reais
  const formatCurrency = (value) => {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  return (
    <>
      <GlobalStyle /> {/* Incluindo estilos globais */}
      <GerenciamentoContainer>
        <Sidebar>
          <a className="d-flex align-items-center mb-3 mb-md-auto me-md-auto text-white text-decoration-none">
            <span className="fs-4">SISCRV</span>
          </a>
          <hr />
          <ul className="nav nav-pills flex-column mb-auto">
            <li className="nav-item">
              <Link to="/Admin/Gerenciamento" className="nav-link active" aria-current="page">
                Gerenciamento
              </Link>
            </li>
            <li>
              <Link to="/Admin/services" className="nav-link text-white">
                Custos | Preços
              </Link>
            </li>
            <li>
              <Link to="/Admin/GerenciamentoCreditos" className="nav-link text-white">
              Relatório de Créditos
              </Link>
            </li>
            <li>
              <Link to="/Admin/InserirCreditos" className="nav-link text-white">
                Novo Usuário | Inserir Crédito
              </Link>
            </li>
            <li>
              <Link to="/Admin/GerenciamentoUsuariosDia" className="nav-link text-white">
                Relatório Diário
              </Link>
            </li>
            <li>
              <Link to="/Admin/GerenciamentoUsuariosMes" className="nav-link text-white">
                Relatório Mensal
              </Link>
            </li>
          </ul>
          <hr />
          <Dropdown>
            <Dropdown.Toggle variant="dark" id="dropdownUser1" className="d-flex align-items-center text-white text-decoration-none">
              <img src="https://avatars.githubusercontent.com/u/39032772?s=400&u=be2f63c766595d29cd10e2c0b40a6a1f6c421310&v=4" alt="" width="32" height="32" className="rounded-circle me-2" />
              <strong>Administrador</strong>
            </Dropdown.Toggle>

            <Dropdown.Menu variant="dark">
              <Dropdown.Item href="#">New project...</Dropdown.Item>
              <Dropdown.Item href="#">Settings</Dropdown.Item>
              <Dropdown.Item href="#">Profile</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={handleLogout}>Sair</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <hr />
          <CardContainer>
            <StyledCard>
              <FaMoneyBillWave size={24} />
              <div>
                <h6>Custo Total</h6>
                <p>{formatCurrency(totals.totalCost)}</p>
              </div>
            </StyledCard>
            <StyledCard>
              <FaDollarSign size={24} />
              <div>
                <h6>Valor Total</h6>
                <p>{formatCurrency(totals.totalCharged)}</p>
              </div>
            </StyledCard>
            <StyledCard>
              <FaChartLine size={24} />
              <div>
                <h6>Lucro Total</h6>
                <p>{formatCurrency(totals.totalProfit)}</p>
              </div>
            </StyledCard>
            <StyledCard>
              <FaUsers size={24} />
              <div>
                <h6>Usuários</h6>
                <p>{userCount}</p>
              </div>
            </StyledCard>
          </CardContainer>
          <UpdateButton onClick={fetchUsers}>Atualizar</UpdateButton>
        </Sidebar>

        <Content>
          <div style={{ width: '100%', maxWidth: '1200px', display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
            <div className="table-responsive">
              <h1>RELATÓRIO DE USUARIOS</h1>
              <StyledTable className="table table-striped table-hover table-bordered">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Usuário</th>
                    <th>WhatsApp</th>
                    <th>CPFCNPJ</th>
                    <th>Email</th>
                    <th>Token</th>
                    <th>Créditos</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user, index) => (
                    <tr key={user.id} className={index % 2 === 0 ? '' : 'active-row'}>
                      <td>{user.id}</td>
                      <td>{user.username}</td>
                      <td>{user.whatsAppNumber}</td>
                      <td>{user.cpfcnpj}</td>
                      <td>{user.email}</td>
                      <td>{user.token}</td>
                      <td>{formatCurrency(user.credits)}</td>
                      <td>
                        <Button variant="info" onClick={() => fetchRequestLogs(user)}><FaSearch /></Button>
                        <Button variant="warning" onClick={() => handleEditUser(user)}><FaEdit /></Button>
                        <Button variant="danger" onClick={() => handleDeleteUser(user.id)}><FaTrashAlt /></Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            </div>
          </div>
        </Content>

        <Modal show={showModal} onHide={() => setShowModal(false)} dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title" centered>
          <Modal.Header closeButton>
            <Modal.Title>Relatório do Usuário</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="table-responsive" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
              <StyledTable className="table table-striped table-hover table-bordered">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Usuário</th>
                    <th>Rota</th>
                    <th>Arquivo</th>
                    <th>Custo</th>
                    <th>Valor Cobrado</th>
                    <th>Lucro</th>
                    <th>Data da Requisição</th>
                    <th>Créditos Atuais</th>
                    <th>Créditos Anteriores</th>
                  </tr>
                </thead>
                <tbody>
                  {requestLogs.map((log, index) => (
                    <tr key={log.id} className={index % 2 === 0 ? '' : 'active-row'}>
                      <td>{log.id}</td>
                      <td>{log.userName}</td>
                      <td>{log.route}</td>
                      <td>{log.fileName}</td>
                      <td>{formatCurrency(log.cost)}</td>
                      <td>{formatCurrency(log.chargedAmount)}</td>
                      <td>{formatCurrency(log.profit)}</td>
                      <td>{new Date(log.requestTime).toLocaleString()}</td>
                      <td>{formatCurrency(log.currentCredits)}</td>
                      <td>{formatCurrency(log.previousCredits)}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="10">
                      <p>Total de Requisições: {totals.totalRequests}</p>
                    </td>
                  </tr>
                </tfoot>
              </StyledTable>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showEditModal} onHide={() => setShowEditModal(false)} dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title" centered>
          <Modal.Header closeButton>
            <Modal.Title>Editar Usuário</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {editingUser && (
              <Form>
                <Form.Group className="mb-3" controlId="formUsername">
                  <Form.Label>Nome de Usuário</Form.Label>
                  <Form.Control
                    type="text"
                    name="username"
                    value={editingUser.username}
                    onChange={handleEditInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formPassword">
                  <Form.Label>Senha</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={editingUser.password}
                    onChange={handleEditInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formCredits">
                  <Form.Label>Créditos</Form.Label>
                  <Form.Control
                    type="number"
                    name="credits"
                    value={editingUser.credits}
                    onChange={handleEditInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formT">
                  <Form.Label>Token</Form.Label>
                  <Form.Control
                    type="token"
                    name="token"
                    value={editingUser.token}
                    onChange={handleEditInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formWhatsAppNumber">
                  <Form.Label>WhatsApp</Form.Label>
                  <Form.Control
                    type="text"
                    name="whatsAppNumber"
                    value={editingUser.whatsAppNumber}
                    onChange={handleEditInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formCPFCNPJ">
                  <Form.Label>CPFCNPJ</Form.Label>
                  <Form.Control
                    type="text"
                    name="cpfcnpj"
                    value={editingUser.cpfcnpj}
                    onChange={handleEditInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={editingUser.email}
                    onChange={handleEditInputChange}
                  />
                </Form.Group>
               
                <Form.Group className="mb-3" controlId="formIsAdmin">
                  <Form.Label>Administrador</Form.Label>
                  <Form.Control
                    as="select"
                    name="isAdmin"
                    value={editingUser.isAdmin}
                    onChange={handleEditInputChange}
                  >
                    <option value="0">Não</option>
                    <option value="1">Sim</option>
                  </Form.Control>
                </Form.Group>
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
          <Button variant="primary" onClick={handleUpdateUser}>
                  Atualizar
                </Button>
            <Button variant="secondary" onClick={() => setShowEditModal(false)}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </GerenciamentoContainer>
    </>
  );
};

export default UserManagementPage;
