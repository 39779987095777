// src/components/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import axiosInstance from '../axiosConfig'; // Importando a configuração do axios

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [token, setToken] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        const storedIsAdmin = localStorage.getItem('isAdmin') === 'true';
        if (storedToken) {
            setIsAuthenticated(true);
            setToken(storedToken);
            setIsAdmin(storedIsAdmin);
            axios.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
        }
    }, []);

    const login = async (username, password) => {
        try {
            const response = await axiosInstance.post('/api/Admin/Login', {
                username,
                password
            });
            if (response.data.token) {
                setIsAuthenticated(true);
                setToken(response.data.token);
                setIsAdmin(response.data.isAdmin);
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('isAdmin', response.data.isAdmin);
                axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
                return true;
            }
        } catch (error) {
            console.error('Erro ao fazer login:', error);
            if (error.response && error.response.status === 401) {
                setError('Credenciais inválidas.');
            } else {
                setError('Erro ao fazer login. Por favor, tente novamente mais tarde.');
            }
        }
        return false;
    };

    const logout = () => {
        setIsAuthenticated(false);
        setToken(null);
        setIsAdmin(false);
        localStorage.removeItem('token');
        localStorage.removeItem('isAdmin');
        delete axios.defaults.headers.common['Authorization'];
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, isAdmin, login, logout, token, error }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
