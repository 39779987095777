import styled, { createGlobalStyle } from 'styled-components';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from './axiosConfig'; // Importando a configuração do axios
import PDFViewer from './PDFViewer';
import { Card, Modal, Button, Form, Navbar, Nav } from 'react-bootstrap';

// Estilos globais para a aplicação
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
  }
`;

// Estilizando o container principal
const MainContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
`;

// Estilizando o cabeçalho
const Header = styled.header`
  width: 100%;
  .navbar {
    background-color: #f8f9fa;
    display: flex;
    justify-content: center;
    align-items: center;
    .navbar-brand {
      color: #000000;
      .bi {
        width: 40px;
        height: 32px;
      }
    }
    .nav-pills {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .nav-link {
      color: #007bff;
    }
    .nav-link.active {
      background-color: #fff;
      color: #007bff;
      font-weight: bold;
    }
  }

  @media (max-width: 768px) {
    .navbar {
      flex-direction: row;
      justify-content: space-between;
      .navbar-brand {
        margin-right: auto;
      }
      .nav-pills {
        justify-content: flex-end;
        flex-grow: 1;
      }
    }
  }
`;

// Estilizando o container de conteúdo
const ContentContainer = styled.div`
  display: grid;
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 10px;
    width: auto;
  }
`;

// Estilizando o card de formulário
const FormCard = styled(Card)`
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  background-color: #ffffff;
  width: 400px;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

// Estilizando o card de PDF
const PdfCard = styled(Card)`
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  background-color: #ffffff;

   @media (max-width: 768px) {
    padding: 15px;
    margin-left: -120px;
  }
`;

// Estilizando os componentes conforme solicitado
const ServiceLabel = styled.label`
  margin-top: 10px;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-top: 3px;
  margin-bottom: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;

  @media (max-width: 1020px) {
    padding: 6px;
  }
`;

const CreditsInfo = styled.p`
  margin-top: 4px;
  color: #516377;
`;

const SubmitButton = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  margin-top: 8px;
  width: 100%;
  &:disabled {
    background-color: #6c757d;
  }

  @media (max-width: 1020px) {
    padding: 8px 16px;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-top: 10px;
`;

const LoadingImage = styled.img`
  display: block;
  margin: 20px auto;
`;

const DocumentImage = styled.img`
  width: 100%;
  max-width: 600px;
  display: block;
  margin: 20px auto;

  @media (max-width: 1020px) {
    max-width: 100%;
  }
`;

const PdfDownloadLink = styled.a`
  display: block;
  text-align: center;
  margin-top: 20px;
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    
  }
`;

// Componente principal
const SISDOK = () => {
  const [service, setService] = useState('');
  const [placa, setPlaca] = useState('');
  const [renavam, setRenavam] = useState('');
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [tipoPessoa, setTipoPessoa] = useState('F');
  const [username, setUsername] = useState('');
  const [token, setToken] = useState('');
  const [credits, setCredits] = useState(0);
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfName, setPdfName] = useState('documento.pdf');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showImage, setShowImage] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  const [services, setServices] = useState([]);

  const navigate = useNavigate();

  const specialStates = [
    'CrlvAM', 'CrlvBA', 'CrlvCE', 'CrlvES', 'CrlvDF', 
    'CrlvMS', 'CrlvPB', 'CrlvPE', 'CrlvPI', 
    'CrlvRS', 'CrlvRO', 'CrlvSC',  'CrlvSE'
  ];

  const isSpecialState = (service) => {
    return specialStates.includes(service);
  };

  const fetchServices = async () => {
    try {
      const response = await axiosInstance.get('/api/admin/Services');
      const servicePrices = response.data.reduce((acc, service) => {
        acc[service.name] = service.price;
        return acc;
      }, {});

      const updatedServices = [
        { label: `Consulta Veicular R$${servicePrices.ConsultaVP} - (Emissão na hora)`, value: 'ConsultaVP', price: servicePrices.ConsultaVP },
        { label: `2 via ATPV-e - R$${servicePrices['2viaATPVe']} - (Emissão na hora)`, value: '2viaATPVe', price: servicePrices['2viaATPVe'] },
        { label: `CRV Digital - R$${servicePrices.CrvDigital} - (Emissão na hora)`, value: 'CrvDigital', price: servicePrices.CrvDigital },
        { label: `CRLV AC - R$${servicePrices.CrlvAC} - (Emissão na hora)`, value: 'CrlvAC', price: servicePrices.CrlvAC },
        { label: `CRLV AM - R$${servicePrices.CrlvAM}`, value: 'CrlvAM', price: servicePrices.CrlvAM },
        { label: `CRLV AP - R$${servicePrices.CrlvAP} - (Emissão na hora)`, value: 'CrlvAP', price: servicePrices.CrlvAP },
        { label: `CRLV BA - R$${servicePrices.CrlvBA}`, value: 'CrlvBA', price: servicePrices.CrlvBA },
        { label: `CRLV CE - R$${servicePrices.CrlvCE}`, value: 'CrlvCE', price: servicePrices.CrlvCE },
        { label: `CRLV DF - R$${servicePrices.CrlvDF}`, value: 'CrlvDF', price: servicePrices.CrlvDF },
        { label: `CRLV ES - R$${servicePrices.CrlvES}`, value: 'CrlvES', price: servicePrices.CrlvES },
        { label: `CRLV GO - R$${servicePrices.CrlvGO} - (Emissão na hora)`, value: 'CrlvGO', price: servicePrices.CrlvGO },
        { label: `CRLV MA - R$${servicePrices.CrlvMA} - (Emissão na hora)`, value: 'CrlvMA', price: servicePrices.CrlvMA },
        { label: `CRLV MG - R$${servicePrices.CrlvMG} - (Emissão na hora)`, value: 'CrlvMG', price: servicePrices.CrlvMG },
        { label: `CRLV MT - R$${servicePrices.CrlvMT} - (Emissão na hora)`, value: 'CrlvMT', price: servicePrices.CrlvMT },
        { label: `CRLV MS - R$${servicePrices.CrlvMS}`, value: 'CrlvMS', price: servicePrices.CrlvMS },
        { label: `CRLV PB - R$${servicePrices.CrlvPB}`, value: 'CrlvPB', price: servicePrices.CrlvPB },
        { label: `CRLV PE - R$${servicePrices.CrlvPE}`, value: 'CrlvPE', price: servicePrices.CrlvPE },
        { label: `CRLV PI - R$${servicePrices.CrlvPI}`, value: 'CrlvPI', price: servicePrices.CrlvPI },
        { label: `CRLV PR - R$${servicePrices.CrlvPR} - (Emissão na hora)`, value: 'CrlvPR', price: servicePrices.CrlvPR },
        { label: `CRLV RJ - R$${servicePrices.CrlvRJ} - (Emissão na hora)`, value: 'CrlvRJ', price: servicePrices.CrlvRJ },
        { label: `CRLV RN - R$${servicePrices.CrlvRN} - (Emissão na hora)`, value: 'CrlvRN', price: servicePrices.CrlvRN },
        { label: `CRLV RO - R$${servicePrices.CrlvRO}`, value: 'CrlvRO', price: servicePrices.CrlvRO },
        { label: `CRLV RR - R$${servicePrices.CrlvRR} - (Emissão na hora)`, value: 'CrlvRR', price: servicePrices.CrlvRR },
        { label: `CRLV RS - R$${servicePrices.CrlvRS}`, value: 'CrlvRS', price: servicePrices.CrlvRS },
        { label: `CRLV SC - R$${servicePrices.CrlvSC}`, value: 'CrlvSC', price: servicePrices.CrlvSC },
        { label: `CRLV SE - R$${servicePrices.CrlvSE}`, value: 'CrlvSE', price: servicePrices.CrlvSE },
        { label: `CRLV SP - R$${servicePrices.CrlvSP} - (Emissão na hora)`, value: 'CrlvSP', price: servicePrices.CrlvSP },
        { label: `CRLV TO - R$${servicePrices.CrlvTO} - (Emissão na hora)`, value: 'CrlvTO', price: servicePrices.CrlvTO }
      ];

      setServices(updatedServices);
      localStorage.setItem('services', JSON.stringify(updatedServices));
    } catch (error) {
      console.error('Erro ao obter serviços:', error);
    }
  };

  useEffect(() => {
    const storedServices = localStorage.getItem('services');
    if (storedServices) {
      setServices(JSON.parse(storedServices));
    } else {
      fetchServices();
    }

    const fetchCredits = async () => {
      if (username && token) {
        try {
          const response = await axiosInstance.get(`/api/user/credits`, {
            params: { username, token }
          });
          setCredits(response.data.credits);
        } catch (error) {
          console.error('Erro ao obter créditos:', error);
          setError('Erro ao obter créditos do usuário.');
        }
      }
    };

    fetchCredits();

    const doNotShow = localStorage.getItem('doNotShowInfoModal') === 'true';
    setDoNotShowAgain(doNotShow);
  }, [username, token]);

  const handleRequest = async () => {
    if (!service) {
      setError('Por favor, selecione um serviço.');
      return;
    }

    if (service === 'consulta') {
      navigate('/consulta');
      return;
    }

    const selectedService = services.find(s => s.value === service);
    if (!selectedService) {
      setError('Serviço inválido.');
      return;
    }

    if (credits < selectedService.price) {
      setError('Créditos insuficientes.');
      return;
    }

    if (!placa || !username || !token) {
      setError('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    if (!['CrlvMG', 'consulta'].includes(service) && !renavam) {
      setError('Por favor, preencha o campo Renavam.');
      return;
    }

    if (['CrlvSP', 'CrlvGO', 'CrlvPR', 'CrlvRJ'].includes(service) && (!cpfCnpj || !tipoPessoa)) {
      setError('Por favor, preencha todos os campos.');
      return;
    }

    if (isSpecialState(service)) {
      const message = `Olá, quero que emita o ${selectedService.label}, placa: ${placa}.`;
      window.open(`https://api.whatsapp.com/send?phone=5511999184789&text=${encodeURIComponent(message)}`);
      setLoading(false);
      return;
    }

    setError('');
    setPdfUrl('');
    setLoading(true);
    setShowImage(false);

    try {
      const plateCheckResponse = await axiosInstance.get(`/CRV/checkPlate`, {
        params: { placa }
      });

      if (plateCheckResponse.data.exists) {
        setConfirmModal(true);
        setLoading(false);
        return;
      }

      await makeRequest(selectedService, placa, renavam, cpfCnpj, tipoPessoa, username, token);
    } catch (error) {
      console.error('Erro ao verificar placa:', error);
      setError('Erro ao verificar placa.');
    } finally {
      setLoading(false);
    }
  };

  const makeRequest = async (selectedService, placa, renavam, cpfCnpj, tipoPessoa, username, token) => {
    setLoading(true);
    try {
      let response;
      const params = { placa, username, token };

      if (!['CrlvMG', 'consulta'].includes(service)) {
        params.renavam = renavam;
      }

      if (['CrlvSP', 'CrlvGO', 'CrlvPR', 'CrlvRJ'].includes(service)) {
        params.renavam = renavam;
        params.cpfCnpj = cpfCnpj;
        params.tipoPessoa = tipoPessoa;
      }

      if (service === 'CrlvMG') {
        params.uf = 'MG';
        response = await axiosInstance.post(`/CRV/CrlvMG`, null, {
          params: params,
          responseType: 'blob'
        });
      } else if (['CrlvSP', 'CrlvGO', 'CrlvPR', 'CrlvRJ'].includes(service)) {
        response = await axiosInstance.post(`/CRV/${service}`, null, {
          params: params,
          responseType: 'blob'
        });
      } else {
        response = await axiosInstance.get(`/CRV/${service}`, {
          params: params,
          responseType: 'blob'
        });
      }

      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      const contentDisposition = response.headers['content-disposition'];
      let fileName = placa + '_CRLV.pdf';
      if (contentDisposition && contentDisposition.includes('attachment')) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
        }
      }

      setCredits(credits - selectedService.price);

      setPlaca('');
      setRenavam('');
      setCpfCnpj('');

      setPdfUrl(pdfUrl);
      setPdfName(fileName);
    } catch (error) {
      console.error('Erro ao fazer a requisição:', error);
      setError('Erro ao fazer requisição! Verifique se inseriu os dados corretamente.');
    } finally {
      setLoading(false);
    }
  };

  const handleServiceChange = (e) => {
    const selectedService = e.target.value;
    setService(selectedService);
    setShowModal(false);
    setTermsAccepted(false);

    if (selectedService === 'consulta') {
      navigate('/consulta');
    } else if (isSpecialState(selectedService)) {
      setShowModal(true);
      setTermsAccepted(false);
    } else if (!['CrlvAM', 'CrlvBA', 'CrlvCE', 'CrlvES', 'CrlvDF', 'CrlvMS', 'CrlvPB', 'CrlvPE', 'CrlvPI', 'CrlvRS', 'CrlvRO', 'CrlvSC', 'CrlvSE', '2viaATPVe', 'CrvDigital', 'consulta'].includes(selectedService)) {
      if (!doNotShowAgain) {
        setShowInfoModal(true);
      }
    } else {
      setShowModal(false);
    }
  };

  const handleCloseInfoModal = () => {
    if (doNotShowAgain) {
      localStorage.setItem('doNotShowInfoModal', 'true');
    }
    setShowInfoModal(false);
  };

  const handleAcceptTerms = () => {
    setTermsAccepted(true);
    setShowModal(false);
  };

  const shouldShowCpfCnpj = () => {
    return ['CrlvSP', 'CrlvGO', 'CrlvPR', 'CrlvRJ'].includes(service);
  };

  const handleConfirm = async () => {
    setConfirmModal(false);
    await makeRequest(service, placa, renavam, cpfCnpj, tipoPessoa, username, token);
  };

  const handleCancel = () => {
    setConfirmModal(false);
    setLoading(false);
  };

  return (
    <MainContainer>
      <GlobalStyle />
      <Header className="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
        <Navbar expand="lg" className="w-100">
          <Navbar.Brand href="" className="d-flex align-items-center">
            <svg className="bi me-2" width="40" height="32"><use xlinkHref="#bootstrap"></use></svg>
            <span className="fs-4">CRLV na Hora!</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
            <Nav className="nav-pills">
              <Nav.Link href="/Dashboard" className="nav-link active">CRLV Digital</Nav.Link>
              <Nav.Link href="/ConsultaPremium" className="nav-link">Consulta PREMIUM</Nav.Link>
              <Nav.Link href="/ConsultaBinEstadual" className="nav-link">Base Estadual</Nav.Link>
              <Nav.Link href="/ConsultaBinNacional" className="nav-link">Base Nacional</Nav.Link>
              <Nav.Link href="#" className="nav-link">Leilão PREMIUM</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Header>
      <ContentContainer className="sisdok-container d-grid gap-3">
        <FormCard className="shadow p-4">
          <div>
           
            <ServiceLabel>Serviço</ServiceLabel>
            <select value={service} onChange={handleServiceChange} className="form-control">
              <option value="">Selecione um serviço</option>
              {services.map((s) => (
                <option key={s.value} value={s.value}>{s.label}</option>
              ))}
            </select>
            {service !== 'consulta' && (
              <>
                <ServiceLabel>PLACA</ServiceLabel>
                <Input
                  type="text"
                  placeholder="Placa"
                  value={placa}
                  onChange={(e) => setPlaca(e.target.value)}
                  className="form-control"
                />
                {!['CrlvMG'].includes(service) && (
                  <>
                    <ServiceLabel>RENAVAM</ServiceLabel>
                    <Input
                      type="text"
                      placeholder="Renavam"
                      value={renavam}
                      onChange={(e) => setRenavam(e.target.value)}
                      className="form-control"
                    />
                  </>
                )}
                {shouldShowCpfCnpj() && (
                  <>
                    <ServiceLabel>CPF/CNPJ</ServiceLabel>
                    <Input
                      type="text"
                      placeholder="CPF/CNPJ"
                      value={cpfCnpj}
                      onChange={(e) => setCpfCnpj(e.target.value)}
                      className="form-control"
                    />
                    <ServiceLabel>Tipo de Pessoa</ServiceLabel>
                    <select value={tipoPessoa} onChange={(e) => setTipoPessoa(e.target.value)} className="form-control">
                      <option value="F">Física</option>
                      <option value="J">Jurídica</option>
                    </select>
                  </>
                )}
                <ServiceLabel>USUÁRIO</ServiceLabel>
                <Input
                  type="text"
                  placeholder="Nome de usuário"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="form-control"
                />
                <ServiceLabel>TOKEN</ServiceLabel>
                <Input
                  type="text"
                  placeholder="Token"
                  value={token}
                  onChange={(e) => setToken(e.target.value)}
                  className="form-control"
                />
                <CreditsInfo>Créditos disponíveis: R${credits.toFixed(2)}</CreditsInfo>
              </>
            )}
            <SubmitButton onClick={handleRequest} disabled={isSpecialState(service) && !termsAccepted}>
              {isSpecialState(service) ? 'Solicitar Documento' : 'Emitir Documento'}
            </SubmitButton>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {loading && <LoadingImage src="carregamento.gif" alt="Carregando..." />}
          </div>
        </FormCard>
        <PdfCard className="shadow p-4">
          <div>
            {showImage && !pdfUrl && (
              <DocumentImage src="documentocrlv.jpg" alt="Documento CRLV" />
            )}
            {pdfUrl && !loading && (
              <div>
                <PDFViewer pdfUrl={pdfUrl} />
                <PdfDownloadLink href={pdfUrl} download={pdfName}>Download do PDF</PdfDownloadLink>
              </div>
            )}
          </div>
        </PdfCard>
      </ContentContainer>
      {showModal && (
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Termos de Emissão de Documento</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              A emissão do CRLV Digital para este Estado segue um procedimento diferente, pois ainda não é possível realizá-la de forma automática. Por favor, preencha os dados da placa, nome de usuário e token. Ao clicar no botão, você será direcionado para o WhatsApp da nossa equipe. O atendimento é realizado por ordem de solicitação.
            </p>
            <p>
              Aguarde enquanto fazemos a consulta no site do Detran e informaremos qual documento conseguimos emitir. Se estiver de acordo e for o documento que você precisa, basta efetuar o pagamento. O documento será emitido junto ao Detran no mesmo dia. Após a emissão, enviaremos o arquivo PDF pelo WhatsApp.
            </p>
            <Form.Check
              type="checkbox"
              label="Aceito os termos descritos acima."
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleAcceptTerms} disabled={!termsAccepted}>Fechar</Button>
          </Modal.Footer>
        </Modal>
      )}
      {showInfoModal && (
        <Modal show={showInfoModal} onHide={handleCloseInfoModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Atenção</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Antes de solicitar o documento (CRLV-e), verifique as seguintes considerações:
              Consulte antes no site do detran se o documento já está atualizado no sistema, caso tenha pago os débitos recentemente aguarde até que o detran atualize o documento no sistema.
            </p>
            <p>
              Motivos que fazem o documento não atualizar
              Comunicado de venda - Intenção de venda - Recall - IPVA em aberto - Bloqueios - Pagamento recente de débitos - óbito
            </p>
            <p>
              Ao prosseguir com a emissão, o usuário confirma que consultou previamente seu veículo, sendo total responsável caso possua débitos os restrições que ocasione na emissão de documento desatualizado.
            </p>
            <Form.Check
              type="checkbox"
              label="Não mostrar mensagem novamente"
              checked={doNotShowAgain}
              onChange={(e) => setDoNotShowAgain(e.target.checked)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseInfoModal}>Ok, ciente!</Button>
          </Modal.Footer>
        </Modal>
      )}
      {confirmModal && (
        <Modal show={confirmModal} onHide={handleCancel} centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirmação de Emissão</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Tem certeza que deseja emitir novamente o documento da placa {placa}?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleCancel}>Não</Button>
            <Button variant="success" onClick={handleConfirm}>Sim</Button>
          </Modal.Footer>
        </Modal>
      )}
    </MainContainer>
  );
};

export default SISDOK;
