import React, { useState, useRef, useEffect } from 'react';
import axiosInstance from './axiosConfig'; // Importando a configuração do axios
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Card, Table, Button, Modal } from 'react-bootstrap';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import styled, { createGlobalStyle } from 'styled-components';
import CustomNavbar from './Navbar'; // Importando o CustomNavbar

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    background: #fff;
  }
  button.btn.btn-secondary {
    padding: inherit;
  }
  .bXxSxj {
    width: auto;
  }
  .card-body {
    background: white;
    width: 550px;
  }
  @media (max-width: 768px) {
    .modal-header .btn-close {
      margin-right: 140px;
    }
    .modal-footer {
      justify-content: start;
    }
    .text-success {
      --bs-text-opacity: 1;
      color: rgb(251 244 246) !important;
      background: #0090ff;
    }
    .row {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  @media (max-width: 768px) {
    div {
      height: 100%;
      width: 380px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: -10px;
      margin-left: -5px;
    }
  }
`;

const Header = styled.header`
  width: 100%;  
  height: auto;
  margin-left: 0px;
  background-color: #fff;
`;

const NavbarToggle = styled.button`
  background: none;
  border: none;
  color: #000;
  font-size: 1.5rem;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1100;
  @media (min-width: 769px) {
    display: none;
  }
`;

const ConsultaCard = styled(Card)`
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 20px auto;
  padding: 20px;
  @media (max-width: 767px) {
    padding: 20px;
    width: 100%;
    margin-left: -15px;
    div {
      width: 100%;
      padding: 11px;
    }
  }
`;

const ConsultaTitle = styled.h2`
  text-align: left;
  margin-bottom: 2rem;
  font-family: 'FontAwesome';
  color: black;
  font-size: larger;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-top: 0px;
  margin-bottom: 8px;
  border: 1px solid #007bff;
  border-radius: 4px;
`;

const CreditsInfo = styled.p`
  color: #007bff;
  font-size: large;
  @media (max-width: 767px) {
    margin-top: -10px;
  }
`;

const ConsultaButton = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  margin-top: 8px;
  width: 100%;
  &:disabled {
    background-color: #007bff;
  }
  @media (max-width: 767px) {
    margin-top: -10px;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-top: 10px;
`;

const ContainerConsulta = styled.div`
  margin-top: 20px;
  display: flow;
  flex-direction: column;
  align-items: center;
  .table-responsive {
    width: auto;
  }
  @media (max-width: 767px) {
    margin-left: -15px;
  }
`;

const SectionTitle = styled.h3`
  display: inline-block;
  margin: 10px;
`;

const ListGroupItem = styled.li`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ced4da;
`;

const ListGroupItemText = styled.div`
  margin-left: 1rem;
`;

const CaptchaContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding: 0 10px;
  @media (max-width: 767px) {
    iframe {
      max-width: auto;
      transform: scale(1);
      transform-origin: 0 0;
      margin-top: 30px;
      margin-left: -25px;
    }
  }
`;

const ConsultaEstadual = () => {
  const [placa, setPlaca] = useState('');
  const [token, setToken] = useState('');
  const [username, setUsername] = useState('');
  const [captchaToken, setCaptchaToken] = useState(null);
  const [consultaData, setConsultaData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [credits, setCredits] = useState(0);
  const [navbarExpanded, setNavbarExpanded] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const hcaptchaRef = useRef(null);
  const [debouncedToken, setDebouncedToken] = useState(token);

      const trackPurchase = () => {
        // Disparar o evento personalizado para o GTM
        window.dataLayer.push({
            'event': 'successfulConsultation',
            'service': 'BaseEstadual',
            'Value': '10,00'
        });
    };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedToken(token);
    }, 3000);
    return () => {
      clearTimeout(handler);
    };
  }, [token]);

  useEffect(() => {
    if (debouncedToken) {
      fetchCredits(debouncedToken);
    }
  }, [debouncedToken]);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');
    if (storedToken) {
      setToken(storedToken);
    }
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('userToken', token);
    localStorage.setItem('username', username);
  }, [token, username]);

  const fetchCredits = async (token) => {
    try {
      const response = await axiosInstance.get('/api/user/credits', {
        params: { token },
      });
      setCredits(response.data.credits);
    } catch (error) {
      console.error('Erro ao obter créditos:', error);
      setError('Erro ao obter créditos do usuário.');
      setShowErrorModal(true);
    }
  };

  const handleConsulta = async () => {
    if (!placa || !token || !captchaToken || !username) {
      setError('Por favor, preencha todos os campos e complete o CAPTCHA.');
      setShowErrorModal(true);
      return;
    }
    if (credits < 10) {
      setError('Créditos insuficientes para realizar a consulta.');
      setShowErrorModal(true);
      return;
    }
    setError('');
    setLoading(true);
    setConsultaData(null);
    try {
      const response = await axiosInstance.post('/CRV/BinEstadual', null, {
        params: {
          placa,
          username,
          token,
        },
      });
  
      if (!response.data.BIN_ESTADUAL || Object.keys(response.data.BIN_ESTADUAL).length === 0) {
        setError('Veículo não encontrado! Verifique se digitou a placa corretamente.');
        setShowErrorModal(true);
        return;
      }
  
      setConsultaData(response.data.BIN_ESTADUAL);
      const updatedCredits = credits - 10;
      setCredits(updatedCredits);

        // Disparar o evento de consulta bem-sucedida para o GTM
        trackPurchase();

    } catch (error) {
      console.error('Erro ao fazer a requisição:', error);
      if (error.response) {
        if (error.response.status === 401) {
          setError('Token inválido ou expirado.');
        } else if (error.response.status === 400) {
          setError('Créditos insuficientes! Insira novos créditos acessando a página Recarga Pix e realize sua consulta.');
        } else {
          setError('Veículo não encontrado! Verifique se digitou a placa corretamente.');
        }
      } else {
        setError('Erro ao fazer a requisição. Verifique o console para mais detalhes.');
      }
      setShowErrorModal(true);
    } finally {
      setLoading(false);
      if (hcaptchaRef.current) {
        hcaptchaRef.current.resetCaptcha();
        setCaptchaToken(null);
      }
    }
  };

  const onCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handlePlacaChange = (e) => {
    const value = e.target.value.toUpperCase();
    if (value.length <= 7 && /^[A-Z0-9]*$/.test(value)) {
      setPlaca(value);
    }
  };

  const handleDownloadPDF = () => {
    if (!consultaData || Object.keys(consultaData).length === 0) {
      setError('Não há dados para gerar o PDF.');
      setShowErrorModal(true);
      return;
    }
  
    const doc = new jsPDF();
    doc.text('Consulta Estadual', 14, 16);
    const addSection = (title, data) => {
      doc.autoTable({
        startY: doc.lastAutoTable ? doc.lastAutoTable.finalY + 10 : 20,
        head: [[title, '']],
        body: data.map(([label, value]) => [
          { content: label, styles: { fontStyle: 'bold' } },
          value,
        ]),
        theme: 'grid',
        styles: {
          fontSize: 10,
          cellPadding: 3,
          valign: 'middle',
          lineWidth: 0.1,
        },
        columnStyles: {
          0: { cellWidth: 80 },
          1: { cellWidth: 102 },
        },
      });
    };
    const renderTableDataForPDF = (data) => {
      return Object.entries(data).map(([key, value]) => {
        return [key.replace(/_/g, ' '), value];
      });
    };

    addSection('DADOS DO VEÍCULO', renderTableDataForPDF({
      'PLACA': consultaData.PLACA,
      'RENAVAM': consultaData.RENAVAM,
      'CHASSI': consultaData.CHASSI,
      'UF': consultaData.UF,
      'MUNICÍPIO': consultaData.MUNICIPIO,
      'NÚMERO DO MOTOR': consultaData.NUMERO_MOTOR,
      'SITUAÇÃO': consultaData.SITUACAO,
    }));

    addSection('INFORMAÇÕES COMPLEMENTARES', renderTableDataForPDF({
      'MARCA/MODELO COMPLETO': consultaData.MARCA_MODELO,
      'ANO DE FABRICAÇÃO': consultaData.ANO_FABRICACAO,
      'ANO DO MODELO': consultaData.ANO_MODELO,
      'TIPO': consultaData.TIPO_VEICULO,
      'COR': consultaData.COR_VEICULO,
      'ESPÉCIE': consultaData.ESPECIE_VEICULO,
      'COMBUSTÍVEL': consultaData.COMBUSTIVEL,
      'POTÊNCIA': consultaData.POTENCIA_VEICULO,
      'CILINDRADA': consultaData.CILINDRADA,
      'CAPACIDADE DE PASSAGEIROS': consultaData.QUANTIDADE_PASSAGEIROS,
      'CMT': consultaData.CMT,
      'PBT': consultaData.PBT,
      'EIXOS': consultaData.NUMERO_EIXOS,
    }));

    addSection('MULTAS E DÉBITOS', renderTableDataForPDF({
      'IPVA': consultaData.RESTRICOES.IPVA.VALOR_PENDENCIA,
      'MULTAS': consultaData.RESTRICOES.MULTAS.VALOR_PENDENCIA,
      'LICENCIAMENTO': consultaData.RESTRICOES.LICENCIAMENTO.VALOR_PENDENCIA,
    }));

    addSection('RESTRIÇÕES', renderTableDataForPDF({
      'RESTRIÇÃO 01': consultaData.RESTRICOES.MENSAGENS_RESTRICOES[0]?.MENSAGEM || 'N/A',
      'RESTRIÇÃO 02': consultaData.RESTRICOES.MENSAGENS_RESTRICOES[1]?.MENSAGEM || 'N/A',
    }));

    doc.save(`${placa}_Consulta_Estadual.pdf`);
  };

  const renderTableRows = (data) => {
    return Object.entries(data).map(([key, value]) => (
      <tr key={key}>
        <th className="text-uppercase">{key.replace(/_/g, ' ')}</th>
        <td className="text-uppercase">{value}</td>
      </tr>
    ));
  };

  return (
    <MainContainer>
      <GlobalStyle />
      <CustomNavbar /> {/* Usando o componente CustomNavbar */}
      <ConsultaCard>
        <Card.Body>
        <h1>Base Estadual - R$10</h1>
          <div style={{ marginTop: '20px', color: '#516377', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <Input
                type="text"
                id="placa"
                placeholder="Digite a placa"
                value={placa}
                onChange={handlePlacaChange}
                maxLength="7"
              />
              <Input
                type="text"
                id="username"
                placeholder="Digite o username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <Input
                type="text"
                id="token"
                placeholder="Digite o token"
                value={token}
                onChange={(e) => setToken(e.target.value)}
              />
              <div style={{ marginTop: '2px' }}>
                <CreditsInfo>Créditos disponíveis: R${credits.toFixed(2)}</CreditsInfo>
              </div>
              <CaptchaContainer>
                <HCaptcha
                  sitekey="71549bc6-209d-4506-9f97-ee716882c326"
                  onVerify={onCaptchaChange}
                  ref={hcaptchaRef}
                />
              </CaptchaContainer>
              <ConsultaButton onClick={handleConsulta} disabled={loading || !captchaToken || placa.length !== 7}>
                {loading ? 'Consultando...' : 'Consultar'}
              </ConsultaButton>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </div>
          </div>
        </Card.Body>
      </ConsultaCard>
      {consultaData && (
        <ContainerConsulta>
          <Card className="mb-4">
            <Card.Header className="bg-primary text-white">Dados do Veículo</Card.Header>
            <Card.Body>
              <Table bordered responsive>
                <tbody>
                  {renderTableRows({
                    'PLACA': consultaData.PLACA,
                    'RENAVAM': consultaData.RENAVAM,
                    'CHASSI': consultaData.CHASSI,
                    'UF': consultaData.UF,
                    'MUNICÍPIO': consultaData.MUNICIPIO,
                    'NÚMERO DO MOTOR': consultaData.NUMERO_MOTOR,
                    'SITUAÇÃO': consultaData.SITUACAO,
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Card className="mb-4">
            <Card.Header className="bg-primary text-white">Informações Complementares</Card.Header>
            <Card.Body>
              <Table bordered responsive>
                <tbody>
                  {renderTableRows({
                    'MARCA/MODELO COMPLETO': consultaData.MARCA_MODELO,
                    'ANO DE FABRICAÇÃO': consultaData.ANO_FABRICACAO,
                    'ANO DO MODELO': consultaData.ANO_MODELO,
                    'TIPO': consultaData.TIPO_VEICULO,
                    'COR': consultaData.COR_VEICULO,
                    'ESPÉCIE': consultaData.ESPECIE_VEICULO,
                    'COMBUSTÍVEL': consultaData.COMBUSTIVEL,
                    'POTÊNCIA': consultaData.POTENCIA_VEICULO,
                    'CILINDRADA': consultaData.CILINDRADA,
                    'CAPACIDADE DE PASSAGEIROS': consultaData.QUANTIDADE_PASSAGEIROS,
                    'CMT': consultaData.CMT,
                    'PBT': consultaData.PBT,
                    'EIXOS': consultaData.NUMERO_EIXOS,
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Card className="mb-4">
            <Card.Header className="bg-primary text-white">Multas e Débitos</Card.Header>
            <Card.Body>
              <Table bordered responsive>
                <tbody>
                  {renderTableRows({
                    'IPVA': consultaData.RESTRICOES?.IPVA?.VALOR_PENDENCIA || 'N/A',
                    'MULTAS': consultaData.RESTRICOES?.MULTAS?.VALOR_PENDENCIA || 'N/A',
                    'LICENCIAMENTO': consultaData.RESTRICOES?.LICENCIAMENTO?.VALOR_PENDENCIA || 'N/A',
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Card className="mb-4">
            <Card.Header className="bg-primary text-white">Restrições</Card.Header>
            <Card.Body>
              <Table bordered responsive>
                <tbody>
                  {renderTableRows({
                    'RESTRIÇÃO 01': consultaData.RESTRICOES?.MENSAGENS_RESTRICOES?.[0]?.MENSAGEM || 'N/A',
                    'RESTRIÇÃO 02': consultaData.RESTRICOES?.MENSAGENS_RESTRICOES?.[1]?.MENSAGEM || 'N/A',
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Button variant="primary" onClick={handleDownloadPDF}>Download PDF</Button>
        </ContainerConsulta>
      )}
      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Erro</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowErrorModal(false)}>Fechar</Button>
        </Modal.Footer>
      </Modal>
    </MainContainer>
  );
};

export default ConsultaEstadual;
